import React, { useEffect, useState } from "react";
import Layout from "../../Layout/Index";
import ThemeSettings from "../../Layout/ThemeSettings";
import { Link } from "react-router-dom";
import PageTitle from "../../Layout/PageTitle";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { currentRole } from "../../Layout/HelmetComponent";
import { Modal } from "react-bootstrap";
import * as api from "../../../services/ApiService";
import * as tokenUtils from "../../../utils/tokenUtils";
import { toast } from "react-toastify";
import Spinner from "../../Loader/Spinner";
import { Scale } from "chart.js";
import Styles from "./Subscription.module.scss";

import { MDBAccordion, MDBAccordionItem } from 'mdb-react-ui-kit';

function Subscriptions() {
  let user = tokenUtils.getTokenData();

  const breadcrumbItems = [
    { text: currentRole + " Dashboard", link: "/client/dashboard" },
    { text: "Subscription Plans", link: null },
  ];
  const [increaseHeight, setIncreaseHeight] = useState(false);
  const hoverStyle = {
    Scaled: 1.5,
  };
  const [isHovered, setIsHovered] = useState(false);

  // const handleMouseEnter = () => setIsHovered(true);
  // const handleMouseLeave = () => setIsHovered(false);
  return (
    <Layout>
      <div className="container-fluid">
        {/* start page title */}
        <PageTitle
          title="Subscription Plans"
          breadcrumbItems={breadcrumbItems}
        />
        {/* end page title */}
        <div className="row mb-3">
          <div
            className={`col-md-3`}
            // onMouseEnter={handleMouseEnter} style={hoverStyle}
          >
            <div className={`card card-pricing  ${Styles.component} `}>
              <div
                className="card-body text-center pb-0 "
                style={
                  increaseHeight
                    ? { height: "100%", overflow: "initial" }
                    : { height: "40vw", overflow: "hidden" }
                }
              >
                <p className="card-pricing-plan-name font-weight-bold text-uppercase fw-bold">
                  Tier 1
                </p>
                <span className="card-pricing-icon text-primary">
                  <i className="fas fa-podcast" />
                </span>
                <h2
                  className="card-pricing-price"
                  style={{
                    borderTop: "3px solid #efeefc",
                    color: "#8686e0",
                    fontSize: "30px",
                    fontWeight: 700,
                  }}
                >
                  $9.9 <span style={{ color: "#8686e0" }}>/ Month</span>
                </h2>
                <p className="mb-0 text-dark">&nbsp;</p>
                {/* <ul
                  className="card-pricing-features pt-1"
                  style={{ color: "#ff5712", fontWeight: 800 }}
                >
                  <li>Admin Dashboard</li>
                  <li>Inbox</li>
                  <li>Calendar</li>
                  <li>Documents</li>
                  <li>Tasks</li>
                  <li>Activity Logs</li>
                  <li>Manage Agency Admin Users</li>
                  <li>Job Postings (5 Limit)</li>
                </ul> */}
                <p style={{fontSize:"20px", fontWeight:700,color: "#ff5712"}}> Recruitment Tools </p>
                <p style={{fontWeight:"700",fontSize:"13px"}}> Unlimited Job Posting Slots</p>
                <p style={{fontWeight:"700",fontSize:"13px"}}> Job Posting Management</p>
                <p style={{fontWeight:"700",fontSize:"13px"}}>Applicant Tracking</p>
                <p style={{fontWeight:"700",fontSize:"13px"}}>Follow Up Management</p>
                <p style={{fontWeight:"700",fontSize:"13px"}}> Submission Files</p>
                <p style={{fontSize:"20px", fontWeight:700,color: "#ff5712"}}> Compliance File Tools </p>
                <p style={{fontWeight:"700",fontSize:"13px"}}> Manage Employee Compliance Files</p>
                <p style={{fontWeight:"700",fontSize:"13px"}}> Facility Compliance Lists</p>
                <p style={{fontWeight:"700",fontSize:"13px"}}> Skills Checklists</p>
                <p style={{fontSize:"20px", fontWeight:700,color: "#ff5712"}}>Account Management Tools </p>
                <p style={{fontWeight:"700",fontSize:"13px"}}> Manage Employees on Assignment</p>
                <p style={{fontWeight:"700",fontSize:"13px"}}> Manage Submissions</p>
                <p style={{fontSize:"20px", fontWeight:700,color: "#ff5712"}}>Administration & Management Tools</p>
                <p style={{fontWeight:"700",fontSize:"13px"}}> Admin Dashboard</p>
                <p style={{fontWeight:"700",fontSize:"13px"}}> Messaging/Inbox</p>
                <p style={{fontWeight:"700",fontSize:"13px"}}> Calendar & Reminders</p>
                <p style={{fontWeight:"700",fontSize:"13px"}}> Task Management</p>
                <p style={{fontWeight:"700",fontSize:"13px"}}> Activity Logs</p>
                <p style={{fontWeight:"700",fontSize:"13px"}}> Add Admin Users</p>
                <p style={{fontSize:"20px", fontWeight:700,color: "#ff5712"}}>Customer Service Tools</p>
                <p style={{fontWeight:"700",fontSize:"13px"}}> Dedicated Success Manager</p>
                <p style={{fontWeight:"700",fontSize:"13px"}}> Dedicated Support Line</p>
                <p style={{fontWeight:"700",fontSize:"13px"}}> Dedicated Support Emai</p>
              </div>
              <div style={{ display: "flex", justifyContent: "center" }}>
                {increaseHeight ? (
                  <div
                    style={{
                      width: "80% ",
                      textAlign: "center",
                      // padding: 10,
                      background: "#f5f6f8",
                      cursor: "pointer",
                    }}
                    onClick={() => setIncreaseHeight(false)}
                  >
                    <span></span>
                    <i class="bi bi-caret-up-fill"></i>
                  </div>
                ) : (
                  <div
                    style={{
                      width: "80% ",
                      textAlign: "center",
                      padding: "",
                      background: "#f5f6f8",
                      cursor: "pointer",
                    }}
                    onClick={() => setIncreaseHeight(true)}
                  >
                    <i class="bi bi-caret-down-fill"></i>
                  </div>
                )}
              </div>

              <div className="card-footer text-center pt-0 mt-2">
                <Link
                  to="/client/checkout"
                  className="btn btn-primary waves-effect waves-light width-sm"
                  style={{ background: "rgb(30, 136, 242)", border: "none" }}
                >
                  Get Subscription
                </Link>
              </div>
            </div>{" "}
            {/* end Pricing_card */}
          </div>
          <div
            className="col-md-3"
            // onMouseEnter={handleMouseEnter} style={hoverStyle}
          >
            <div className={`card card-pricing  ${Styles.component} `}>
              <div
                className="card-body text-center pb-0 "
                style={
                  increaseHeight
                    ? { height: "100%", overflow: "initial" }
                    : { height: "40vw", overflow: "hidden" }
                }
              >
                <p className="card-pricing-plan-name font-weight-bold text-uppercase fw-bold">
                  Tier 2
                </p>
                <span className="card-pricing-icon text-primary">
                  <i className="fe-award" />
                </span>
                <h2
                  className="card-pricing-price "
                  style={{
                    borderTop: "3px solid #efeefc",
                    color: "#8686e0",
                    fontSize: "30px",
                    fontWeight: 700,
                  }}
                >
                  $1000 <span style={{ color: "#8686e0" }}>/ Month</span>
                </h2>
                <p className="mb-0 text-dark">(20% off with annual plan)</p>
                {/* <ul
                  className="card-pricing-features pt-1"
                  style={{ color: "#ff5712", fontWeight: 800 }}
                >
                  <li>Admin Dashboard</li>
                  <li>Inbox</li>
                  <li>Calendar</li>
                  <li>Documents</li>
                  <li>Tasks</li>
                  <li>Activity Logs</li>
                  <li>Manage Agency Admin Users</li>
                  <li>Job Postings (5 Limit)</li>
                  <li>Recruitment Dashboard</li>
                  <li>Job Posting Management (Unlimited Postings)</li>
                  <li>Candidate Tracking & Management</li>
                  <li>Job Matching Tools</li>
                  <li>Pre-Qual Candidates</li>
                </ul> */}
                <p style={{fontSize:"20px", fontWeight:800,color: "#ff5712"}}> Recruitment Tools </p>
                <p style={{fontWeight:"700",fontSize:"13px"}}> Unlimited Job Posting Slots</p>
                <p style={{fontWeight:"700",fontSize:"13px"}}> Job Posting Management</p>
                <p style={{fontWeight:"700",fontSize:"13px"}}>Applicant Tracking</p>
                <p style={{fontWeight:"700",fontSize:"13px"}}>Follow Up Management</p>
                <p style={{fontWeight:"700",fontSize:"13px"}}> Submission Files</p>
                <p style={{fontSize:"20px", fontWeight:800,color: "#ff5712"}}> Compliance File Tools </p>
                <p style={{fontWeight:"700",fontSize:"13px"}}> Manage Employee Compliance Files</p>
                <p style={{fontWeight:"700",fontSize:"13px"}}> Facility Compliance Lists</p>
                <p style={{fontWeight:"700",fontSize:"13px"}}> Skills Checklists</p>
                <p style={{fontSize:"20px", fontWeight:800,color: "#ff5712"}}>Account Management Tools </p>
                <p style={{fontWeight:"700",fontSize:"13px"}}> Manage Employees on Assignment</p>
                <p style={{fontWeight:"700",fontSize:"13px"}}> Manage Submissions</p>
                <p style={{fontSize:"20px", fontWeight:800,color: "#ff5712"}}>Administration & Management Tools</p>
                <p style={{fontWeight:"700",fontSize:"13px"}}> Admin Dashboard</p>
                <p style={{fontWeight:"700",fontSize:"13px"}}> Messaging/Inbox</p>
                <p style={{fontWeight:"700",fontSize:"13px"}}> Calendar & Reminders</p>
                <p style={{fontWeight:"700",fontSize:"13px"}}> Task Management</p>
                <p style={{fontWeight:"700",fontSize:"13px"}}> Activity Logs</p>
                <p style={{fontWeight:"700",fontSize:"13px"}}> Add Admin Users</p>
                <p style={{fontSize:"20px", fontWeight:800,color: "#ff5712"}}>Customer Service Tools</p>
                <p style={{fontWeight:"700",fontSize:"13px"}}> Dedicated Success Manager</p>
                <p style={{fontWeight:"700",fontSize:"13px"}}> Dedicated Support Line</p>
                <p style={{fontWeight:"700",fontSize:"13px"}}> Dedicated Support Emai</p>
              </div>
              <div style={{ display: "flex", justifyContent: "center" }}>
                {increaseHeight ? (
                  <div
                    style={{
                      width: "80% ",
                      textAlign: "center",
                      // padding: 10,
                      background: "#f5f6f8",
                      cursor: "pointer",
                    }}
                    onClick={() => setIncreaseHeight(false)}
                  >
                    <span></span>
                    <i class="bi bi-caret-up-fill"></i>
                  </div>
                ) : (
                  <div
                    style={{
                      width: "80% ",
                      textAlign: "center",
                      padding: "",
                      background: "#f5f6f8",
                      cursor: "pointer",
                    }}
                    onClick={() => setIncreaseHeight(true)}
                  >
                    <i class="bi bi-caret-down-fill"></i>
                  </div>
                )}
              </div>

              <div className="card-footer text-center pt-0 mt-2">
                <Link
                  to="/client/checkout"
                  className="btn btn-primary waves-effect waves-light width-sm"
                  style={{ background: "rgb(30, 136, 242)", border: "none" }}
                >
                  Get Subscription
                </Link>
              </div>
            </div>{" "}
            {/* end Pricing_card */}
          </div>
          <div
            className="col-md-3"
            // onMouseEnter={handleMouseEnter} style={hoverStyle}
          >
            <div className={`card card-pricing  ${Styles.component} `}>
              <div
                className="card-body text-center pb-0 "
                style={
                  increaseHeight
                    ? { height: "100%", overflow: "initial" }
                    : { height: "40vw", overflow: "hidden" }
                }
              >
                <p className="card-pricing-plan-name font-weight-bold text-uppercase fw-bold">
                  Tier 3
                </p>
                <span className="card-pricing-icon text-primary">
                  <i className="fe-aperture" />
                </span>
                <h2
                  className="card-pricing-price "
                  style={{
                    fontSize: "30px",
                    fontWeight: 700,
                    borderTop: "3px solid #efeefc",
                    color: "#8686e0",
                  }}
                >
                  $1300 <span style={{ color: "#8686e0" }}>/ Month</span>
                </h2>
                <p className="mb-0 text-dark">(20% off with annual plan)</p>
                {/* <ul
                  className="card-pricing-features pt-1"
                  style={{ color: "#ff5712", fontWeight: 800 }}
                >
                  <li>Admin Dashboard</li>
                  <li>Inbox</li>
                  <li>Calendar</li>
                  <li>Documents</li>
                  <li>Tasks</li>
                  <li>Activity Logs</li>
                  <li>Manage Agency Admin Users</li>
                  <li>Job Postings (5 Limit)</li>
                  <li>Recruitment Dashboard</li>
                  <li>Job Posting Management (Unlimited Postings)</li>
                  <li>Candidate Tracking & Management</li>
                  <li>Job Matching Tools</li>
                  <li>Pre-Qual Candidates</li>
                  <li>Account Management Dashboard</li>
                  <li>Manage Clients</li>
                  <li>Manage Employee Assignments</li>
                  <li>Manage Employee Schedules</li>
                  <li>Manage Submissions</li>
                </ul> */}
                <p style={{fontSize:"20px", fontWeight:700,color: "#ff5712"}}> Recruitment Tools </p>
                <p style={{fontWeight:"700",fontSize:"13px"}}> Unlimited Job Posting Slots</p>
                <p style={{fontWeight:"700",fontSize:"13px"}}> Job Posting Management</p>
                <p style={{fontWeight:"700",fontSize:"13px"}}>Applicant Tracking</p>
                <p style={{fontWeight:"700",fontSize:"13px"}}>Follow Up Management</p>
                <p style={{fontWeight:"700",fontSize:"13px"}}> Submission Files</p>
                <p style={{fontSize:"20px", fontWeight:700,color: "#ff5712"}}> Compliance File Tools </p>
                <p style={{fontWeight:"700",fontSize:"13px"}}> Manage Employee Compliance Files</p>
                <p style={{fontWeight:"700",fontSize:"13px"}}> Facility Compliance Lists</p>
                <p style={{fontWeight:"700",fontSize:"13px"}}> Skills Checklists</p>
                <p style={{fontSize:"20px", fontWeight:700,color: "#ff5712"}}>Account Management Tools </p>
                <p style={{fontWeight:"700",fontSize:"13px"}}> Manage Employees on Assignment</p>
                <p style={{fontWeight:"700",fontSize:"13px"}}> Manage Submissions</p>
                <p style={{fontSize:"20px", fontWeight:700,color: "#ff5712"}}>Administration & Management Tools</p>
                <p style={{fontWeight:"700",fontSize:"13px"}}> Admin Dashboard</p>
                <p style={{fontWeight:"700",fontSize:"13px"}}> Messaging/Inbox</p>
                <p style={{fontWeight:"700",fontSize:"13px"}}> Calendar & Reminders</p>
                <p style={{fontWeight:"700",fontSize:"13px"}}> Task Management</p>
                <p style={{fontWeight:"700",fontSize:"13px"}}> Activity Logs</p>
                <p style={{fontWeight:"700",fontSize:"13px"}}> Add Admin Users</p>
                <p style={{fontSize:"20px", fontWeight:700,color: "#ff5712"}}>Customer Service Tools</p>
                <p style={{fontWeight:"700",fontSize:"13px"}}> Dedicated Success Manager</p>
                <p style={{fontWeight:"700",fontSize:"13px"}}> Dedicated Support Line</p>
                <p style={{fontWeight:"700",fontSize:"13px"}}> Dedicated Support Emai</p>
              </div>
              <div style={{ display: "flex", justifyContent: "center" }}>
                {increaseHeight ? (
                  <div
                    style={{
                      width: "80% ",
                      textAlign: "center",
                      // padding: 10,
                      background: "#f5f6f8",
                      cursor: "pointer",
                    }}
                    onClick={() => setIncreaseHeight(false)}
                  >
                    <span></span>
                    <i class="bi bi-caret-up-fill"></i>
                  </div>
                ) : (
                  <div
                    style={{
                      width: "80% ",
                      textAlign: "center",
                      padding: "",
                      background: "#f5f6f8",
                      cursor: "pointer",
                    }}
                    onClick={() => setIncreaseHeight(true)}
                  >
                    <i class="bi bi-caret-down-fill"></i>
                  </div>
                )}
              </div>

              <div className="card-footer text-center pt-0 mt-2">
                <Link
                  to="/client/checkout"
                  className="btn btn-primary waves-effect waves-light width-sm"
                  style={{ background: "rgb(30, 136, 242)", border: "none" }}
                >
                  Get Subscription
                </Link>
              </div>
            </div>{" "}
            {/* end Pricing_card */}
          </div>

          <div
            className="col-md-3"
            // onMouseEnter={handleMouseEnter} style={hoverStyle}
          >
            <div className={`card card-pricing  ${Styles.component} `}>
              <div
                className="card-body text-center pb-0 "
                style={
                  increaseHeight
                    ? { height: "100%", overflow: "initial" }
                    : { height: "40vw", overflow: "hidden" }
                }
              >
                {" "}
                <p className="card-pricing-plan-name font-weight-bold text-uppercase fw-bold">
                  Tier 4
                </p>
                <span className="card-pricing-icon text-primary">
                  <i className="fas fa-paper-plane" />
                </span>
                <h2
                  className="card-pricing-price "
                  style={{
                    fontSize: "30px",
                    fontWeight: 700,
                    borderTop: "3px solid #efeefc",
                    color: "#8686e0",
                  }}
                >
                  $1600 <span style={{ color: "#8686e0" }}>/ Month</span>
                </h2>
                <p className="mb-0 text-dark">(20% off with annual plan)</p>
                {/* <ul className="card-pricing-features pt-1 " style={{fontWeight:800}}>
                  <li>Admin Dashboard</li>
                  <li>Inbox</li>
                  <li>Calendar</li>
                  <li>Documents</li>
                  <li>Tasks</li>
                  <li>Activity Logs</li>
                  <li>Manage Agency Admin Users</li>
                  <li>Job Postings (5 Limit)</li>
                  <li>Recruitment Dashboard</li>
                  <li>Job Posting Management (Unlimited Postings)</li>
                  <li>Candidate Tracking & Management</li>
                  <li>Job Matching Tools</li>
                  <li>Pre-Qual Candidates</li>
                  <li>Account Management Dashboard</li>
                  <li>Manage Clients</li>
                  <li>Manage Employee Assignments</li>
                  <li>Manage Employee Schedules</li>
                  <li>Manage Submissions</li>
                  <li>Compliance Dashboard</li>
                  <li>Manage Compliance Files</li>
                  <li>Manage Facility Compliance Requirement Lists</li>
                  <li>Manage & Assign Skills Checklists</li>
                  <li>Request access to Employee Files</li>
                </ul> */}
                <p style={{fontSize:"20px", fontWeight:700,color: "#ff5712"}}> Recruitment Tools </p>
                <p style={{fontWeight:"700",fontSize:"13px"}}> Unlimited Job Posting Slots</p>
                <p style={{fontWeight:"700",fontSize:"13px"}}> Job Posting Management</p>
                <p style={{fontWeight:"700",fontSize:"13px"}}>Applicant Tracking</p>
                <p style={{fontWeight:"700",fontSize:"13px"}}>Follow Up Management</p>
                <p style={{fontWeight:"700",fontSize:"13px"}}> Submission Files</p>
                <p style={{fontSize:"20px", fontWeight:700,color: "#ff5712"}}> Compliance File Tools </p>
                <p style={{fontWeight:"700",fontSize:"13px"}}> Manage Employee Compliance Files</p>
                <p style={{fontWeight:"700",fontSize:"13px"}}> Facility Compliance Lists</p>
                <p style={{fontWeight:"700",fontSize:"13px"}}> Skills Checklists</p>
                <p style={{fontSize:"20px", fontWeight:700,color: "#ff5712"}}>Account Management Tools </p>
                <p style={{fontWeight:"700",fontSize:"13px"}}> Manage Employees on Assignment</p>
                <p style={{fontWeight:"700",fontSize:"13px"}}> Manage Submissions</p>
                <p style={{fontSize:"20px", fontWeight:700,color: "#ff5712"}}>Administration & Management Tools</p>
                <p style={{fontWeight:"700",fontSize:"13px"}}> Admin Dashboard</p>
                <p style={{fontWeight:"700",fontSize:"13px"}}> Messaging/Inbox</p>
                <p style={{fontWeight:"700",fontSize:"13px"}}> Calendar & Reminders</p>
                <p style={{fontWeight:"700",fontSize:"13px"}}> Task Management</p>
                <p style={{fontWeight:"700",fontSize:"13px"}}> Activity Logs</p>
                <p style={{fontWeight:"700",fontSize:"13px"}}> Add Admin Users</p>
                <p style={{fontSize:"20px", fontWeight:700,color: "#ff5712"}}>Customer Service Tools</p>
                <p style={{fontWeight:"700",fontSize:"13px"}}> Dedicated Success Manager</p>
                <p style={{fontWeight:"700",fontSize:"13px"}}> Dedicated Support Line</p>
                <p style={{fontWeight:"700",fontSize:"13px"}}> Dedicated Support Emai</p>
              </div>
              <div style={{ display: "flex", justifyContent: "center" }}>
                {increaseHeight ? (
                  <div
                    style={{
                      width: "80% ",
                      textAlign: "center",
                      // padding: 10,
                      background: "#f5f6f8",
                      cursor: "pointer",
                    }}
                    onClick={() => setIncreaseHeight(false)}
                  >
                    <span></span>
                    <i class="bi bi-caret-up-fill"></i>
                  </div>
                ) : (
                  <div
                    style={{
                      width: "80% ",
                      textAlign: "center",
                      padding: "",
                      background: "#f5f6f8",
                      cursor: "pointer",
                    }}
                    onClick={() => setIncreaseHeight(true)}
                  >
                    <i class="bi bi-caret-down-fill"></i>
                  </div>
                )}
              </div>

              <div className="card-footer text-center pt-0 mt-2">
                <Link
                  to="/client/checkout"
                  className="btn btn-primary waves-effect waves-light width-sm"
                  style={{ background: "rgb(30, 136, 242)", border: "none" }}
                >
                  Get Subscription
                </Link>
              </div>
            </div>


          
            {/* end Pricing_card */}
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default Subscriptions;
