import React, { useEffect, useState } from "react";
import Layout from "../../Layout/Index";
import ThemeSettings from "../../Layout/ThemeSettings";
import { Link } from "react-router-dom";
import PageTitle from "../../Layout/PageTitle";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { currentRole } from "../../Layout/HelmetComponent";
import { Modal } from "react-bootstrap";
import * as api from "../../../services/ApiService";
import * as tokenUtils from "../../../utils/tokenUtils";

import Styles from "./Subscription.module.scss";

import { MDBAccordion, MDBAccordionItem } from 'mdb-react-ui-kit';

function SubscriptionsUi2() {
  let user = tokenUtils.getTokenData();

  const breadcrumbItems = [
    { text: currentRole + " Dashboard", link: "/client/dashboard" },
    { text: "Subscription Plans", link: null },
  ];
  const [increaseHeight, setIncreaseHeight] = useState(false);
  const hoverStyle = {
    Scaled: 1.5,
  };
  const [isHovered, setIsHovered] = useState(false);

  // const handleMouseEnter = () => setIsHovered(true);
  // const handleMouseLeave = () => setIsHovered(false);
  return (
    <Layout>
      <div className="container-fluid">
        {/* start page title */}
        <PageTitle
          title="Subscription Plans"
          breadcrumbItems={breadcrumbItems}
        />
        {/* end page title */}
        <div className="row mb-3">
        <div
            className="col-md-3"
            // onMouseEnter={handleMouseEnter} style={hoverStyle}
          >
        <div className={`card card-pricing  `}>
              <div
                className="card-body text-center pb-0 ">
  
  <p className="card-pricing-plan-name font-weight-bold text-uppercase fw-bold">
                  Tier 1
                </p>
                <span className="card-pricing-icon text-primary">
                  <i className="fas fa-podcast" />
                </span>
                <h2
                  className="card-pricing-price"
                  style={{
                    borderTop: "3px solid #efeefc",
                    color: "#8686e0",
                    fontSize: "26px",
                    fontWeight: 700,
                  }}
                >
                  $9.9 <span style={{ color: "#8686e0" }}>/ Month</span>
                </h2>
                <MDBAccordion flush >
      <MDBAccordionItem collapseId={1} headerTitle={
          <span style={{fontSize:"14px", fontWeight:700,color: "#ff5712"}} >Recruitment Tools</span>
        }>
         <p style={{fontWeight:"700",fontSize:"13px"}}> Unlimited Job Posting Slots</p>
                <p style={{fontWeight:"700",fontSize:"13px"}}> Job Posting Management</p>
                <p style={{fontWeight:"700",fontSize:"13px"}}>Applicant Tracking</p>
                <p style={{fontWeight:"700",fontSize:"13px"}}>Follow Up Management</p>
                <p style={{fontWeight:"700",fontSize:"13px"}}> Submission Files</p>
      </MDBAccordionItem>
      <MDBAccordionItem collapseId={2} headerTitle={
          <span style={{fontSize:"14px", fontWeight:700,color: "#ff5712"}} >Compliance File Tools</span>
        }>
          <p style={{fontWeight:"700",fontSize:"13px"}}> Manage Employee Compliance Files</p>
                <p style={{fontWeight:"700",fontSize:"13px"}}> Facility Compliance Lists</p>
                <p style={{fontWeight:"700",fontSize:"13px"}}> Skills Checklists</p>
      </MDBAccordionItem>
      <MDBAccordionItem collapseId={3} headerTitle={
          <span style={{fontSize:"14px", fontWeight:700,color: "#ff5712"}} >Account Management Tools</span>
        }>
       <p style={{fontWeight:"700",fontSize:"13px"}}> Manage Employees on Assignment</p>
       <p style={{fontWeight:"700",fontSize:"13px"}}> Manage Submissions</p>
      </MDBAccordionItem>
      <MDBAccordionItem collapseId={4} headerTitle={
          <span style={{fontSize:"14px", fontWeight:700,color: "#ff5712"}} >Administration & Management Tools</span>
        }>
         <p style={{fontWeight:"700",fontSize:"13px"}}> Admin Dashboard</p>
                <p style={{fontWeight:"700",fontSize:"13px"}}> Messaging/Inbox</p>
                <p style={{fontWeight:"700",fontSize:"13px"}}> Calendar & Reminders</p>
                <p style={{fontWeight:"700",fontSize:"13px"}}> Task Management</p>
                <p style={{fontWeight:"700",fontSize:"13px"}}> Activity Logs</p>
                <p style={{fontWeight:"700",fontSize:"13px"}}> Add Admin Users</p>
      </MDBAccordionItem>
      <MDBAccordionItem collapseId={5} headerTitle={
          <span style={{fontSize:"14px", fontWeight:700,color: "#ff5712"}} >Customer Service Tools</span>
        }>
       <p style={{fontWeight:"700",fontSize:"13px"}}> Dedicated Success Manager</p>
                <p style={{fontWeight:"700",fontSize:"13px"}}> Dedicated Support Line</p>
                <p style={{fontWeight:"700",fontSize:"13px"}}> Dedicated Support Emai</p>
      </MDBAccordionItem>
    </MDBAccordion>

                
              </div>
            

              <div className="card-footer text-center pt-0 mt-2">
                <Link
                  to="/client/checkout"
                  className="btn btn-primary waves-effect waves-light width-sm"
                  style={{ background: "rgb(30, 136, 242)", border: "none" }}
                >
                  Get Subscription
                </Link>
              </div>
            </div>
            </div><div
            className="col-md-3"
            // onMouseEnter={handleMouseEnter} style={hoverStyle}
          >
        <div className={`card card-pricing  `}>
              <div
                className="card-body text-center pb-0 ">
  
  <p className="card-pricing-plan-name font-weight-bold text-uppercase fw-bold">
                  Tier 2
                </p>
                <span className="card-pricing-icon text-primary">
                  <i className="fe-award" />
                </span>
                <h2
                  className="card-pricing-price "
                  style={{
                    borderTop: "3px solid #efeefc",
                    color: "#8686e0",
                    fontSize: "26px",
                    fontWeight: 700,
                  }}
                >
                  $1000 <span style={{ color: "#8686e0" }}>/ Month</span>
                </h2>
                <p className="mb-0 text-dark">(20% off with annual plan)</p>


                <MDBAccordion flush >
      <MDBAccordionItem collapseId={1} headerTitle={
          <span style={{fontSize:"14px", fontWeight:700,color: "#ff5712"}} >Recruitment Tools</span>
        }>
         <p style={{fontWeight:"700",fontSize:"13px"}}> Unlimited Job Posting Slots</p>
                <p style={{fontWeight:"700",fontSize:"13px"}}> Job Posting Management</p>
                <p style={{fontWeight:"700",fontSize:"13px"}}>Applicant Tracking</p>
                <p style={{fontWeight:"700",fontSize:"13px"}}>Follow Up Management</p>
                <p style={{fontWeight:"700",fontSize:"13px"}}> Submission Files</p>
      </MDBAccordionItem>
      <MDBAccordionItem collapseId={2} headerTitle={
          <span style={{fontSize:"14px", fontWeight:700,color: "#ff5712"}} >Compliance File Tools</span>
        }>
          <p style={{fontWeight:"700",fontSize:"13px"}}> Manage Employee Compliance Files</p>
                <p style={{fontWeight:"700",fontSize:"13px"}}> Facility Compliance Lists</p>
                <p style={{fontWeight:"700",fontSize:"13px"}}> Skills Checklists</p>
      </MDBAccordionItem>
      <MDBAccordionItem collapseId={3} headerTitle={
          <span style={{fontSize:"14px", fontWeight:700,color: "#ff5712"}} >Account Management Tools</span>
        }>
       <p style={{fontWeight:"700",fontSize:"13px"}}> Manage Employees on Assignment</p>
       <p style={{fontWeight:"700",fontSize:"13px"}}> Manage Submissions</p>
      </MDBAccordionItem>
      <MDBAccordionItem collapseId={4} headerTitle={
          <span style={{fontSize:"14px", fontWeight:700,color: "#ff5712"}} >Administration & Management Tools</span>
        }>
         <p style={{fontWeight:"700",fontSize:"13px"}}> Admin Dashboard</p>
                <p style={{fontWeight:"700",fontSize:"13px"}}> Messaging/Inbox</p>
                <p style={{fontWeight:"700",fontSize:"13px"}}> Calendar & Reminders</p>
                <p style={{fontWeight:"700",fontSize:"13px"}}> Task Management</p>
                <p style={{fontWeight:"700",fontSize:"13px"}}> Activity Logs</p>
                <p style={{fontWeight:"700",fontSize:"13px"}}> Add Admin Users</p>
      </MDBAccordionItem>
      <MDBAccordionItem collapseId={5} headerTitle={
          <span style={{fontSize:"14px", fontWeight:700,color: "#ff5712"}} >Customer Service Tools</span>
        }>
       <p style={{fontWeight:"700",fontSize:"13px"}}> Dedicated Success Manager</p>
                <p style={{fontWeight:"700",fontSize:"13px"}}> Dedicated Support Line</p>
                <p style={{fontWeight:"700",fontSize:"13px"}}> Dedicated Support Emai</p>
      </MDBAccordionItem>
    </MDBAccordion>

                
              </div>
            

              <div className="card-footer text-center pt-0 mt-2">
                <Link
                  to="/client/checkout"
                  className="btn btn-primary waves-effect waves-light width-sm"
                  style={{ background: "rgb(30, 136, 242)", border: "none" }}
                >
                  Get Subscription
                </Link>
              </div>
            </div>
            </div><div
            className="col-md-3"
            // onMouseEnter={handleMouseEnter} style={hoverStyle}
          >
        <div className={`card card-pricing  `}>
              <div
                className="card-body text-center pb-0 ">
  
  <p className="card-pricing-plan-name font-weight-bold text-uppercase fw-bold">
                  Tier 3
                </p>
                <span className="card-pricing-icon text-primary">
                  <i className="fe-aperture" />
                </span>
                <h2
                  className="card-pricing-price "
                  style={{
                    fontSize: "26px",
                    fontWeight: 700,
                    borderTop: "3px solid #efeefc",
                    color: "#8686e0",
                  }}
                >
                  $1300 <span style={{ color: "#8686e0" }}>/ Month</span>
                </h2>
                <p className="mb-0 text-dark">(20% off with annual plan)</p>


                <MDBAccordion flush >
      <MDBAccordionItem collapseId={1} headerTitle={
          <span style={{fontSize:"14px", fontWeight:700,color: "#ff5712"}} >Recruitment Tools</span>
        }>
         <p style={{fontWeight:"700",fontSize:"13px"}}> Unlimited Job Posting Slots</p>
                <p style={{fontWeight:"700",fontSize:"13px"}}> Job Posting Management</p>
                <p style={{fontWeight:"700",fontSize:"13px"}}>Applicant Tracking</p>
                <p style={{fontWeight:"700",fontSize:"13px"}}>Follow Up Management</p>
                <p style={{fontWeight:"700",fontSize:"13px"}}> Submission Files</p>
      </MDBAccordionItem>
      <MDBAccordionItem collapseId={2} headerTitle={
          <span style={{fontSize:"14px", fontWeight:700,color: "#ff5712"}} >Compliance File Tools</span>
        }>
          <p style={{fontWeight:"700",fontSize:"13px"}}> Manage Employee Compliance Files</p>
                <p style={{fontWeight:"700",fontSize:"13px"}}> Facility Compliance Lists</p>
                <p style={{fontWeight:"700",fontSize:"13px"}}> Skills Checklists</p>
      </MDBAccordionItem>
      <MDBAccordionItem collapseId={3} headerTitle={
          <span style={{fontSize:"14px", fontWeight:700,color: "#ff5712"}} >Account Management Tools</span>
        }>
       <p style={{fontWeight:"700",fontSize:"13px"}}> Manage Employees on Assignment</p>
       <p style={{fontWeight:"700",fontSize:"13px"}}> Manage Submissions</p>
      </MDBAccordionItem>
      <MDBAccordionItem collapseId={4} headerTitle={
          <span style={{fontSize:"14px", fontWeight:700,color: "#ff5712"}} >Administration & Management Tools</span>
        }>
         <p style={{fontWeight:"700",fontSize:"13px"}}> Admin Dashboard</p>
                <p style={{fontWeight:"700",fontSize:"13px"}}> Messaging/Inbox</p>
                <p style={{fontWeight:"700",fontSize:"13px"}}> Calendar & Reminders</p>
                <p style={{fontWeight:"700",fontSize:"13px"}}> Task Management</p>
                <p style={{fontWeight:"700",fontSize:"13px"}}> Activity Logs</p>
                <p style={{fontWeight:"700",fontSize:"13px"}}> Add Admin Users</p>
      </MDBAccordionItem>
      <MDBAccordionItem collapseId={5} headerTitle={
          <span style={{fontSize:"14px", fontWeight:700,color: "#ff5712"}} >Customer Service Tools</span>
        }>
       <p style={{fontWeight:"700",fontSize:"13px"}}> Dedicated Success Manager</p>
                <p style={{fontWeight:"700",fontSize:"13px"}}> Dedicated Support Line</p>
                <p style={{fontWeight:"700",fontSize:"13px"}}> Dedicated Support Emai</p>
      </MDBAccordionItem>
    </MDBAccordion>

                
              </div>
            

              <div className="card-footer text-center pt-0 mt-2">
                <Link
                  to="/client/checkout"
                  className="btn btn-primary waves-effect waves-light width-sm"
                  style={{ background: "rgb(30, 136, 242)", border: "none" }}
                >
                  Get Subscription
                </Link>
              </div>
            </div>
            </div><div
            className="col-md-3"
            // onMouseEnter={handleMouseEnter} style={hoverStyle}
          >
        <div className={`card card-pricing  `}>
              <div
                className="card-body text-center pb-0 ">
  
  <p className="card-pricing-plan-name font-weight-bold text-uppercase fw-bold">
                  Tier 4
                </p>
                <span className="card-pricing-icon text-primary">
                  <i className="fas fa-paper-plane" />
                </span>
                <h2
                  className="card-pricing-price "
                  style={{
                    fontSize: "26px",
                    fontWeight: 700,
                    borderTop: "3px solid #efeefc",
                    color: "#8686e0",
                  }}
                >
                  $1600 <span style={{ color: "#8686e0" }}>/ Month</span>
                </h2>
                <p className="mb-0 text-dark">(20% off with annual plan)</p>


                <MDBAccordion flush >
      <MDBAccordionItem collapseId={1} headerTitle={
          <span style={{fontSize:"14px", fontWeight:700,color: "#ff5712"}} >Recruitment Tools</span>
        }>
         <p style={{fontWeight:"700",fontSize:"13px"}}> Unlimited Job Posting Slots</p>
                <p style={{fontWeight:"700",fontSize:"13px"}}> Job Posting Management</p>
                <p style={{fontWeight:"700",fontSize:"13px"}}>Applicant Tracking</p>
                <p style={{fontWeight:"700",fontSize:"13px"}}>Follow Up Management</p>
                <p style={{fontWeight:"700",fontSize:"13px"}}> Submission Files</p>
      </MDBAccordionItem>
      <MDBAccordionItem collapseId={2} headerTitle={
          <span style={{fontSize:"14px", fontWeight:700,color: "#ff5712"}} >Compliance File Tools</span>
        }>
          <p style={{fontWeight:"700",fontSize:"13px"}}> Manage Employee Compliance Files</p>
                <p style={{fontWeight:"700",fontSize:"13px"}}> Facility Compliance Lists</p>
                <p style={{fontWeight:"700",fontSize:"13px"}}> Skills Checklists</p>
      </MDBAccordionItem>
      <MDBAccordionItem collapseId={3} headerTitle={
          <span style={{fontSize:"14px", fontWeight:700,color: "#ff5712"}} >Account Management Tools</span>
        }>
       <p style={{fontWeight:"700",fontSize:"13px"}}> Manage Employees on Assignment</p>
       <p style={{fontWeight:"700",fontSize:"13px"}}> Manage Submissions</p>
      </MDBAccordionItem>
      <MDBAccordionItem collapseId={4} headerTitle={
          <span style={{fontSize:"14px", fontWeight:700,color: "#ff5712"}} >Administration & Management Tools</span>
        }>
         <p style={{fontWeight:"700",fontSize:"13px"}}> Admin Dashboard</p>
                <p style={{fontWeight:"700",fontSize:"13px"}}> Messaging/Inbox</p>
                <p style={{fontWeight:"700",fontSize:"13px"}}> Calendar & Reminders</p>
                <p style={{fontWeight:"700",fontSize:"13px"}}> Task Management</p>
                <p style={{fontWeight:"700",fontSize:"13px"}}> Activity Logs</p>
                <p style={{fontWeight:"700",fontSize:"13px"}}> Add Admin Users</p>
      </MDBAccordionItem>
      <MDBAccordionItem collapseId={5} headerTitle={
          <span style={{fontSize:"14px", fontWeight:700,color: "#ff5712"}} >Customer Service Tools</span>
        }>
       <p style={{fontWeight:"700",fontSize:"13px"}}> Dedicated Success Manager</p>
                <p style={{fontWeight:"700",fontSize:"13px"}}> Dedicated Support Line</p>
                <p style={{fontWeight:"700",fontSize:"13px"}}> Dedicated Support Emai</p>
      </MDBAccordionItem>
    </MDBAccordion>

                
              </div>
            

              <div className="card-footer text-center pt-0 mt-2">
                <Link
                  to="/client/checkout"
                  className="btn btn-primary waves-effect waves-light width-sm"
                  style={{ background: "rgb(30, 136, 242)", border: "none" }}
                >
                  Get Subscription
                </Link>
              </div>
            </div>
            </div>
        </div>
        
      </div>
    </Layout>
  );
}

export default SubscriptionsUi2;
