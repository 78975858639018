import React, { useState, useEffect } from "react";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import Layout from "../../Layout/Index";
import * as api from "../../../services/ApiService";
import * as tokenUtils from "../../../utils/tokenUtils";
import Spinner from "../../Loader/Spinner";
import { toast } from "react-toastify";
import Dropdown from "react-bootstrap/Dropdown";
import PageTitle from "../../Layout/PageTitle";
import { formatDate } from "../../../utils/dateFormat";
import { Tooltip } from "react-tooltip";
import useConfirm from "../../../hooks/useConfirm";

const AdminJobs = () => {
  // Get data from token util
  let user = tokenUtils.getTokenData();

  const navigate = useNavigate();
  const location = useLocation();
  const isBoosted = location.pathname.includes("boosted-jobs");

  const [isLoading, setIsLoading] = useState(false);

  const [modalOpen, setModalOpen] = useState(false);

  const toggleModal = () => {
    setModalOpen(!modalOpen);
  };

  const [showJobFilterDropdown, setJobFilterDropdown] = useState(false);
  const handleToggleJobFilterDropDown = () => {
    setJobFilterDropdown(!showJobFilterDropdown);
  };

  // State for filter values
  const [filters, setFilters] = useState({
    job_title: "",
    state_id: "",
    date_posted: "",
    starred_job: false,
    order_by: "default",
  });

  // Handle form reset
  const handleReset = () => {
    const defaultFilters = {
      job_title: "",
      state_id: "",
      date_posted: "",
      starred_job: false,
      order_by: "default",
    };
    setFilters(defaultFilters);
    setJobFilterDropdown(false);
  };

  useEffect(() => {
    if (showJobFilterDropdown === false) {
      fetchJobData(filters);
    }
  }, [showJobFilterDropdown]);

  // Handle form input change
  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;

    if (type === "checkbox") {
      setFilters({
        ...filters,
        [name]: checked,
      });
    } else {
      setFilters({
        ...filters,
        [name]: value,
      });
    }
  };

  // Handle form submit
  const handleSubmit = (e) => {
    e.preventDefault();
    fetchJobData(filters);
    setJobFilterDropdown(false);
  };

  const [states, setStates] = useState([]);

  useEffect(() => {
    // Function to fetch data from the API
    const fetchData = async () => {
      try {
        const apiData = await api.getMethod("get-states");
        setStates(apiData.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    // Call the fetchData function when the component mounts
    fetchData();
  }, []); // Empty dependency array ensures that the effect runs only once, when the component mounts

  const handleEdit = (record) => {
    /*
    // Populate formData with the values of the selected record
    setformData({
      id: record.job_interview_id,
      user_id: user.id,
      jobId: record.job_id,
      empId: record.user_id,
      interviewerId: record.interviewer_id,
      interviewDate: record.interview_date,
      interviewTime: record.interview_time,
    });

    setAddModalOpen(true);
    */
  };

  const { confirm } = useConfirm();
  const handleDelete = async (record) => {
    const confirmed = await confirm();
    if (confirmed.isConfirmed) {
      setIsLoading(true);
      record.user_id = user.id;
      const result = await api.postMethod(record, "admin/delete-job");
      setIsLoading(false);
      if (result.status) {
        toast.success(result.message);
        fetchJobData();
      }
    }
  };

  const [jobs, setJobs] = useState({});
  const [loading, setLoading] = useState(true);
  const [noRecordsFound, setNoRecordsFound] = useState(false);

  const fetchJobData = async () => {
    try {
      const param = {
        user_id: user.id,
        token: user.token,
      };

      const queryParams = { ...param, ...filters };

      //const apiData = await api.postMethod(queryParams, "admin/get-jobs");

      let apiData;
      if (isBoosted) apiData = await api.postMethod(queryParams, "admin/get-boosted-jobs");
      else apiData = await api.postMethod(queryParams, "admin/get-jobs");

      //const apiDataObj = Object.setPrototypeOf(apiData, Object.prototype);

      /*
      const apiDataObj = apiData.reduce((acc, currentValue, index) => {
        acc[index] = currentValue;
        return acc;
      }, {});
      */

      setJobs(apiData.data);
      setLoading(false);
      // Check if data array is empty
      if (apiData.data.length === 0) {
        setNoRecordsFound(true);
      }
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    setIsLoading(true);
    fetchJobData();
  }, []); // Empty dependency array ensures that the effect runs only once, when the component mounts

  const toggleStarredJob = async (job_id, status) => {
    setIsLoading(true);
    const param = {
      user_id: user.id,
      token: user.token,
      job_id: job_id,
      status: status,
    };
    const result = await api.postMethod(param, "admin/toggle-starred-job");
    setIsLoading(false);
    if (result.status) {
      toast.success(result.message);
      fetchJobData();
    }
  };

  const updateStatus = async (id, status) => {
    try {
      setIsLoading(true);
      const param = {
        user_id: user.id,
        id: id,
        status: status,
      };
      const result = await api.postFileMethod(param, "admin/update-job-status");
      setIsLoading(false);
      if (result.status) {
        toast.success(result.message);
        fetchJobData();
      } else {
        toast.error(result.message);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  /* Select All checkbox functions start */

  const [selectedJobIds, setSelectedJobIds] = useState([]);
  const [bulkAction, setBulkAction] = useState("");

  const handleSelectAllChange = (event) => {
    if (event.target.checked) {
      const allUserIds = jobs.map((record) => record.id);
      setSelectedJobIds(allUserIds);
    } else {
      setSelectedJobIds([]);
    }
  };

  const handleCheckboxChange = (event) => {
    const userId = parseInt(event.target.value);
    setSelectedJobIds((prevSelectedUserIds) => {
      if (event.target.checked) {
        // Add the user ID to the list
        return [...prevSelectedUserIds, userId];
      } else {
        // Remove the user ID from the list
        return prevSelectedUserIds.filter((id) => id !== userId);
      }
    });
  };

  const handleBulkActionChange = (event) => {
    setBulkAction(event.target.value);
  };

  const handleBulkActionSubmit = async () => {
    if (bulkAction === "") {
      toast.error("Please any action from bulk actions");
    } else if (selectedJobIds.length === 0) {
      toast.error("Please select atleast 1 record to perform bulk action");
    } else {
      try {
        setIsLoading(true);
        const param = {
          user_id: user.id,
          job_ids: selectedJobIds,
          bulk_action: bulkAction,
        };
        const result = await api.postFileMethod(param, "admin/job-bulk-actions");
        setIsLoading(false);
        if (result.status) {
          toast.success(result.message);
          setSelectedJobIds([]);
          setBulkAction("");
          fetchJobData();
        } else {
          toast.error(result.message);
        }
      } catch (error) {
        setIsLoading(false);
      }
    }

    /*
    if (bulkAction === "delete") {
      
      axios
        .post("/api/delete-users", { user_ids: selectedJobIds })
        .then((response) => {
          setRecords(records.filter((record) => !selectedJobIds.includes(record.id)));
          setSelectedJobIds([]);
        })
        .catch((error) => console.error("There was an error deleting the users!", error));
      
    } else if (bulkAction === "change-status") {
      
      axios.post('/api/change-status', { user_ids: selectedJobIds, status: 'inactive' })
        .then(response => {
          setRecords(records.map(record => 
            selectedJobIds.includes(record.id) ? { ...record, status: 'inactive' } : record
          ));
          setSelectedJobIds([]);
        })
        .catch(error => console.error('There was an error changing the status!', error));
        
    }
    */
  };

  const breadcrumbItems = [
    { text: "Admin Dashboard", link: "/admin/dashboard" },
    { text: isBoosted ? "Boosted Jobs" : "Job Postings", link: null },
  ];

  return (
    <>
      <Layout>
        <PageTitle
          title={isBoosted ? "Boosted Jobs" : "Job Postings"}
          breadcrumbItems={breadcrumbItems}
        />

        {/* end page title */}
        <div className="row">
          <div className="col-12">
            <div className="card custom-box-shadow">
              <div className="card-body">
                <div className="row justify-content-between">
                  <div className="col-md-6">
                    <div className="">
                      <select
                        className="form-select my-1 my-lg-0 w-auto d-inline me-1"
                        value={bulkAction}
                        onChange={handleBulkActionChange}
                      >
                        <option value="">Bulk Actions</option>
                        <option value="delete">Delete</option>
                        <option value="change-status-active">Change Status to Active</option>
                        <option value="change-status-inactive">Change Status to In-Active</option>
                      </select>
                      <button onClick={handleBulkActionSubmit} className="btn btn-info">
                        Apply Action
                      </button>
                    </div>
                  </div>
                  <div className="col-auto">
                    <div className="dropdown" style={{ width: 300 }}>
                      <button
                        type="button"
                        className="btn btn-outline-light dropdown-toggle w-100 text-start"
                        onClick={handleToggleJobFilterDropDown}
                      >
                        Filter and Search Jobs
                        <i className="mdi mdi-chevron-down float-end" />
                      </button>
                      <div className={`dropdown-menu ${showJobFilterDropdown ? "show" : ""}`}>
                        <form className="px-2 py-2" onSubmit={handleSubmit}>
                          <div className="mb-2">
                            <label htmlFor="job_title" className="form-label">
                              Job Title
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="job_title"
                              name="job_title"
                              value={filters.job_title}
                              onChange={handleInputChange}
                            />
                          </div>
                          <div className="mb-2">
                            <label htmlFor="state_id" className="form-label">
                              Location
                            </label>
                            <select
                              className="form-control"
                              id="state_id"
                              name="state_id"
                              value={filters.state_id}
                              onChange={handleInputChange}
                            >
                              <option value="" key="">
                                Please Select
                              </option>
                              {/* Render the states */}
                              {states.map((state) => (
                                <option key={state.id} value={state.id}>
                                  {state.name}
                                </option>
                              ))}
                            </select>
                          </div>
                          <div className="mb-2">
                            <label htmlFor="date_posted" className="form-label">
                              Date Posted
                            </label>
                            <input
                              type="date"
                              className="form-control"
                              id="date_posted"
                              name="date_posted"
                              value={filters.date_posted}
                              onChange={handleInputChange}
                            />
                          </div>
                          <div className="mb-2">
                            <label htmlFor="order_by" className="form-label">
                              Order By
                            </label>
                            <select
                              className="form-control"
                              id="order_by"
                              name="order_by"
                              value={filters.order_by}
                              onChange={handleInputChange}
                            >
                              <option value="default" key="default">
                                Default
                              </option>
                              <option value="highest" key="highest">
                                Highest Pay First
                              </option>
                              <option value="lowest" key="lowest">
                                Lowest Pay First
                              </option>
                            </select>
                          </div>
                          <div className="mb-2">
                            <div className="form-check">
                              <input
                                type="checkbox"
                                className="form-check-input"
                                id="starred_job"
                                name="starred_job"
                                checked={filters.starred_job}
                                onChange={handleInputChange}
                              />
                              <label className="form-check-label" htmlFor="starred_job">
                                <i className="fa fa-star" /> Starred
                              </label>
                            </div>
                          </div>
                          <div className="text-end">
                            <button
                              type="button"
                              className="btn btn-light float-start"
                              onClick={handleReset}
                            >
                              Reset Filter
                            </button>
                            <button type="submit" className="btn btn-primary">
                              Apply Filter
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                  <div className="col-auto">
                    <div className="text-lg-end my-1 my-lg-0">
                      <Link
                        to="/admin/job-post-step1"
                        className="btn btn-primary waves-effect waves-light mb-2 me-1"
                      >
                        <i className="fa fa-plus mr-1" /> Post a Job
                      </Link>
                      {/* !isBoosted ? (
                        <Link
                          to="/admin/boosted-jobs"
                          className="btn btn-info waves-effect waves-light mb-2"
                        >
                          <i className="mr-1" data-feather="zap" /> Boosted Jobs
                        </Link>
                      ) : (
                        <Link
                          to="/admin/job-postings"
                          className="btn btn-info waves-effect waves-light mb-2"
                        >
                          <i className="mr-1" data-feather="zap" /> All Jobs
                        </Link>
                      ) */}
                    </div>
                  </div>
                  {/* end col*/}
                </div>
                <div className="table-responsive">
                  <table className="table table-centered mb-0">
                    {/* table-nowrap */}
                    <thead className="table-light">
                      <tr>
                        <th className="form-checks">
                          <input
                            type="checkbox"
                            onChange={handleSelectAllChange}
                            checked={selectedJobIds.length === jobs.length && jobs.length > 0}
                            className="form-check-input"
                          />
                        </th>
                        <th>&nbsp;</th>
                        <th width="10%">Job Title</th>
                        <th>Applicants</th>
                        {/* <th>Specialty</th>*/}
                        <th>Status</th>
                        <th>Posted By</th>
                        <th>Date Posted</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {loading ? (
                        <tr>
                          <td colSpan="8">Loading...</td>
                        </tr>
                      ) : (
                        <>
                          {noRecordsFound ? (
                            <tr>
                              <td colSpan="8">No records found</td>
                            </tr>
                          ) : (
                            <>
                              {Object.entries(jobs).map(([key, value]) => (
                                <tr key={value.id}>
                                  <td className="form-checks">
                                    <input
                                      type="checkbox"
                                      value={value.id}
                                      name="job_ids"
                                      onChange={handleCheckboxChange}
                                      checked={selectedJobIds.includes(value.id)}
                                      className="form-check-input"
                                    />
                                  </td>
                                  <td>
                                    <span
                                      className={
                                        value.is_starred == 1
                                          ? "star-toggle fas fa-star text-warning"
                                          : "star-toggle far fa-star"
                                      }
                                      onClick={() => toggleStarredJob(value.id, value.is_starred)}
                                    ></span>
                                  </td>
                                  <td>
                                    <Link
                                      to={`/admin/job/` + value.unique_id}
                                      className="job-title"
                                      data-tooltip-id={`mt-` + value.unique_id}
                                      data-tooltip-content={value.title}
                                    >
                                      {value.title}
                                    </Link>
                                    <Tooltip id={`mt-` + value.unique_id}></Tooltip>

                                    <p className="mb-0 text-muted">
                                      <small>(#{value.unique_id})</small>
                                    </p>
                                    {value.specialty !== null && (
                                      <p className="mb-0 text-muted">
                                        <small>
                                          <strong>Specialty</strong> : {value.specialty}
                                        </small>
                                      </p>
                                    )}
                                    {(value.state_code !== null || value.city_name !== null) && (
                                      <p className="mb-0 ">
                                        <small>
                                          <strong>Address</strong> :{" "}
                                          {/* <i className="fa fa-map-marker-alt me-1"></i>*/}
                                          {value.state_code !== null && value.city_name !== null
                                            ? value.city_name + ", " + value.state_code
                                            : value.state_code !== null
                                            ? value.state_code
                                            : value.city_name !== null
                                            ? value.city_name
                                            : ""}
                                        </small>
                                      </p>
                                    )}
                                  </td>
                                  <td>
                                    <div className="btn-group mb-2">
                                      <button
                                        onClick={() =>
                                          navigate(`/admin/job/` + value.unique_id, {
                                            state: { tab: "job_applications" },
                                          })
                                        }
                                        type="button"
                                        className="btn btn-light fw-bold px-1 py-1 border-end font-13"
                                      >
                                        {value.applied_jobs}
                                        <br />
                                        Applied
                                      </button>

                                      <button
                                        onClick={() =>
                                          navigate(`/admin/job/` + value.unique_id, {
                                            state: { tab: "job_applications" },
                                          })
                                        }
                                        type="button"
                                        className="btn btn-light fw-bold px-1 py-1 border-end font-13"
                                      >
                                        {value.shortlisted_jobs}
                                        <br />
                                        Shortlisted
                                      </button>

                                      <button
                                        onClick={() =>
                                          navigate(`/admin/job/` + value.unique_id, {
                                            state: { tab: "job_applications" },
                                          })
                                        }
                                        type="button"
                                        className="btn btn-light fw-bold px-1 py-1 border-end font-13"
                                      >
                                        {value.submitted_jobs}
                                        <br />
                                        Submitted
                                      </button>

                                      {/* 
                                      <button
                                        onClick={() =>
                                          navigate(`/admin/job/` + value.unique_id, {
                                            state: { tab: "job_applications" },
                                          })
                                        }
                                        type="button"
                                        className="btn btn-light fw-bold px-1 py-1 border-end font-13"
                                      >
                                        {value.interview_jobs}
                                        <br />
                                        Interview
                                      </button>
                                      */}
                                      <button
                                        onClick={() =>
                                          navigate(`/admin/job/` + value.unique_id, {
                                            state: { tab: "job_applications" },
                                          })
                                        }
                                        type="button"
                                        className="btn btn-light fw-bold px-1 py-1 border-end font-13"
                                      >
                                        {value.offered_jobs}
                                        <br />
                                        Offered
                                      </button>
                                      <button
                                        onClick={() =>
                                          navigate(`/admin/job/` + value.unique_id, {
                                            state: { tab: "job_applications" },
                                          })
                                        }
                                        type="button"
                                        className="btn btn-light fw-bold px-1 py-1 font-13"
                                      >
                                        {value.hired_jobs}
                                        <br />
                                        &nbsp;&nbsp;Hired&nbsp;&nbsp;
                                      </button>
                                    </div>
                                  </td>
                                  {/* <td>{value.specialty !== null ? value.specialty : "-"}</td>*/}
                                  <td>
                                    <h5>
                                      {value.status == 1 && (
                                        <label className="badge badge-soft-success">Active</label>
                                      )}
                                      {value.status == 2 && (
                                        <label className="badge badge-soft-danger">In-Active</label>
                                      )}
                                      {value.status == 0 && (
                                        <label className="badge badge-soft-secondary">Draft</label>
                                      )}
                                      {value.status == 6 && (
                                        <label className="badge badge-soft-danger">Expired</label>
                                      )}
                                    </h5>
                                  </td>
                                  <td>
                                    {(value.user_role_id == 2 && (
                                      <Link
                                        to={`/admin/facility-profile/` + value.user_unique_id}
                                        className=" "
                                      >
                                        {value.name}
                                      </Link>
                                    )) ||
                                      (value.user_role_id == 3 && (
                                        <Link
                                          to={`/admin/agency-profile/` + value.user_unique_id}
                                          className=" "
                                        >
                                          {value.name}
                                        </Link>
                                      )) || <span className="">{value.name}</span>}
                                  </td>
                                  <td>
                                    {formatDate(value.created_at, "MMM DD, YYYY")}{" "}
                                    <small className="text-muted">
                                      {formatDate(value.created_at, "hh:mm A")}
                                    </small>
                                  </td>
                                  <td>
                                    <Dropdown className="">
                                      <Dropdown.Toggle
                                        variant="default"
                                        id={`dropdown-basic-` + value.job_id}
                                        key={`dropdown-basic-` + value.job_id}
                                        className="p-0"
                                      >
                                        <i className="mdi mdi-dots-horizontal m-0 text-muted h3" />
                                      </Dropdown.Toggle>

                                      <Dropdown.Menu>
                                        {value.status == 2 && (
                                          <Dropdown.Item onClick={() => updateStatus(value.id, 1)}>
                                            Mark as Active
                                          </Dropdown.Item>
                                        )}
                                        {value.status == 1 && (
                                          <Dropdown.Item onClick={() => updateStatus(value.id, 2)}>
                                            Mark as In-Active
                                          </Dropdown.Item>
                                        )}

                                        <Dropdown.Item
                                          as={NavLink}
                                          to={`/admin/job-post-step1/` + value.unique_id}
                                        >
                                          Edit
                                        </Dropdown.Item>
                                        <Dropdown.Item onClick={() => handleDelete(value)}>
                                          Delete
                                        </Dropdown.Item>
                                      </Dropdown.Menu>
                                    </Dropdown>
                                  </td>
                                </tr>
                              ))}
                            </>
                          )}
                        </>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
              {/* end card-body*/}
            </div>
            {/* end card*/}
          </div>
          {/* end col */}
        </div>
        <div className="row">
          <div className="col-md-12  mb-2">Total {jobs.length} Job Post</div>
        </div>
        {/* end row */}
      </Layout>

      {isLoading === true && <Spinner />}
    </>
  );
};

export default AdminJobs;
