import React, { useState, useEffect } from "react";
import Header from "../../Layout/Header";
import Footer from "../../Layout/Footer";
import Sidebar from "../../Layout/Sidebar";
import ThemeSettings from "../../Layout/ThemeSettings";
import PersonalInfo from "./Tabs/PersonalInfo";
import { Link } from "react-router-dom";
import * as api from "../../../services/ApiService";
import * as tokenUtils from "../../../utils/tokenUtils";
import PageTitle from "../../Layout/PageTitle";
import { currentRole } from "../../Layout/HelmetComponent";

function Profile() {
  // Get data from token util
  let user = tokenUtils.getTokenData();

  const [states, setStates] = useState([]);

  useEffect(() => {
    // Function to fetch data from the API
    const fetchData = async () => {
      try {
        const apiData = await api.getMethod("get-states");
        setStates(apiData.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    // Call the fetchData function when the component mounts
    fetchData();
  }, []); // Empty dependency array ensures that the effect runs only once, when the component mounts

  const breadcrumbItems = [
    { text: currentRole + " Dashboard", link: "/client/dashboard" },
    { text: "Company Profile", link: null },
  ];

  return (
    <>
      <div id="wrapper">
        <Sidebar />

        <div className="content-page">
          <Header />

          <div className="content">
            {/* Start Content*/}
            <div className="container-fluid">
              {/* start page title */}
              <PageTitle title="Company Profile" breadcrumbItems={breadcrumbItems} />
              {/* end page title */}
              <div className="row">
                <div className="col-lg-12 col-xl-12">
                  <div className="card">
                    <div className="card-body">
                      <PersonalInfo states={states} />
                    </div>
                  </div>

                  {/* end card*/}
                </div>
                {/* end col */}
              </div>
              {/* end row */}
            </div>
            {/* container */}
          </div>

          <Footer />
        </div>
      </div>

      <ThemeSettings />
    </>
  );
}

export default Profile;
