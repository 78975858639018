import React, { useState, useEffect } from "react";
import * as tokenUtils from "../../../../utils/tokenUtils";
import * as api from "../../../../services/ApiService";
import { Link } from "react-router-dom";

function Connections({ profileInfo }) {
  // Get data from token util
  let user = tokenUtils.getTokenData();

  const [connections, setconnections] = useState({});
  const [loading, setLoading] = useState(true);
  const [noRecordsFound, setNoRecordsFound] = useState(false);

  const fetchApplicants = async () => {
    try {
      const param = { user_id: user.id, userID: profileInfo.id };
      const apiData = await api.postMethod(param, "client/get-user-connections");

      setconnections(apiData.data);
      setLoading(false);
      setNoRecordsFound(false);
      // Check if data array is empty
      if (apiData.data.length === 0) {
        setNoRecordsFound(true);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchApplicants();
  }, []); // Empty dependency array ensures that the effect runs only once, when the component mounts

  return (
    <>
      {loading ? (
        <p className="text-center">Loading...</p>
      ) : (
        <>
          {noRecordsFound ? (
            <p className="text-center">No connections available</p>
          ) : (
            <>
              <div className="row">
                {Object.entries(connections).map(([key, value]) => (
                  <div className="col-md-3 text-center mb-2" key={value.id}>
                    <Link to={`/client/user-profile/` + value.unique_id} className="text-dark">
                      {value.profile_pic_path ? (
                        <img
                          src={value.profile_pic_path}
                          className="rounded-circle img-thumbnail avatar-xl"
                          alt={value.name}
                        />
                      ) : (
                        <img
                          src={process.env.PUBLIC_URL + "/assets/images/users/default-user.png"}
                          className="rounded-circle img-thumbnail avatar-xl"
                          alt={value.name}
                        />
                      )}
                      <h4 className="mt-2">{value.name}</h4>
                    </Link>
                  </div>
                ))}
              </div>
            </>
          )}
        </>
      )}
    </>
  );
}

export default Connections;
