import * as toast from "../utils/toastUtils";
import axios from "axios";
const token = localStorage.getItem('token');

/*  Update Personal Info  */
export async function updatePersonalInfo(data, endpoint) {
  let url = process.env.REACT_APP_API_URL + endpoint;

  // Send form data to the server
  const response = await axios.post(url, data, {
    headers: {
      "Content-Type": "multipart/form-data",
      'Authorization': `Bearer ${token}`,
    },
  });

  let res = response.data;
  if (res.status) {
    toast.success(res.message);
    return true;
  } else {
    toast.error(res.message);
    return false;
  }

  /*const response = await fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "multipart/form-data"
    },
    body: data // JSON.stringify(data)
  });

  if (!response.ok) {
    //throw new Error("Failed to fetch data");
    toast.error("Something went wrong");
    return false;
  }

  const res = await response.json();

  if (res.status) {
    toast.success(res.message);
    return true;
  } else {
    toast.error(res.message);
    return false;
  }*/
}

/*  Get Personal Info  */
export async function getPersonalInfo(data, endpoint) {
  let url = process.env.REACT_APP_API_URL + endpoint;

  const response = await fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      'Authorization': `Bearer ${token}`,
    },
    body: JSON.stringify(data),
  });

  if (!response.ok) {
    //throw new Error("Failed to fetch data");
    toast.error("Something went wrong");
    return false;
  }

  const res = await response.json();

  if (res.status) {
    return res.data;
  } else {
    toast.error(res.message);
    return false;
  }
}

/*  Update Work History  */
export async function updateWorkHistory(data, endpoint) {
  let url = process.env.REACT_APP_API_URL + endpoint;

  const response = await fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      'Authorization': `Bearer ${token}`,
    },
    body: JSON.stringify(data),
  });

  if (!response.ok) {
    //throw new Error("Failed to fetch data");
    toast.error("Something went wrong");
    return false;
  }

  const res = await response.json();

  if (res.status) {
    toast.success(res.message);
    return true;
  } else {
    toast.error(res.message);
    return false;
  }
}

/*  Delete Work History  */
export async function deleteWorkHistory(data, endpoint) {
  let url = process.env.REACT_APP_API_URL + endpoint;

  const response = await fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      'Authorization': `Bearer ${token}`,
    },
    body: JSON.stringify(data),
  });

  if (!response.ok) {
    //throw new Error("Failed to fetch data");
    toast.error("Something went wrong");
    return false;
  }

  const res = await response.json();

  if (res.status) {
    toast.success(res.message);
    return true;
  } else {
    toast.error(res.message);
    return false;
  }
}

/*  Update Educational Info  */
export async function updateEducationalInfo(data, endpoint) {
  let url = process.env.REACT_APP_API_URL + endpoint;

  const response = await fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      'Authorization': `Bearer ${token}`,
    },
    body: JSON.stringify(data),
  });

  if (!response.ok) {
    //throw new Error("Failed to fetch data");
    toast.error("Something went wrong");
    return false;
  }

  const res = await response.json();

  if (res.status) {
    toast.success(res.message);
    return true;
  } else {
    toast.error(res.message);
    return false;
  }
}

/*  Delete Educational Info  */
export async function deleteEducationalInfo(data, endpoint) {
  let url = process.env.REACT_APP_API_URL + endpoint;

  const response = await fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      'Authorization': `Bearer ${token}`,
    },
    body: JSON.stringify(data),
  });

  if (!response.ok) {
    //throw new Error("Failed to fetch data");
    toast.error("Something went wrong");
    return false;
  }

  const res = await response.json();

  if (res.status) {
    toast.success(res.message);
    return true;
  } else {
    toast.error(res.message);
    return false;
  }
}

/*  Update Skills  */
export async function updateSkill(data, endpoint) {
  let url = process.env.REACT_APP_API_URL + endpoint;

  const response = await fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      'Authorization': `Bearer ${token}`,
    },
    body: JSON.stringify(data),
  });

  if (!response.ok) {
    //throw new Error("Failed to fetch data");
    toast.error("Something went wrong");
    return false;
  }

  const res = await response.json();

  if (res.status) {
    toast.success(res.message);
    return true;
  } else {
    toast.error(res.message);
    return false;
  }
}

/*  Delete Skill  */
export async function deleteSkill(data, endpoint) {
  let url = process.env.REACT_APP_API_URL + endpoint;

  const response = await fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      'Authorization': `Bearer ${token}`,
    },
    body: JSON.stringify(data),
  });

  if (!response.ok) {
    //throw new Error("Failed to fetch data");
    toast.error("Something went wrong");
    return false;
  }

  const res = await response.json();

  if (res.status) {
    toast.success(res.message);
    return true;
  } else {
    toast.error(res.message);
    return false;
  }
}

/* Update Job Preference*/
export async function updateJobPreference(data, endpoint) {
  let url = process.env.REACT_APP_API_URL + endpoint;

  const response = await fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      'Authorization': `Bearer ${token}`,
    },
    body: JSON.stringify(data),
  });

  if (!response.ok) {
    //throw new Error("Failed to fetch data");
    toast.error("Something went wrong");
    return false;
  }

  const res = await response.json();

  if (res.status) {
    toast.success(res.message);
    return true;
  } else {
    toast.error(res.message);
    return false;
  }
}
