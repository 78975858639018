import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const MySwal = withReactContent(Swal);

const useRestore = () => {
  const restore = async (options) => {
    const result = await MySwal.fire({
      title: options?.title || "Are you sure you want to restore this?",
      text: options?.text || "You will be able to undo this action!",
      icon: options?.icon || "info",
      showCancelButton: true,
      confirmButtonColor: options?.confirmButtonColor || "#3085d6",
      cancelButtonColor: options?.cancelButtonColor || "#d33",
      confirmButtonText: options?.confirmButtonText || "Yes, restore it!",
      cancelButtonText: options?.cancelButtonText || "No, cancel!",
    });

    return result;
  };

  return { restore };
};

export default useRestore;
