import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const MySwal = withReactContent(Swal);

const useRejectRequest = () => {
  const rejectRequest = async (options) => {
    const result = await MySwal.fire({
      title: options?.title || "Are you sure you want to reject this document access request?",
      /*text: options?.text || "You will be able to undo this action!",*/
      icon: options?.icon || "error",
      showCancelButton: true,
      confirmButtonColor: options?.confirmButtonColor || "#3085d6",
      cancelButtonColor: options?.cancelButtonColor || "#d33",
      confirmButtonText: options?.confirmButtonText || "Yes",
      cancelButtonText: options?.cancelButtonText || "No",
    });

    return result;
  };

  return { rejectRequest };
};

export default useRejectRequest;
