import React from "react";

function ThemeSettings() {
  return (
    <div
      className="offcanvas offcanvas-end right-bar"
      tabIndex={-1}
      id="theme-settings-offcanvas"
    >
      <div className="offcanvas-body p-3 h-100" data-simplebar="">
        {/* Tab panes */}
        <div className="mt-n3">
          <h6 className="fw-medium py-2 px-3 font-13 text-uppercase bg-light mx-n3 mt-n3 mb-3">
            <span className="d-block py-1">Theme Settings</span>
          </h6>
        </div>
        <h5 className="fw-medium font-14 mt-4 mb-2 pb-1">Color Scheme</h5>
        <div className="colorscheme-cardradio">
          <div className="d-flex flex-column gap-2">
            <div className="form-check form-switch">
              <input
                className="form-check-input"
                type="checkbox"
                name="data-bs-theme"
                id="layout-color-light"
                defaultValue="light"
              />
              <label className="form-check-label" htmlFor="layout-color-light">
                Light
              </label>
            </div>
            <div className="form-check form-switch">
              <input
                className="form-check-input"
                type="checkbox"
                name="data-bs-theme"
                id="layout-color-dark"
                defaultValue="dark"
              />
              <label className="form-check-label" htmlFor="layout-color-dark">
                Dark
              </label>
            </div>
          </div>
        </div>
        <h5 className="fw-medium font-14 mt-4 mb-2 pb-1">Content Width</h5>
        <div className="d-flex flex-column gap-2">
          <div className="form-check form-switch">
            <input
              className="form-check-input"
              type="checkbox"
              name="data-layout-width"
              id="layout-width-default"
              defaultValue="default"
            />
            <label className="form-check-label" htmlFor="layout-width-default">
              Fluid (Default)
            </label>
          </div>
          <div className="form-check form-switch">
            <input
              className="form-check-input"
              type="checkbox"
              name="data-layout-width"
              id="layout-width-boxed"
              defaultValue="boxed"
            />
            <label className="form-check-label" htmlFor="layout-width-boxed">
              Boxed
            </label>
          </div>
        </div>
        <div id="layout-mode">
          <h5 className="fw-medium font-14 mt-4 mb-2 pb-1">Layout Mode</h5>
          <div className="d-flex flex-column gap-2">
            <div className="form-check form-switch">
              <input
                className="form-check-input"
                type="checkbox"
                name="data-layout-mode"
                id="layout-mode-default"
                defaultValue="default"
              />
              <label className="form-check-label" htmlFor="layout-mode-default">
                Default
              </label>
            </div>
            <div id="layout-detached">
              <div className="form-check form-switch">
                <input
                  className="form-check-input"
                  type="checkbox"
                  name="data-layout-mode"
                  id="layout-mode-detached"
                  defaultValue="detached"
                />
                <label
                  className="form-check-label"
                  htmlFor="layout-mode-detached"
                >
                  Detached
                </label>
              </div>
            </div>
          </div>
        </div>
        <h5 className="fw-medium font-14 mt-4 mb-2 pb-1">Topbar Color</h5>
        <div className="d-flex flex-column gap-2">
          <div className="form-check form-switch">
            <input
              className="form-check-input"
              type="checkbox"
              name="data-topbar-color"
              id="topbar-color-light"
              defaultValue="light"
            />
            <label className="form-check-label" htmlFor="topbar-color-light">
              Light
            </label>
          </div>
          <div className="form-check form-switch">
            <input
              className="form-check-input"
              type="checkbox"
              name="data-topbar-color"
              id="topbar-color-dark"
              defaultValue="dark"
            />
            <label className="form-check-label" htmlFor="topbar-color-dark">
              Dark
            </label>
          </div>
          <div className="form-check form-switch">
            <input
              className="form-check-input"
              type="checkbox"
              name="data-topbar-color"
              id="topbar-color-brand"
              defaultValue="brand"
            />
            <label className="form-check-label" htmlFor="topbar-color-brand">
              Brand
            </label>
          </div>
        </div>
        <div>
          <h5 className="fw-medium font-14 mt-4 mb-2 pb-1">Menu Color</h5>
          <div className="d-flex flex-column gap-2">
            <div className="form-check form-switch">
              <input
                className="form-check-input"
                type="checkbox"
                name="data-menu-color"
                id="leftbar-color-light"
                defaultValue="light"
              />
              <label className="form-check-label" htmlFor="leftbar-color-light">
                Light
              </label>
            </div>
            <div className="form-check form-switch">
              <input
                className="form-check-input"
                type="checkbox"
                name="data-menu-color"
                id="leftbar-color-dark"
                defaultValue="dark"
              />
              <label className="form-check-label" htmlFor="leftbar-color-dark">
                Dark
              </label>
            </div>
            <div className="form-check form-switch">
              <input
                className="form-check-input"
                type="checkbox"
                name="data-menu-color"
                id="leftbar-color-brand"
                defaultValue="brand"
              />
              <label className="form-check-label" htmlFor="leftbar-color-brand">
                Brand
              </label>
            </div>
            <div className="form-check form-switch">
              <input
                className="form-check-input"
                type="checkbox"
                name="data-menu-color"
                id="leftbar-color-gradient"
                defaultValue="gradient"
              />
              <label
                className="form-check-label"
                htmlFor="leftbar-color-gradient"
              >
                Gradient
              </label>
            </div>
          </div>
        </div>
        <div id="menu-icon-color">
          <h5 className="fw-medium font-14 mt-4 mb-2 pb-1">Menu Icon Color</h5>
          <div className="d-flex flex-column gap-2">
            <div className="form-check form-switch">
              <input
                className="form-check-input"
                type="checkbox"
                name="data-two-column-color"
                id="twocolumn-menu-color-light"
                defaultValue="light"
              />
              <label
                className="form-check-label"
                htmlFor="twocolumn-menu-color-light"
              >
                Light
              </label>
            </div>
            <div className="form-check form-switch">
              <input
                className="form-check-input"
                type="checkbox"
                name="data-two-column-color"
                id="twocolumn-menu-color-dark"
                defaultValue="dark"
              />
              <label
                className="form-check-label"
                htmlFor="twocolumn-menu-color-dark"
              >
                Dark
              </label>
            </div>
            <div className="form-check form-switch">
              <input
                className="form-check-input"
                type="checkbox"
                name="data-two-column-color"
                id="twocolumn-menu-color-brand"
                defaultValue="brand"
              />
              <label
                className="form-check-label"
                htmlFor="twocolumn-menu-color-brand"
              >
                Brand
              </label>
            </div>
            <div className="form-check form-switch">
              <input
                className="form-check-input"
                type="checkbox"
                name="data-two-column-color"
                id="twocolumn-menu-color-gradient"
                defaultValue="gradient"
              />
              <label
                className="form-check-label"
                htmlFor="twocolumn-menu-color-gradient"
              >
                Gradient
              </label>
            </div>
          </div>
        </div>
        <div>
          <h5 className="fw-medium font-14 mt-4 mb-2 pb-1">Menu Icon Tone</h5>
          <div className="d-flex flex-column gap-2">
            <div className="form-check form-switch">
              <input
                className="form-check-input"
                type="checkbox"
                name="data-menu-icon"
                id="menu-icon-default"
                defaultValue="default"
              />
              <label className="form-check-label" htmlFor="menu-icon-default">
                Default
              </label>
            </div>
            <div className="form-check form-switch">
              <input
                className="form-check-input"
                type="checkbox"
                name="data-menu-icon"
                id="menu-icon-twotone"
                defaultValue="twotones"
              />
              <label className="form-check-label" htmlFor="menu-icon-twotone">
                Twotone
              </label>
            </div>
          </div>
        </div>
        <div id="sidebar-size">
          <h5 className="fw-medium font-14 mt-4 mb-2 pb-1">Sidebar Size</h5>
          <div className="d-flex flex-column gap-2">
            <div className="form-check form-switch">
              <input
                className="form-check-input"
                type="checkbox"
                name="data-sidenav-size"
                id="leftbar-size-default"
                defaultValue="default"
              />
              <label
                className="form-check-label"
                htmlFor="leftbar-size-default"
              >
                Default
              </label>
            </div>
            <div className="form-check form-switch">
              <input
                className="form-check-input"
                type="checkbox"
                name="data-sidenav-size"
                id="leftbar-size-compact"
                defaultValue="compact"
              />
              <label
                className="form-check-label"
                htmlFor="leftbar-size-compact"
              >
                Compact (Medium Width)
              </label>
            </div>
            <div className="form-check form-switch">
              <input
                className="form-check-input"
                type="checkbox"
                name="data-sidenav-size"
                id="leftbar-size-small"
                defaultValue="condensed"
              />
              <label className="form-check-label" htmlFor="leftbar-size-small">
                Condensed (Icon View)
              </label>
            </div>
            <div className="form-check form-switch">
              <input
                className="form-check-input"
                type="checkbox"
                name="data-sidenav-size"
                id="leftbar-size-full"
                defaultValue="full"
              />
              <label className="form-check-label" htmlFor="leftbar-size-full">
                Full Layout
              </label>
            </div>
            <div className="form-check form-switch">
              <input
                className="form-check-input"
                type="checkbox"
                name="data-sidenav-size"
                id="leftbar-size-fullscreen"
                defaultValue="fullscreen"
              />
              <label
                className="form-check-label"
                htmlFor="leftbar-size-fullscreen"
              >
                Fullscreen Layout
              </label>
            </div>
          </div>
        </div>
        <div id="sidebar-user">
          <h5 className="fw-medium font-14 mt-4 mb-2 pb-1">
            Sidebar User Info
          </h5>
          <div className="form-check form-switch">
            <input
              type="checkbox"
              className="form-check-input"
              name="data-sidebar-user"
              id="sidebaruser-check"
            />
            <label className="form-check-label" htmlFor="sidebaruser-check">
              Enable
            </label>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ThemeSettings;
