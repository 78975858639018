// ThemeToggleButton.js
import React, { useContext } from "react";
import { ThemeContext } from "./ThemeContext";

const ThemeToggleButton = () => {
  const { theme, toggleTheme } = useContext(ThemeContext);

  return (
    <div class="nav-link waves-effect waves-light" id="light-dark-mode" onClick={toggleTheme}>
      <i class="ri-moon-line font-22"></i>
    </div>
  );
};

export default ThemeToggleButton;
