import React, { useState, useEffect } from "react";
import Header from "../../Layout/Header";
import Footer from "../../Layout/Footer";
import Sidebar from "../../Layout/Sidebar";
import ThemeSettings from "../../Layout/ThemeSettings";
import { useParams, Link, useNavigate } from "react-router-dom";
import * as api from "../../../services/ApiService";
import * as tokenUtils from "../../../utils/tokenUtils";
import Spinner from "../../Loader/Spinner";
import { toast } from "react-toastify";

function ChecklistDetails() {
  // Get data from token util
  let user = tokenUtils.getTokenData();

  const { checklistId } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  let navigate = useNavigate();

  /* Checklist Submission */
  const [formData, setformData] = useState({
    user_id: user.id,
    token: user.token,
  });

  const [checklistRow, setChecklistRow] = useState({});

  const [checklistMeta, setChecklistMeta] = useState([]);

  const fetchChecklistDetails = async () => {
    try {
      const param = {
        user_id: user.id,
        token: user.token,
        checklistId: checklistId,
      };
      const apiData = await api.postMethod(param, "user/get-checklist-detail");

      if (apiData.status) {
        setChecklistRow(apiData.data);

        setChecklistMeta(JSON.parse(apiData.data.checklist_meta));
        setIsLoading(false);
      } else {
        navigate("/user/checklists");
      }
    } catch (error) {
      setIsLoading(false);
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchChecklistDetails();
  }, []); // Empty dependency array ensures that the effect runs only once, when the component mounts

  const fetchUserChecklistDetails = async (checklistMeta) => {
    try {
      const param = {
        user_id: user.id,
        token: user.token,
        checklistId: checklistRow.id,
      };
      const apiData = await api.postMethod(param, "user/get-user-checklist-detail");

      if (apiData.status && apiData.data.checklist_meta) {
        setSelectedValues(JSON.parse(apiData.data.checklist_meta));
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchUserChecklistDetails();
  }, [checklistMeta]); // Empty dependency array ensures that the effect runs only once, when the component mounts

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = {
      day: "2-digit",
      month: "short",
      year: "numeric",
    };
    const formattedDate = date.toLocaleDateString("en-US", options);
    return `${formattedDate}`;
  };

  const formatTime = (dateString) => {
    const date = new Date(dateString);
    const formattedTime = date.toLocaleTimeString("en-US", {
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    });
    return `${formattedTime}`;
  };

  const [selectedValues, setSelectedValues] = useState({});

  const [submissionType, setSubmissionType] = useState(0);

  const toggleSubmissionType = (type) => {
    setSubmissionType(type);
  };

  // Handle form submission
  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    try {
      const param = {
        user_id: user.id,
        token: user.token,
        checklist_id: checklistRow.id,
        checklist_meta: selectedValues,
        submissionType: submissionType,
      };

      const result = await api.postMethod(param, "user/update-checklist-detail");

      if (result.status) {
        setIsLoading(false);

        if (submissionType == 1) {
          navigate("/user/skill-checklists?type=completed");
        } else if (submissionType == 0) {
          navigate("/user/skill-checklists?type=draft");
        }
        toast.success(result.message);
      } else {
        setIsLoading(false);
        toast.error(result.message);
      }
    } catch (error) {
      setIsLoading(false);
      console.error("Error fetching data:", error);
    }
  };

  // Handle radio button change
  const handleChange = (sectionIndex, skillIndex, type, value) => {
    setSelectedValues((prev) => ({
      ...prev,
      [sectionIndex]: {
        ...prev[sectionIndex],
        [skillIndex]: { ...prev[sectionIndex]?.[skillIndex], [type]: value },
      },
    }));
  };

  // Render radio buttons
  const renderRadioButtons = (sectionIndex, skillIndex, type, options) => {
    return options.map((option, optionIndex) => (
      <label key={optionIndex} className="mx-1">
        <input
          type="radio"
          name={`${sectionIndex}-${skillIndex}-${type}`}
          value={option.value}
          checked={selectedValues[sectionIndex]?.[skillIndex]?.[type] === option.value}
          onChange={() => handleChange(sectionIndex, skillIndex, type, option.value)}
          className="me-1"
        />
        {option.title}
      </label>
    ));
  };

  return (
    <>
      <div id="wrapper">
        <Sidebar />

        <div className="content-page">
          <Header />

          <div className="content">
            {/* Start Content*/}
            <div className="container-fluid">
              {/* start page title */}
              <div className="row">
                <div className="col-12">
                  <div className="page-title-box">
                    <div className="page-title-right">
                      <ol className="breadcrumb m-0">
                        <li className="breadcrumb-item">
                          <Link to="/dashboard">Home</Link>
                        </li>
                        <li className="breadcrumb-item">
                          <Link to="/user/skill-checklists">Skill Checklists</Link>
                        </li>
                        <li className="breadcrumb-item active">{checklistRow.title}</li>
                      </ol>
                    </div>
                    <h4 className="page-title">{checklistRow.title}</h4>
                  </div>
                </div>
              </div>
              {/* end page title */}
              <form className="" onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-12">
                    <div className="card custom-box-shadow">
                      <div className="card-body">
                        <div className="row">
                          <div className="col-md-3">
                            <strong>Checklist Title</strong>
                          </div>
                          <div className="col-md-3">{checklistRow.title}</div>
                          <div className="col-md-3">
                            <strong>Assignment Date</strong>
                          </div>
                          <div className="col-md-3">
                            {formatDate(checklistRow.created_at)}{" "}
                            {formatTime(checklistRow.created_at)}
                          </div>
                        </div>
                      </div>
                      {/* end card-body*/}
                    </div>

                    {checklistMeta.length > 0 &&
                      checklistMeta.map((section, sectionIndex) => (
                        <div key={sectionIndex} className="card custom-box-shadow">
                          <div className="card-header border-bottom">
                            <h4 className="header-title pb-0 mb-0">{section.sectionTitle}</h4>
                          </div>
                          <div className="card-body">
                            <table className="table table-bordered mb-0 table-sm">
                              <thead>
                                <tr>
                                  <th style={{ width: "33%" }}> </th>
                                  <th className="text-center" style={{ width: "33%" }}>
                                    Proficiency
                                  </th>
                                  <th className="text-center" style={{ width: "33%" }}>
                                    Frequency
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {section.skills.map((skill, skillIndex) => (
                                  <tr className="mt-2" key={skillIndex}>
                                    <th>
                                      <p className="m-0">{skill.skillTitle}</p>
                                    </th>
                                    <td className="text-center">
                                      {renderRadioButtons(
                                        section.sectionTitle,
                                        skill.skillTitle,
                                        "proficiency",
                                        skill.options.proficiency
                                      )}
                                    </td>
                                    <td className="text-center">
                                      {renderRadioButtons(
                                        section.sectionTitle,
                                        skill.skillTitle,
                                        "frequency",
                                        skill.options.frequency
                                      )}
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      ))}

                    <div className="card custom-box-shadow">
                      <div className="card-body">
                        <button
                          type="submit"
                          className="btn btn-secondary"
                          onClick={() => toggleSubmissionType(0)}
                        >
                          Save as Draft
                        </button>

                        <button
                          type="submit"
                          className="btn btn-primary float-end"
                          onClick={() => toggleSubmissionType(1)}
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                  </div>
                  {/* end col */}
                </div>
              </form>
              {/* end row */}
            </div>
            {/* container */}
          </div>

          <Footer />
        </div>
      </div>

      <ThemeSettings />
      {isLoading === true && <Spinner />}
    </>
  );
}

export default ChecklistDetails;
