// Spinner.js

import React from "react";
import "./Spinner.css";

const Spinner = () => {
  return (
    <div className="spinner-container">
      <img
        src={process.env.PUBLIC_URL + "/assets/images/loading.gif"}
        alt="Loading"
        className="rounded-circle"
      />
      {/* 
        <svg width="50" height="50" viewBox="0 0 100 100">
            <circle cx="50" cy="50" r="40" stroke="white" fill="transparent" stroke-width="4" stroke-dasharray="28.274333561000066 70.68583375800007" transform="translate(50,50) rotate(0)">
                <animateTransform attributeName="transform" type="rotate" from="0 50 50" to="360 50 50" dur="1s" repeatCount="indefinite" />
            </circle>
        </svg>
      <div className="label">Please wait...</div>
      */}
    </div>
  );
};

export default Spinner;
