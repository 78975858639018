import React, { useEffect, useState } from "react";
import Layout from "../../Layout/Index";
import ThemeSettings from "../../Layout/ThemeSettings";
import { Link } from "react-router-dom";
import PageTitle from "../../Layout/PageTitle";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { currentRole } from "../../Layout/HelmetComponent";
import { Modal } from "react-bootstrap";
import * as api from "../../../services/ApiService";
import * as tokenUtils from "../../../utils/tokenUtils";
import { toast } from "react-toastify";
import Spinner from "../../Loader/Spinner";

function Checkout() {
  let user = tokenUtils.getTokenData();

  const breadcrumbItems = [
    { text: "Home", link: "/user/dashboard" },
    { text: "Checkout", link: null },
  ];

  return (
    <Layout>
      <div className="container-fluid">
        {/* start page title */}
        <PageTitle title="Checkout" breadcrumbItems={breadcrumbItems} />
        {/* end page title */}
        <div className="row mb-3">
          <div className="col-lg-4">
            <div className="card">
              <div className="card-body">
                <h4 className="header-title mb-3">Subscriptions</h4>

                <div className="pb-2">
                  <label className="form-label">Package</label>
                  <select className="form-control">
                    <option value="">Tier 1 ($19.00 Per Month)</option>
                    <option value="">Tier 2 ($1000 Per Month)</option>
                  </select>
                </div>

                <div className="pb-2">
                  <label className="form-label">Type</label>
                  <select className="form-control">
                    <option value="">Monthly</option>
                    <option value="">Yearly</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="card">
              <div className="card-body">
                <h4 className="header-title mb-3">Subscription Validity</h4>

                <div className="pb-2">
                  <label className="form-label">Subscription Start Date</label>
                  <input type="text" className="form-control" disabled value="22 June 2024" />
                </div>

                <div className="pb-2">
                  <label className="form-label">Subscription End Date</label>
                  <input type="text" className="form-control" disabled value="22 July 2024" />
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="card">
              <div className="card-body">
                <h4 className="header-title mb-3">Add-Ons</h4>

                <div className="mb-1">
                  <label>
                    <input type="checkbox" className="custom-control-input" /> Recruitment
                    Coordinator ($20 per hour)
                  </label>
                </div>

                <div className="mb-1">
                  <label>
                    <input type="checkbox" className="custom-control-input" /> On-Call Scheduling
                    Coordinator (after hrs) ($100 per shift (depending on call volume))
                  </label>
                </div>

                <div className="mb-1">
                  <label>
                    <input type="checkbox" className="custom-control-input" /> Phone Operator ($100
                    per shift (depending on call volume))
                  </label>
                </div>

                <div className="mb-1">
                  <label>
                    <input type="checkbox" className="custom-control-input" /> Account Manager ($20
                    per hour )
                  </label>
                </div>

                <div className="mb-1">
                  <label>
                    <input type="checkbox" className="custom-control-input" /> Compliance
                    Coordinator ($20 per hour )
                  </label>
                </div>

                <div className="mb-1">
                  <label>
                    <input type="checkbox" className="custom-control-input" /> Scheduling
                    Coordinator ($20 per hour )
                  </label>
                </div>

                <div className="mb-1">
                  <label>
                    <input type="checkbox" className="custom-control-input" /> Payroll Coordinator
                    ($20 per hour )
                  </label>
                </div>

                <div className="mb-1">
                  <label>
                    <input type="checkbox" className="custom-control-input" /> Billing Coordinator
                    ($20 per hour )
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="card">
          <div className="card-body">
            <h4 className="header-title mb-3">Subscription Payment Details</h4>
            <div className="table-responsive">
              <table className="table table-bordered table-centered mb-0">
                <thead className="thead-light">
                  <tr>
                    <th>Subscription Plan</th>
                    <th>Quantity</th>
                    <th>Price</th>
                    <th>Total</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th scope="row">Tier 1</th>
                    <td>1</td>
                    <td>$9.9</td>
                    <td>$9.9</td>
                  </tr>
                  <tr>
                    <th scope="row" colSpan={3} className="text-right">
                      Sub Total :
                    </th>
                    <td>
                      <div className="font-weight-bold">$9.9</div>
                    </td>
                  </tr>
                  <tr>
                    <th scope="row" colSpan={3} className="text-right">
                      Total :
                    </th>
                    <td>
                      <div className="font-weight-bold">$9.9</div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div className="text-end mb-3">
          <button type="button" class="btn btn-primary">
            <i class="mdi mdi-cart-plus mr-1"></i> Proceed to Checkout
          </button>
        </div>
      </div>
    </Layout>
  );
}

export default Checkout;
