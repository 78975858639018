import React from 'react'
import Layout from '../../Layout/Index'
import { currentRole } from "../../Layout/HelmetComponent";
import PageTitle from "../../Layout/PageTitle";
import { Button } from 'react-bootstrap';
import { MDBAccordion, MDBAccordionItem } from 'mdb-react-ui-kit';

const SubscriptionDetails = () => {
    const breadcrumbItems = [
        { text: currentRole + " Dashboard", link: "/client/dashboard" },
        { text: "Subscription Details", link: null },
      ]
  return <>
   <Layout>
   <PageTitle
          title="Subscription Details"
          breadcrumbItems={breadcrumbItems}
        />
<div className='d-flex w-100 justify-content-around' >
<div style={{width:"30%"}}
            className="col-md-3"
            // onMouseEnter={handleMouseEnter} style={hoverStyle}
          >
        <div className={`card card-pricing  `}>
              <div
                className="card-body text-center pb-0 ">
  
  <p className="card-pricing-plan-name font-weight-bold text-uppercase fw-bold">
                 Current Plan
                </p>
                <span className="card-pricing-icon text-primary">
                  <i className="fe-aperture" />
                </span>
                <h2
                  className="card-pricing-price "
                  style={{
                    fontSize: "26px",
                    fontWeight: 700,
                    borderTop: "3px solid #efeefc",
                    color: "#8686e0",
                  }}
                >
                  $1300 <span style={{ color: "#8686e0" }}>/ Month</span>
                </h2>
                <p className="mb-0 text-dark">(20% off with annual plan)</p>


                <MDBAccordion flush >
      <MDBAccordionItem collapseId={1} headerTitle={
          <span style={{fontSize:"14px", fontWeight:700,color: "#ff5712"}} >Recruitment Tools</span>
        }>
         <p style={{fontWeight:"700",fontSize:"13px"}}> Unlimited Job Posting Slots</p>
                <p style={{fontWeight:"700",fontSize:"13px"}}> Job Posting Management</p>
                <p style={{fontWeight:"700",fontSize:"13px"}}>Applicant Tracking</p>
                <p style={{fontWeight:"700",fontSize:"13px"}}>Follow Up Management</p>
                <p style={{fontWeight:"700",fontSize:"13px"}}> Submission Files</p>
      </MDBAccordionItem>
      <MDBAccordionItem collapseId={2} headerTitle={
          <span style={{fontSize:"14px", fontWeight:700,color: "#ff5712"}} >Compliance File Tools</span>
        }>
          <p style={{fontWeight:"700",fontSize:"13px"}}> Manage Employee Compliance Files</p>
                <p style={{fontWeight:"700",fontSize:"13px"}}> Facility Compliance Lists</p>
                <p style={{fontWeight:"700",fontSize:"13px"}}> Skills Checklists</p>
      </MDBAccordionItem>
      <MDBAccordionItem collapseId={3} headerTitle={
          <span style={{fontSize:"14px", fontWeight:700,color: "#ff5712"}} >Account Management Tools</span>
        }>
       <p style={{fontWeight:"700",fontSize:"13px"}}> Manage Employees on Assignment</p>
       <p style={{fontWeight:"700",fontSize:"13px"}}> Manage Submissions</p>
      </MDBAccordionItem>
      <MDBAccordionItem collapseId={4} headerTitle={
          <span style={{fontSize:"14px", fontWeight:700,color: "#ff5712"}} >Administration & Management Tools</span>
        }>
         <p style={{fontWeight:"700",fontSize:"13px"}}> Admin Dashboard</p>
                <p style={{fontWeight:"700",fontSize:"13px"}}> Messaging/Inbox</p>
                <p style={{fontWeight:"700",fontSize:"13px"}}> Calendar & Reminders</p>
                <p style={{fontWeight:"700",fontSize:"13px"}}> Task Management</p>
                <p style={{fontWeight:"700",fontSize:"13px"}}> Activity Logs</p>
                <p style={{fontWeight:"700",fontSize:"13px"}}> Add Admin Users</p>
      </MDBAccordionItem>
      <MDBAccordionItem collapseId={5} headerTitle={
          <span style={{fontSize:"14px", fontWeight:700,color: "#ff5712"}} >Customer Service Tools</span>
        }>
       <p style={{fontWeight:"700",fontSize:"13px"}}> Dedicated Success Manager</p>
                <p style={{fontWeight:"700",fontSize:"13px"}}> Dedicated Support Line</p>
                <p style={{fontWeight:"700",fontSize:"13px"}}> Dedicated Support Emai</p>
      </MDBAccordionItem>
    </MDBAccordion>

                
              </div>
            </div>
            </div>
<div style={{width:"30%"}} >
        <div className="card-body custom-box-shadow p-2">
                          <div className="row">
                            <div className="col-6">
                              <div className="avatar-lg rounded-circle bg-soft-primary border-primary border">
                                <p className=" font-22 avatar-title text-primary" >28</p>
                              </div>
                              <p>Days Remaing</p>
                            </div>
                            <div className="col-6 mt-2">
                              <div className="text-end">
                                <h4 className="mt-1">
                                  <span data-plugin="counterup">330/365</span>
                                </h4>
                                <p className="text-info mb-1 text-truncate">Days</p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="card-body custom-box-shadow p-2 mt-2">
                          <div className="row">
                            <div className="col-6">
                              <div className="avatar-lg rounded-circle bg-soft-primary border-primary border">
                                <p className=" font-20 avatar-title text-primary" >Tier 3</p>
                              </div>
                              {/* <p>Plan</p> */}
                            </div>
                            <div className="col-6 mt-2">
                              <div className="text-end">
                                <h4 className="mt-1">
                                  <span data-plugin="counterup">Plan</span>
                                </h4>
                                {/* <p className="text-info mb-1 text-truncate">Days</p> */}
                              </div>
                            </div>
                          </div>
                        </div>
        </div>
        
       
            <div style={{width:"30%"}}>
            <div className="card-body custom-box-shadow p-2">
                          <div className="row">
                            <div className="col-6">
                             Plans History
                            </div>
                            <div className="col-6 mt-2" style={{height:"30vw"}}>
                              <div className="text-end">
                                
                              </div>
                            </div>
                          </div>
                        </div>
                        <Button style={{marginTop:"2vw",marginBottom:"2vw",marginLeft:"17vw"}}>Modify Plan</Button>

            </div>
       
</div>
      
              
        
   </Layout>
  </>
}

export default SubscriptionDetails
