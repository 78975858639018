import React, { useState, useEffect } from "react";
import Layout from "../../Layout/Index";
import ThemeSettings from "../../Layout/ThemeSettings";
import { Link, useLocation } from "react-router-dom";
import { Tooltip } from "react-tooltip";
import Spinner from "../../Loader/Spinner";
import { toast } from "react-toastify";
import * as Yup from "yup";
import * as tokenUtils from "../../../utils/tokenUtils";
import * as api from "../../../services/ApiService";
import Modal from "react-bootstrap/Modal";
import Dropdown from "react-bootstrap/Dropdown";
import PageTitle from "../../Layout/PageTitle";
import { currentRole } from "../../Layout/HelmetComponent";
import { formatDate } from "../../../utils/dateFormat";
import ScheduleInterviewModal from "./ScheduleInterviewModal";
import useConfirm from "../../../hooks/useConfirm";
import Announcement from "../../UI/Announcement";

const JobInterviews = () => {
  // Get data from token util
  let user = tokenUtils.getTokenData();

  const location = useLocation();
  const isStaff = location.pathname.includes("/staff");
  const urlSlug = isStaff ? "staff" : "client";

  /* Filters function Start */
  const [showFilterDropdown, setFilterDropdown] = useState(false);
  const handleToggleFilterDropDown = () => {
    setFilterDropdown(!showFilterDropdown);
  };

  const [editInterViewData, setEditInterViewData] = useState({});

  // State for filter values
  const [filters, setFilters] = useState({
    keyword: "",
  });

  // Handle form reset
  const handleReset = () => {
    const defaultFilters = {
      keyword: "",
    };
    setFilters(defaultFilters);
    setFilterDropdown(false);
  };

  useEffect(() => {
    if (showFilterDropdown === false) {
      fetchJobInterviews();
    }
  }, [showFilterDropdown]);

  // Handle form input change
  const handleFilterInputChange = (e) => {
    const { name, value, type, checked } = e.target;

    if (type === "checkbox") {
      setFilters({
        ...filters,
        [name]: checked,
      });
    } else {
      setFilters({
        ...filters,
        [name]: value,
      });
    }
  };

  // Handle form submit
  const handleFilterSubmit = (e) => {
    e.preventDefault();
    setFilterDropdown(false);
  };
  /* Filters function End */

  const [uploadAddOpen, setAddModalOpen] = useState(false);

  const toggleAddModal = () => {
    setAddModalOpen(!uploadAddOpen);
  };

  const handleUserApplicationStatus = async (job_id, user_id, status) => {
    try {
      setIsLoading(true);
      const param = {
        user_id: user.id,
        job_id: job_id,
        emp_id: user_id,
        status: status,
      };
      const result = await api.postMethod(param, "client/update-user-job-application-status");

      if (result.status) {
        fetchJobInterviews();
        setIsLoading(false);
      } else setIsLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setIsLoading(false);
    }
  };

  const handleEdit = (record) => {
    // Populate formData with the values of the selected record
    setEditInterViewData({
      id: record.job_interview_id,
      user_id: user.id,
      title: record.title,
      jobId: record.job_id ? record.job_id : 0,
      empId: record.user_id,
      interviewerId: record.interviewer_id,
      interviewDate: record.interview_date,
      interviewTime: record.interview_time,
    });

    setAddModalOpen(true);
  };

  const { confirm } = useConfirm();
  const handleDelete = async (record) => {
    const confirmed = await confirm();
    if (confirmed.isConfirmed) {
      setIsLoading(true);
      record.user_id = user.id;
      const result = await api.postMethod(record, "client/delete-job-interview");
      setIsLoading(false);
      if (result.status) fetchJobInterviews();
    }
  };

  const [isLoading, setIsLoading] = useState(false);

  const [appliedJobs, setAppliedJob] = useState({});
  const [appliedJobLoading, setAppliedJobLoading] = useState(true);
  const [noAppliedJobRecordsFound, setNoAppliedJobRecordsFound] = useState(false);

  const fetchJobInterviews = async () => {
    try {
      setIsLoading(true);
      const param = {
        user_id: user.id,
      };
      const queryParams = { ...param, ...filters };
      const result = await api.postMethod(queryParams, "client/get-job-interviews");
      if (result.status) {
        setAppliedJob(result.data);

        setAppliedJobLoading(false);
        setNoAppliedJobRecordsFound(false);
        // Check if data array is empty
        if (result.data.length === 0) {
          setNoAppliedJobRecordsFound(true);
        }
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setAppliedJobLoading(false);
    }
  };

  useEffect(() => {
    fetchJobInterviews();
  }, []); // Empty dependency array ensures that the effect runs only once, when the component mounts

  function convertTimeTo12HourFormat(timeString) {
    const [hours, minutes] = timeString.split(":");
    const time = new Date();
    time.setHours(parseInt(hours, 10));
    time.setMinutes(parseInt(minutes, 10));
    return time.toLocaleTimeString("en-US", { hour: "2-digit", minute: "2-digit", hour12: true });
  }

  const breadcrumbItems = [
    { text: currentRole + " Dashboard", link: "/" + urlSlug + "/dashboard" },
    { text: "Follow Ups", link: null },
  ];

  const announcementModule = user?.role_id === 2 ? "FACILITY_FOLLOW_UP" : user?.role_id === 3 ? "AGENCY_FOLLOW_UP" : undefined;
  
  return (
    <Layout>
      <Announcement module={announcementModule} />
      <PageTitle title="Follow Ups" breadcrumbItems={breadcrumbItems} />

      {/* end page title */}
      <div className="row">
        <div className="col-12">
          <div className="card custom-box-shadow">
            <div className="card-body">
              <div className="row justify-content-between mb-2">
                <div className="col-auto">
                  <div className="dropdown" style={{ width: 300 }}>
                    <button
                      type="button"
                      className="btn btn-outline-light dropdown-toggle w-100 text-start"
                      onClick={handleToggleFilterDropDown}
                    >
                      Filters
                      <i className="mdi mdi-chevron-down float-end" />
                    </button>
                    <div className={`dropdown-menu ${showFilterDropdown ? "show" : ""}`}>
                      <form className="px-2 py-2" onSubmit={handleFilterSubmit}>
                        <div className="mb-2">
                          <label htmlFor="keyword" className="form-label">
                            Keyword
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="keyword"
                            name="keyword"
                            value={filters.keyword}
                            onChange={handleFilterInputChange}
                          />
                        </div>
                        <div className="text-end">
                          <button
                            type="button"
                            className="btn btn-light float-start"
                            onClick={handleReset}
                          >
                            Reset Filter
                          </button>
                          <button type="submit" className="btn btn-primary">
                            Apply Filter
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
                <div className="col-auto">
                  <button
                    type="button"
                    className="btn btn-primary waves-effect waves-light mb-2 me-1"
                    onClick={toggleAddModal}
                  >
                    <i className="fa fa-plus mr-1" /> Add New Follow Up
                  </button>
                </div>
                {/* end col*/}
              </div>
              <div className="table-responsive">
                <table className="table table-centered table-nowrap mb-0">
                  <thead className="table-light">
                    <tr>
                      <th>Follow Up Title</th>
                      <th>Assignee</th>
                      <th>Office Admin</th>
                      <th>Follow Up Date/Time</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {appliedJobLoading ? (
                      <tr key="">
                        <td colSpan="5">Loading...</td>
                      </tr>
                    ) : (
                      <>
                        {noAppliedJobRecordsFound ? (
                          <tr key="">
                            <td colSpan="5">No follow ups found</td>
                          </tr>
                        ) : (
                          <>
                            {Object.entries(appliedJobs).map(([key, value]) => (
                              <tr key={value.job_interview_id}>
                                <td>{value.title || "-"}</td>
                                <td>
                                  <Link
                                    to={`/` + urlSlug + `/user-profile/` + value.user_unique_id}
                                    className=""
                                  >
                                    {value.name}
                                  </Link>
                                </td>
                                <td>{value.interviewer_name}</td>
                                <td>
                                  {formatDate(value.interview_date, "MMM DD, YYYY")}{" "}
                                  <small className="text-muted">
                                    {convertTimeTo12HourFormat(value.interview_time)}
                                  </small>
                                </td>

                                <td>
                                  <Dropdown className="">
                                    <Dropdown.Toggle
                                      variant="default"
                                      id={`dropdown-basic-` + value.job_id}
                                      key={`dropdown-basic-` + value.job_id}
                                      className="p-0"
                                    >
                                      <i className="mdi mdi-dots-horizontal m-0 text-muted h3" />
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu>
                                      <Dropdown.Item onClick={() => handleEdit(value)}>
                                        Edit
                                      </Dropdown.Item>
                                      <Dropdown.Item onClick={() => handleDelete(value)}>
                                        Delete
                                      </Dropdown.Item>

                                      {/*
                                      <Dropdown.Divider />
                                      <Dropdown.Header>Change Status to</Dropdown.Header>
                                      <Dropdown.Item
                                        onClick={() =>
                                          handleUserApplicationStatus(
                                            value.job_id,
                                            value.user_id,
                                            5
                                          )
                                        }
                                      >
                                        Offered
                                      </Dropdown.Item>
                                      <Dropdown.Item
                                        onClick={() =>
                                          handleUserApplicationStatus(
                                            value.job_id,
                                            value.user_id,
                                            7
                                          )
                                        }
                                      >
                                        Rejected
                                      </Dropdown.Item>
                                       */}
                                    </Dropdown.Menu>
                                  </Dropdown>
                                </td>
                              </tr>
                            ))}
                          </>
                        )}
                      </>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
            {/* end card-body*/}
          </div>
          {/* end card*/}
        </div>
        {/* end col */}
      </div>

      <ScheduleInterviewModal
        editInterViewData={editInterViewData}
        uploadAddOpen={uploadAddOpen}
        toggleAddModal={toggleAddModal}
        fetchJobInterviews={fetchJobInterviews}
      />

      {isLoading === true && <Spinner />}
    </Layout>
  );
};

export default JobInterviews;
