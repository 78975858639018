import React, { useState, useEffect } from "react";
import Header from "../../Layout/Header";
import Footer from "../../Layout/Footer";
import Sidebar from "../../Layout/Sidebar";
import ThemeSettings from "../../Layout/ThemeSettings";
import { useParams, Link, useNavigate } from "react-router-dom";
import * as api from "../../../services/ApiService";
import { Tooltip } from "react-tooltip";
import * as tokenUtils from "../../../utils/tokenUtils";
import * as Yup from "yup";
import Spinner from "../../Loader/Spinner";
import { toast } from "react-toastify";
import Modal from "react-bootstrap/Modal";
import PageTitle from "../../Layout/PageTitle";
import { currentRole } from "../../Layout/HelmetComponent";
import { formatDate } from "../../../utils/dateFormat";

function ComplianceAddChecklist() {
  let navigate = useNavigate();

  const { checklistID } = useParams();

  // Get data from token util
  let user = tokenUtils.getTokenData();
  const [isLoading, setIsLoading] = useState(false);

  const [formData, setFormData] = useState({
    id: null,
    user_id: user.id,
    token: user.token,
    title: "",
    status: "",
    sections: [
      {
        sectionTitle: "",
        skills: [
          {
            skillTitle: "",
            options: {
              proficiency: [{ title: "", value: "" }],
              frequency: [{ title: "", value: "" }],
            },
          },
        ],
      },
    ],
  });

  const fetchUserDetails = async () => {
    try {
      const param = {
        user_id: user.id,
        token: user.token,
        checklistID: checklistID,
      };
      const apiData = await api.postMethod(param, "client/get-compliance-checklist-details");

      if (apiData.status) {
        setFormData({
          ...formData,
          id: apiData.data.id,
          status: apiData.data.status,
          user_id: user.id,
          token: user.token,
          title: apiData.data.title,
          sections: JSON.parse(apiData.data.checklist_meta),
        });
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchUserDetails();
  }, [checklistID]); // Empty dependency array ensures that the effect runs only once, when the component mounts

  const handleTitleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));

    validateFields(name, value);
  };

  const handleChange = (e, sectionIndex, skillIndex, fieldType, optionIndex) => {
    const { name, value } = e.target;

    const updatedFormData = { ...formData }; // Make a shallow copy
    if (fieldType === "section") {
      updatedFormData.sections[sectionIndex][name] = value;
    } else if (fieldType === "skill") {
      updatedFormData.sections[sectionIndex].skills[skillIndex][name] = value;
    } else {
      updatedFormData.sections[sectionIndex].skills[skillIndex].options[fieldType][optionIndex][
        name
      ] = value;
    }

    setFormData(updatedFormData);
  };

  const addSection = () => {
    const updatedFormData = { ...formData }; // Make a shallow copy
    updatedFormData.sections.push({
      sectionTitle: "",
      skills: [
        {
          skillTitle: "",
          options: {
            proficiency: [{ title: "", value: "" }],
            frequency: [{ title: "", value: "" }],
          },
        },
      ],
    });
    setFormData(updatedFormData);
  };

  const addSkill = (sectionIndex) => {
    const updatedFormData = { ...formData }; // Make a shallow copy
    updatedFormData.sections[sectionIndex].skills.push({
      skillTitle: "",
      options: {
        proficiency: [{ title: "", value: "" }],
        frequency: [{ title: "", value: "" }],
      },
    });
    setFormData(updatedFormData);
  };

  const addOption = (sectionIndex, skillIndex) => {
    const updatedFormData = { ...formData }; // Make a shallow copy
    updatedFormData.sections[sectionIndex].skills[skillIndex].options.proficiency.push({
      title: "",
      value: "",
    });
    updatedFormData.sections[sectionIndex].skills[skillIndex].options.frequency.push({
      title: "",
      value: "",
    });
    setFormData(updatedFormData);
  };

  const [errors, setErrors] = useState({});

  //Define the validation schema
  const validationSchema = Yup.object({
    title: Yup.string().required("Title is Required"),
  });

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      await validationSchema.validate(formData, { abortEarly: false });

      setIsLoading(true);
      const result = await api.postFileMethod(formData, "client/update-compliance-checklist");
      setIsLoading(false);
      if (result.status) {
        toast.success(result.message);
        navigate("/client/compliance-checklists");
      } else {
        toast.error(result.message);
      }
    } catch (error) {
      setIsLoading(false);
      const newErrors = {};
      if (error.inner && Array.isArray(error.inner)) {
        error.inner.forEach((element) => {
          newErrors[element.path] = element.message;
        });
      } else {
        console.error("Unexpected error structure:", error);
      }
      setErrors(newErrors);
    }
  };

  const validateFields = (name, value) => {
    try {
      validationSchema.validateSyncAt(name, { [name]: value });

      // Clear the error for the current field if validation passes
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: "",
      }));
    } catch (error) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: error.message,
      }));
    }
  };

  const breadcrumbItems = [
    { text: currentRole + " Dashboard", link: "/client/dashboard" },
    { text: "Compliance Checklists", link: "/client/compliance-checklists" },
    { text: "Add Compliance Checklist", link: null },
  ];

  return (
    <>
      <div id="wrapper">
        <Sidebar />

        <div className="content-page">
          <Header />
          <div className="content">
            {/* Start Content*/}
            <div className="container-fluid">
              {/* start page title */}
              <PageTitle title="Add Compliance Checklists" breadcrumbItems={breadcrumbItems} />

              <div className="row">
                <div className="col-12">
                  <form onSubmit={handleSubmit}>
                    <div className="card custom-box-shadow">
                      <div className="card-body pb-0">
                        <div className="form-group mb-3">
                          <div className="row">
                            <div className="col-md-6">
                              <label htmlFor="title" className="mb-1">
                                Title *
                              </label>
                              <input
                                className="form-control"
                                type="text"
                                name="title"
                                value={formData.title}
                                onChange={handleTitleChange}
                              />
                              {errors.title && <span className="error">{errors.title}</span>}
                            </div>
                            <div className="col-md-6">
                              <label htmlFor="status" className="mb-1">
                                Status
                              </label>
                              <select
                                className="form-control"
                                name="status"
                                value={formData.status}
                                onChange={handleTitleChange}
                              >
                                <option value="1">Active</option>
                                <option value="0">In-Active</option>
                                <option value="2">Draft</option>
                              </select>
                            </div>
                          </div>
                        </div>

                        {/* Sections */}
                        {formData.sections.map((section, sectionIndex) => (
                          <div key={sectionIndex} className="form-group mb-3">
                            <div className="card border mb-0">
                              <div className="card-header border-bottom">
                                <h5 className="card-title">Section {sectionIndex + 1}</h5>
                                <input
                                  placeholder="Section Title"
                                  className="form-control"
                                  type="text"
                                  name="sectionTitle"
                                  value={section.sectionTitle}
                                  onChange={(e) =>
                                    handleChange(e, sectionIndex, null, "section", null)
                                  }
                                />
                              </div>

                              <div className="card-body pb-0">
                                {section.skills.map((skill, skillIndex) => (
                                  <div key={skillIndex} className="card border mb-3">
                                    <div className="card-header border-bottom">
                                      <h5 className="card-title">Skill List {skillIndex + 1}</h5>
                                      <input
                                        type="text"
                                        name="skillTitle"
                                        placeholder="Skill Title"
                                        className="form-control"
                                        value={skill.skillTitle}
                                        onChange={(e) =>
                                          handleChange(e, sectionIndex, skillIndex, "skill", null)
                                        }
                                      />
                                    </div>

                                    <div className="card-body pb-0">
                                      <div className="row">
                                        <div className="col-md-6">
                                          {["proficiency"].map((optionType, index) => (
                                            <div key={index}>
                                              <h5
                                                className="mt-0 card-title"
                                                style={{ textTransform: "capitalize" }}
                                              >
                                                {optionType} Options
                                              </h5>
                                              {skill.options[optionType].map(
                                                (option, optionIndex) => (
                                                  <div key={optionIndex} className="row mb-3">
                                                    <div className="col-md-6">
                                                      <div className="input-group">
                                                        <span className="input-group-text">
                                                          Title
                                                        </span>

                                                        <input
                                                          className="form-control"
                                                          type="text"
                                                          name="title"
                                                          value={option.title}
                                                          onChange={(e) =>
                                                            handleChange(
                                                              e,
                                                              sectionIndex,
                                                              skillIndex,
                                                              optionType,
                                                              optionIndex
                                                            )
                                                          }
                                                        />
                                                      </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                      <div className="input-group">
                                                        <span className="input-group-text">
                                                          Value
                                                        </span>
                                                        <input
                                                          className="form-control"
                                                          type="text"
                                                          name="value"
                                                          value={option.value}
                                                          onChange={(e) =>
                                                            handleChange(
                                                              e,
                                                              sectionIndex,
                                                              skillIndex,
                                                              optionType,
                                                              optionIndex
                                                            )
                                                          }
                                                        />
                                                      </div>
                                                    </div>
                                                  </div>
                                                )
                                              )}
                                            </div>
                                          ))}
                                        </div>
                                        <div className="col-md-6">
                                          {["frequency"].map((optionType, index) => (
                                            <div key={index}>
                                              <h5
                                                className="mt-0 card-title"
                                                style={{ textTransform: "capitalize" }}
                                              >
                                                {optionType} Options
                                              </h5>
                                              {skill.options[optionType].map(
                                                (option, optionIndex) => (
                                                  <div key={optionIndex} className="row mb-3">
                                                    <div className="col-md-6">
                                                      <div className="input-group">
                                                        <span className="input-group-text">
                                                          Title
                                                        </span>
                                                        <input
                                                          className="form-control"
                                                          type="text"
                                                          name="title"
                                                          value={option.title}
                                                          onChange={(e) =>
                                                            handleChange(
                                                              e,
                                                              sectionIndex,
                                                              skillIndex,
                                                              optionType,
                                                              optionIndex
                                                            )
                                                          }
                                                        />
                                                      </div>
                                                    </div>

                                                    <div className="col-md-6">
                                                      <div className="input-group">
                                                        <span className="input-group-text">
                                                          Value
                                                        </span>
                                                        <input
                                                          className="form-control"
                                                          type="text"
                                                          name="value"
                                                          value={option.value}
                                                          onChange={(e) =>
                                                            handleChange(
                                                              e,
                                                              sectionIndex,
                                                              skillIndex,
                                                              optionType,
                                                              optionIndex
                                                            )
                                                          }
                                                        />
                                                      </div>
                                                    </div>
                                                  </div>
                                                )
                                              )}
                                            </div>
                                          ))}
                                        </div>
                                      </div>
                                    </div>
                                    <div className="card-footer text-end border-top">
                                      <button
                                        className="btn btn-secondary"
                                        type="button"
                                        onClick={() => addOption(sectionIndex, skillIndex)}
                                      >
                                        Add New Option
                                      </button>
                                    </div>
                                  </div>
                                ))}
                              </div>

                              <div className="card-footer text-end border-top">
                                <button
                                  type="button"
                                  className="btn btn-secondary"
                                  onClick={() => addSkill(sectionIndex)}
                                >
                                  Add Skill
                                </button>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>

                      <div className="card-footer pt-0">
                        <div className="text-end">
                          <button className="btn btn-secondary" type="button" onClick={addSection}>
                            Add Section
                          </button>
                        </div>

                        <div className="text-lg-end mt-2 pt-2 border-top">
                          <button type="submit" className="btn btn-primary me-1 ">
                            Submit
                          </button>
                        </div>
                      </div>
                      {/* end card-body*/}
                    </div>
                    {/* end card*/}
                  </form>
                </div>
                {/* end col */}
              </div>
              {/* end row */}
            </div>
            {/* container */}
          </div>

          <Footer />
        </div>
      </div>

      <ThemeSettings />
      {isLoading === true && <Spinner />}
    </>
  );
}

export default ComplianceAddChecklist;
