import React from 'react'

const ActiveUser = () => {
  return (
    <div>
        <div>
      <div className="card mb-0">
      <div className="row g-0 d-flex">
      <div className="col-md-2" style={{marginLeft:"2vw"}}>
          <img
            src={`https://firebasestorage.googleapis.com/v0/b/smu-saas.appspot.com/o/Default_Images%2FBackground%2FSMU_wide.jpg?alt=media&token=e0b9396c-ee3d-4764-969b-682f8c744f16`}
            alt={`'s profile`}
            className="img-fluid rounded-circle"
            style={{ maxWidth: '80px', height:"80px" }}
          />
          <div className='mt-2'>

            <h5 className="card-title"> <strong>Name:</strong> Test Name</h5>
          </div>
       
      </div>
        
        <div className="col-md-5 ">
          <div className="card-body">
            <p className="card-text">
              <strong>Email:</strong> testing1234@gmail.com
            </p>
            <p className="card-text">
              <strong>Phone Number:</strong> 9988776655
            </p>
            
          </div>
        </div>
        <div className="col-md-4">
          <div className="card-body">
            <p className="card-text">
              <strong>Plan Type:</strong> Teir 3
            </p>
            <p className="card-text">
              <strong>Start Date:</strong> 10/16/2024
            </p>
            <p className="card-text">
              <strong>End Date:</strong> 11/16/2024
            </p>
          </div>
        </div>
      </div>
    </div>
    </div>
    <div className='mt-2'>
      <div className="card mb-0 pt-2 ">
      <div className="row g-0 d-flex">
      <div className="col-md-2" style={{marginLeft:"2vw"}}>
          <img
            src={`https://firebasestorage.googleapis.com/v0/b/smu-saas.appspot.com/o/Default_Images%2FBackground%2FSMU_wide.jpg?alt=media&token=e0b9396c-ee3d-4764-969b-682f8c744f16`}
            alt={`'s profile`}
            className="img-fluid rounded-circle"
            style={{ maxWidth: '80px', height:"80px" }}
          />
          <div className='mt-2'>

            <h5 className="card-title"> <strong>Name:</strong> Testing User</h5>
          </div>
       
      </div>
        
        <div className="col-md-5 ">
          <div className="card-body">
            <p className="card-text">
              <strong>Email:</strong> testing@gmail.com
            </p>
            <p className="card-text">
              <strong>Phone Number:</strong> 9876543210
            </p>
            
          </div>
        </div>
        <div className="col-md-4">
          <div className="card-body">
            <p className="card-text">
              <strong>Plan Type:</strong> Teir 4
            </p>
            <p className="card-text">
              <strong>Start Date:</strong> 11/16/2024
            </p>
            <p className="card-text">
              <strong>End Date:</strong> 12/16/2024
            </p>
          </div>
        </div>
      </div>
    </div>
    </div>
    </div>
  )
}

export default ActiveUser
