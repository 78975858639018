import React, { useState } from "react";
import { Button } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import * as api from "../../../../../../services/ApiService";
import { toast } from "react-toastify";
import moment from "moment";
const ReferenceForm = ({ index, reference, handleChange, refDetails,fetchReferences }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [formData, setformData] = useState({});
  const [verificationDetail,setVerificationDetails] = useState()
  const toggleModal = () => {
    setModalOpen(!modalOpen);
  };
  const viewRef = (refDetails) => {
    setformData(refDetails);
    setModalOpen(true);
  };
  
const referenceVerified = async()=>{
  try{
    const param = {
      userID:reference.user_id,
      referenceID:reference.reference_id,
      reference_by_verified_by:verificationDetail.reference_by_verified_by,
      reference_by_verified_by_date :verificationDetail.reference_by_verified_by_date 
    }
    var result = await api.postMethod(param, "admin/reference-verified-by-admin");
    if (result.status) {
      toast.success(result.message);
      fetchReferences()
      toggleModal()
    }
      else{
        toast.error(result.message);
      }
  }
  
  catch(error){
    console.error("Error fetching data:", error)
  }
}
// console.log("verificationDetail",verificationDetail)
const handleVerification = (e) => {
  const { name, value } = e.target;
  setVerificationDetails({ ...verificationDetail, [name]: value });
  // validateFields(name, value);
};
  return (
    <div>
      <h5>Reference {index + 1}</h5>
      <div className="row">
        <div className="col-md-3 mb-2">
          <label className="form-label fw-bold">Title</label>
          <br />
          <span>{reference.title || "-"}</span>
        </div>

        <div className="col-md-2 mb-2 ">
          <label className="form-label fw-bold">Name</label>
          <br />
          <span>{reference.name || "-"}</span>
        </div>

        <div className="col-md-3 mb-2 ">
          <label className="form-label fw-bold">Email</label>
          <br />
          <span>{reference.email || "-"}</span>
        </div>

        <div className="col-md-3 mb-2">
          <label className="form-label fw-bold">Phone</label>
          <br />
          <span>{reference.phone || "-"}</span>
        </div>
        {
          reference.reference_id? 
          reference.is_verify==0?
          <Button
          className="col-md-1 mb-3 mt-1 "
          onClick={() => viewRef(refDetails)}
        >
          Verify
        </Button>: <Button
          className="col-md-1 mb-3 mt-1 " style={{background:"#32bc32f7", border:"none"}}
        >
          Verified
        </Button>:""
        }
        
      </div>
      <Modal
        show={modalOpen}
        onHide={toggleModal}
        centered
        backdrop="static"
        size="xl"
      >
        <Modal.Header closeButton>
          <Modal.Title className="my-0">View Reference Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h5 className="border-bottom pb-2">Reference Completed By</h5>
          <div className="row">
            <div className="col-md-3">
              <div className="mb-2">
                <label className="form-label fw-bold">Name</label>
                <p>{reference?.reference_by_name || "-"}</p>
              </div>
            </div>

            <div className="col-md-3">
              <div className="mb-2">
                <label className="form-label fw-bold">Title</label>
                <p>{reference?.reference_by_title}</p>
              </div>
            </div>

            <div className="col-md-3">
              <div className="mb-2">
                <label className="form-label fw-bold">Phone</label>
                <p>{reference?.reference_by_phone || "-"}</p>
              </div>
            </div>

            <div className="col-md-3">
              <div className="mb-2">
                <label className="form-label fw-bold">Email</label>
                <p>{reference?.reference_by_email || "-"}</p>
              </div>
            </div>

            <div className="col-md-3">
              <div className="mb-2">
                <label className="form-label fw-bold">Signature</label>
                <p>{reference?.reference_by_signature || "-"}</p>
              </div>
            </div>

            <div className="col-md-3">
              <div className="mb-2">
                <label className="form-label fw-bold">Date</label>
                <p>{moment(reference?.reference_by_signature_date).format('MM/DD/YYYY')}</p>
              </div>
            </div>

            {/* <div className="col-md-3">
              <div className="mb-2">
                <label className="form-label fw-bold">Reference Information Verified By</label>
                <p>{reference.details?.reference_by_verified_by || "-"}</p>
              </div>
            </div>

            <div className="col-md-3">
              <div className="mb-2">
                <label className="form-label fw-bold">Date</label>
                <p>{reference.details?.reference_by_verified_by_date || "-"}</p>
              </div>
            </div> */}
          </div>

          <h5 className="border-bottom pb-2">Employment Information</h5>
          <div className="row">
            <div className="col-md-4">
              <div className="mb-2">
                <label className="form-label fw-bold">Facility</label>
                <p>{reference?.facility || "-"}</p>
              </div>
            </div>

            <div className="col-md-4">
              <div className="mb-2">
                <label className="form-label fw-bold">
                  Dates of Employment
                </label>
                <p>{reference?.dates_of_employment || "-"}</p>
              </div>
            </div>

            <div className="col-md-4">
              <div className="mb-2">
                <label className="form-label fw-bold">Address</label>
                <p>{reference?.address || "-"}</p>
              </div>
            </div>

            <div className="col-md-4">
              <div className="mb-2">
                <label className="form-label fw-bold">
                  Title while Employed
                </label>
                <p>{reference?.title_while_employed || "-"}</p>
              </div>
            </div>

            <div className="col-md-4">
              <div className="mb-2">
                <label className="form-label fw-bold">Phone</label>
                <p>{reference?.phone || "-"}</p>
              </div>
            </div>

            <div className="col-md-4">
              <div className="mb-2">
                <label className="form-label fw-bold">Specialty Worked</label>
                <p>{reference?.specialty_worked || "-"}</p>
              </div>
            </div>

            <div className="col-md-12">
              <table className="table table-bordered">
                <thead>
                  <tr>
                    <th></th>
                    <th className="text-center">Yes</th>
                    <th className="text-center">No</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th>1. How long have you worked with this candidate?</th>
                    <td className="text-center">
                      {reference?.worked_with === "Yes" && (
                        <i class="fas fa-check"></i>
                      )}
                    </td>
                    <td className="text-center">
                      {reference?.worked_with === "No" && (
                        <i class="fas fa-check"></i>
                      )}
                    </td>
                  </tr>
                  <tr>
                    <th>
                      2. Is this person honest, reliable, and trustworthy?
                    </th>
                    <td className="text-center">
                      {reference?.honest_reliable === "Yes" && (
                        <i class="fas fa-check"></i>
                      )}
                    </td>
                    <td className="text-center">
                      {reference?.honest_reliable === "No" && (
                        <i class="fas fa-check"></i>
                      )}
                    </td>
                  </tr>
                  <tr>
                    <th>3. Was this candidate on a travel assignment?</th>
                    <td className="text-center">
                      {reference?.travel_assignment === "Yes" && (
                        <i class="fas fa-check"></i>
                      )}
                    </td>
                    <td className="text-center">
                      {reference?.travel_assignment === "No" && (
                        <i class="fas fa-check"></i>
                      )}
                    </td>
                  </tr>
                  <tr>
                    <th>4. Is this candidate eligible for rehire?</th>
                    <td className="text-center">
                      {reference?.eligible_rehire === "Yes" && (
                        <i class="fas fa-check"></i>
                      )}
                    </td>
                    <td className="text-center">
                      {reference?.eligible_rehire === "No" && (
                        <i class="fas fa-check"></i>
                      )}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <h5 className="border-bottom pb-2">
            Candidate Employment Evaluation
          </h5>

          <div className="row">
            <div className="col-md-12">
              <table className="table table-bordered">
                <thead>
                  <tr>
                    <th>Criteria</th>
                    <th className="text-center">Poor</th>
                    <th className="text-center">Fair</th>
                    <th className="text-center">Average</th>
                    <th className="text-center">Good</th>
                    <th className="text-center">Excellent</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th>1. Quality of Work</th>
                    <td className="text-center">
                      {reference?.quality_of_work === "Poor" && (
                        <i class="fas fa-check"></i>
                      )}
                    </td>
                    <td className="text-center">
                      {reference?.quality_of_work === "Fair" && (
                        <i class="fas fa-check"></i>
                      )}
                    </td>
                    <td className="text-center">
                      {reference?.quality_of_work === "Average" && (
                        <i class="fas fa-check"></i>
                      )}
                    </td>
                    <td className="text-center">
                      {reference?.quality_of_work === "Good" && (
                        <i class="fas fa-check"></i>
                      )}
                    </td>
                    <td className="text-center">
                      {reference?.quality_of_work === "Excellent" && (
                        <i class="fas fa-check"></i>
                      )}
                    </td>
                  </tr>
                  <tr>
                    <th>2. Clinical Knowledge/Skills</th>
                    <td className="text-center">
                      {reference?.clinical_knowledge === "Poor" && (
                        <i class="fas fa-check"></i>
                      )}
                    </td>
                    <td className="text-center">
                      {reference?.clinical_knowledge === "Fair" && (
                        <i class="fas fa-check"></i>
                      )}
                    </td>
                    <td className="text-center">
                      {reference?.clinical_knowledge === "Average" && (
                        <i class="fas fa-check"></i>
                      )}
                    </td>
                    <td className="text-center">
                      {reference?.clinical_knowledge === "Good" && (
                        <i class="fas fa-check"></i>
                      )}
                    </td>
                    <td className="text-center">
                      {reference?.clinical_knowledge === "Excellent" && (
                        <i class="fas fa-check"></i>
                      )}
                    </td>
                  </tr>
                  <tr>
                    <th>3. Attendance/Dependability</th>
                    <td className="text-center">
                      {reference?.attendance_dependability === "Poor" && (
                        <i class="fas fa-check"></i>
                      )}
                    </td>
                    <td className="text-center">
                      {reference?.attendance_dependability === "Fair" && (
                        <i class="fas fa-check"></i>
                      )}
                    </td>
                    <td className="text-center">
                      {reference?.attendance_dependability === "Average" && (
                        <i class="fas fa-check"></i>
                      )}
                    </td>
                    <td className="text-center">
                      {reference?.attendance_dependability === "Good" && (
                        <i class="fas fa-check"></i>
                      )}
                    </td>
                    <td className="text-center">
                      {reference?.attendance_dependability === "Excellent" && (
                        <i class="fas fa-check"></i>
                      )}
                    </td>
                  </tr>
                  <tr>
                    <th>4. Interpersonal/Communication Skills</th>
                    <td className="text-center">
                      {reference?.communication_skills === "Poor" && (
                        <i class="fas fa-check"></i>
                      )}
                    </td>
                    <td className="text-center">
                      {reference?.communication_skills === "Fair" && (
                        <i class="fas fa-check"></i>
                      )}
                    </td>
                    <td className="text-center">
                      {reference?.communication_skills === "Average" && (
                        <i class="fas fa-check"></i>
                      )}
                    </td>
                    <td className="text-center">
                      {reference?.communication_skills === "Good" && (
                        <i class="fas fa-check"></i>
                      )}
                    </td>
                    <td className="text-center">
                      {reference?.communication_skills === "Excellent" && (
                        <i class="fas fa-check"></i>
                      )}
                    </td>
                  </tr>
                  <tr>
                    <th>5. Relationship with Others</th>
                    <td className="text-center">
                      {reference?.relationship_others === "Poor" && (
                        <i class="fas fa-check"></i>
                      )}
                    </td>
                    <td className="text-center">
                      {reference?.relationship_others === "Fair" && (
                        <i class="fas fa-check"></i>
                      )}
                    </td>
                    <td className="text-center">
                      {reference?.relationship_others === "Average" && (
                        <i class="fas fa-check"></i>
                      )}
                    </td>
                    <td className="text-center">
                      {reference?.relationship_others === "Good" && (
                        <i class="fas fa-check"></i>
                      )}
                    </td>
                    <td className="text-center">
                      {reference?.relationship_others === "Excellent" && (
                        <i class="fas fa-check"></i>
                      )}
                    </td>
                  </tr>
                  <tr>
                    <th>6. Acceptance of Supervision</th>
                    <td className="text-center">
                      {reference?.acceptance_supervision === "Poor" && (
                        <i class="fas fa-check"></i>
                      )}
                    </td>
                    <td className="text-center">
                      {reference?.acceptance_supervision === "Fair" && (
                        <i class="fas fa-check"></i>
                      )}
                    </td>
                    <td className="text-center">
                      {reference?.acceptance_supervision === "Average" && (
                        <i class="fas fa-check"></i>
                      )}
                    </td>
                    <td className="text-center">
                      {reference?.acceptance_supervision === "Good" && (
                        <i class="fas fa-check"></i>
                      )}
                    </td>
                    <td className="text-center">
                      {reference?.acceptance_supervision === "Excellent" && (
                        <i class="fas fa-check"></i>
                      )}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="col-md-12">
              <div className="mb-2">
                <label className="form-label fw-bold">
                  Additional Comments
                </label>
                <p>{reference?.additional_comments || "-"}</p>
              </div>
              <div
                className="border-top pt-2 "
                style={{ display: "flex", justifyContent: "space-around" }}
              >
                <div className="col-md-5 ">
                  <div className="mb-2">
                    <label className="form-label fw-bold">Verified By Admin</label>
                    <input type="text" name="reference_by_verified_by" onChange={(e)=>handleVerification(e)} className="form-control" required />
                  </div>
                </div>
                <div className="col-md-5">
                  <div className="mb-2">
                    <label className="form-label fw-bold">Verified Date</label>
                    <input type="date" name="reference_by_verified_by_date" onChange={(e)=>handleVerification(e)} className="form-control" required />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={()=>referenceVerified()}>Verify</Button>
          <button
            type="button"
            className="btn btn-secondary"
            onClick={toggleModal}
          >
            Close
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default ReferenceForm;
