export function getTokenData() {
    const token = localStorage.getItem('token');
    if (!token) return null;
  
    try {
      const decodedToken = atob(atob(token));
      // Assuming your token contains JSON data
      const tokenData = JSON.parse(decodedToken);
      return tokenData;
    } catch (error) {
      console.error('Error decoding token:', error);
      return null;
    }
  };
  