import React from "react";

const ReferenceForm = ({ index, reference, handleChange }) => {
  return (
    <div>
      <h5>Reference {index + 1}</h5>
      <div className="row">
        <div className="col-md-3 mb-2">
          <label className="form-label fw-bold">Title</label>
          <br />
          <span>{reference.title || "-"}</span>
        </div>

        <div className="col-md-3 mb-2">
          <label className="form-label fw-bold">Name</label>
          <br />
          <span>{reference.name || "-"}</span>
        </div>

        <div className="col-md-3 mb-2">
          <label className="form-label fw-bold">Email</label>
          <br />
          <span>{reference.email || "-"}</span>
        </div>

        <div className="col-md-3 mb-2">
          <label className="form-label fw-bold">Phone</label>
          <br />
          <span>{reference.phone || "-"}</span>
        </div>
      </div>
    </div>
  );
};

export default ReferenceForm;
