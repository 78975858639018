import React from "react";
import { Link, useNavigate } from "react-router-dom";
import * as auth from "../../../services/AuthService";

function HeaderUser({ user }) {
  let navigate = useNavigate();

  // logout function
  const handleLogout = async () => {
    try {
      // Call function and API endpoint
      let res = await auth.logout({ user_id: user.id, token: user.token }, "user/logout");
      if (res) {
        setTimeout(() => {
          navigate("/");
        }, 500);
      }
    } catch (error) {
      console.error("Unexpected error structure:", error);
    }
  };

  return (
    <>
      <Link to="/user/profile" className="dropdown-item notify-item">
        <i className="fe-user" /> <span>My Profile</span>
      </Link>

      <div className="dropdown-divider" />
      {/* item*/}
      <Link to="#" onClick={() => handleLogout()} className="dropdown-item notify-item">
        <i className="fe-log-out" /> <span>Logout</span>
      </Link>
    </>
  );
}

export default HeaderUser;
