// utils/phoneNumberFormatter.js

export function formatPhoneNumber(phoneNumber, countryCode = "") {
  // Remove all non-numeric characters
  const cleaned = ("" + phoneNumber).replace(/\D/g, "");

  // Determine if the phone number contains a valid country code
  const isTenDigits = cleaned.length === 10;
  const isValidWithCountryCode = cleaned.length === 11 && cleaned.startsWith("1");

  // Extract the last 10 digits for formatting
  let validNumber = isTenDigits ? cleaned : isValidWithCountryCode ? cleaned.slice(1) : "";

  // If the number is valid, format it and prepend the country code if provided
  if (validNumber.length === 10) {
    const areaCode = validNumber.slice(0, 3);
    const middle = validNumber.slice(3, 6);
    const last = validNumber.slice(6, 10);
    return `${countryCode}(${areaCode})${middle}-${last}`;
  }

  // Return original input if not a valid 10-digit number
  return phoneNumber;
}
