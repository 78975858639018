import React, { useEffect, useState } from "react";
import Layout from "../../Layout/Index";
import { getTokenData } from "../../../utils/tokenUtils";
import EmailAndSmsModal from "./common/EmailAndSmsModal";
import { Modal } from "react-bootstrap";
import Spinner from "../../Loader/Spinner";
import { Link } from "react-router-dom";
import PageTitle from "../../Layout/PageTitle";
import { currentRole } from "../../Layout/HelmetComponent";
import * as api from "../../../services/ApiService";
import { formatDate } from "../../../utils/dateFormat";
const AdminSMS = () => {
  let user = getTokenData();

  /* Filters function Start */
  const [showFilterDropdown, setFilterDropdown] = useState(false);
  const handleToggleFilterDropDown = () => {
    setFilterDropdown(!showFilterDropdown);
  };

  // State for filter values
  const [filters, setFilters] = useState({
    keyword: "",
    start_date: "",
    end_date: "",
  });

  // Handle form reset
  const handleReset = () => {
    const defaultFilters = {
      keyword: "",
      start_date: "",
      end_date: "",
    };
    setFilters(defaultFilters);
    setFilterDropdown(false);
  };

  useEffect(() => {
    if (showFilterDropdown === false) {
      fetchMessages();
    }
  }, [showFilterDropdown]);

  // Handle form input change
  const handleFilterInputChange = (e) => {
    const { name, value, type, checked } = e.target;

    if (type === "checkbox") {
      setFilters({
        ...filters,
        [name]: checked,
      });
    } else {
      setFilters({
        ...filters,
        [name]: value,
      });
    }
  };

  // Handle form submit
  const handleFilterSubmit = (e) => {
    e.preventDefault();
    setFilterDropdown(false);
  };
  /* Filters function End */

  const [showModal, setShowModal] = useState(false);
  const [messages, setMessage] = useState([]);
  const [selectedMessage, setSelectedMessage] = useState(null);
  const [show, setShow] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = (message) => {
    setSelectedMessage(message);
    setShow(true);
  };

  const fetchMessages = async (role_id) => {
    setIsLoading(true);
    try {
      const param = { user_id: user.id, type: "sms" };
      const queryParams = { ...param, ...filters };
      const apiData = await api.postMethod(queryParams, "client/get-messages");
      setMessage(apiData.data);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    if (showModal === false) {
      fetchMessages();
    }
  }, [showModal]);

  const breadcrumbItems = [
    { text: currentRole + " Dashboard", link: "/client/dashboard" },
    { text: "SMS", link: null },
  ];

  return (
    <Layout>
      <div className="container-fluid">
        {/* start page title */}
        <PageTitle title="SMS" breadcrumbItems={breadcrumbItems} />
        {/* end page title */}
        <div className="row">
          <div className="col-12">
            <div className="card custom-box-shadow">
              <div className="card-body">
                <div className="row justify-content-between mb-2">
                  <div className="col-auto">
                    <div className="dropdown" style={{ width: 300 }}>
                      <button
                        type="button"
                        className="btn btn-outline-light dropdown-toggle w-100 text-start"
                        onClick={handleToggleFilterDropDown}
                      >
                        Filters
                        <i className="mdi mdi-chevron-down float-end" />
                      </button>
                      <div className={`dropdown-menu ${showFilterDropdown ? "show" : ""}`}>
                        <form className="px-2 py-2" onSubmit={handleFilterSubmit}>
                          <div className="mb-2">
                            <label htmlFor="keyword" className="form-label">
                              Keyword
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="keyword"
                              name="keyword"
                              value={filters.keyword}
                              onChange={handleFilterInputChange}
                            />
                          </div>

                          <div className="mb-2">
                            <label htmlFor="start_date" className="form-label">
                              Date Range
                            </label>
                            <div className="input-group">
                              <input
                                type="date"
                                name="start_date"
                                className="form-control"
                                value={filters.start_date}
                                onChange={handleFilterInputChange}
                              />
                              <span className="input-group-text">To</span>
                              <input
                                type="date"
                                name="end_date"
                                className="form-control"
                                value={filters.end_date}
                                onChange={handleFilterInputChange}
                              />
                            </div>
                          </div>

                          <div className="text-end mt-1">
                            <button
                              type="button"
                              className="btn btn-light float-start"
                              onClick={handleReset}
                            >
                              Reset
                            </button>
                            <button type="submit" className="btn btn-primary">
                              Submit
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                  {/* end col*/}
                  <div className="col-auto me-2">
                    <button
                      type="button"
                      className="btn btn-primary waves-effect waves-light mb-2 "
                      onClick={() => setShowModal(true)}
                    >
                      <i className="fas fa-mobile" /> Send SMS
                    </button>
                  </div>
                </div>
                <div className="table-responsive">
                  <table className="table table-centered table-nowrap mb-0">
                    <thead className="table-light">
                      <tr>
                        <th>Date</th>
                        <th>Sender</th>
                        <th>Receiver</th>
                        <th>SMS Text</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {messages.map((message) => (
                        <tr key={message.id}>
                          <td>
                            {message.created_at ? (
                              <>
                                {formatDate(message.created_at, "DD MMM YYYY")}{" "}
                                <small className="text-muted">
                                  {formatDate(message.created_at, "hh:mm A")}
                                </small>
                              </>
                            ) : (
                              "N/A"
                            )}
                          </td>
                          <td>{message.from_name ? message.from_name : "N/A"}</td>
                          <td>
                            {message.to_name ? (
                              <Link
                                to={`/client/user-profile/` + message.to_unique_id}
                                className=""
                              >
                                {message.to_name}
                              </Link>
                            ) : (
                              "N/A"
                            )}
                          </td>
                          <td>{message.message ? message.message : "N/A"}</td>
                          <td>
                            <button
                              button="button"
                              className="btn btn-default action-icon"
                              onClick={() => handleShow(message)}
                            >
                              <i className="mdi mdi-eye" />
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
              {/* end card-body*/}
            </div>
            {/* end card*/}
          </div>
          {/* end col */}
        </div>
        {/* end row */}
      </div>

      {selectedMessage && (
        <Modal show={show} onHide={handleClose} centered>
          <Modal.Header closeButton>
            <Modal.Title>SMS Details</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="col-md-4">
                <div className="mb-3">
                  <p className="mb-0">
                    <b>Sender</b>
                  </p>
                  <p className="text-muted">{selectedMessage.from_name}</p>
                </div>
              </div>
              <div className="col-md-4">
                <div className="mb-3">
                  <p className="mb-0">
                    <b>Receiver</b>
                  </p>
                  <p className="text-muted">{selectedMessage.to_name}</p>
                </div>
              </div>
              <div className="col-md-4">
                <div className="mb-3">
                  <p className="mb-0">
                    <b>Date</b>
                  </p>
                  <p className="text-muted">
                    {formatDate(selectedMessage.created_at, "DD MMM YYYY")}{" "}
                    <small className="text-muted">
                      {formatDate(selectedMessage.created_at, "hh:mm A")}
                    </small>
                  </p>
                </div>
              </div>
              <div className="col-md-12">
                <div className="mb-0">
                  <p className="mb-0">
                    <b>SMS Text</b>
                  </p>
                  <div
                    className="text-muted"
                    dangerouslySetInnerHTML={{ __html: selectedMessage.message }}
                  />
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button
              type="button"
              className="btn btn-secondary waves-effect"
              onClick={() => setShow(false)}
            >
              Close
            </button>
          </Modal.Footer>
        </Modal>
      )}

      <EmailAndSmsModal showModal={showModal} setShowModal={setShowModal} type="sms" />

      {isLoading === true && <Spinner />}
    </Layout>
  );
};

export default AdminSMS;
