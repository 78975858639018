import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import * as profile from "../../../../services/ProfileService";
import * as Yup from "yup";
import * as api from "../../../../services/ApiService";
import * as tokenUtils from "../../../../utils/tokenUtils";
import { phoneRegExp } from "../../../../utils/dateFormat";

function PersonalInfo({ states, fetchProfileData, refreshPage }) {
  // Get data from token util
  let user = tokenUtils.getTokenData();
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const isSubmissionFileRef = queryParams.get("ref") === "submission-file";

  // Use useState hook to initialize FormData variables with empty values
  const [formData, setformData] = useState({
    user_id: user.id,
    role_id: user.role_id,
    token: user.token,
    profile_pic: "",
    name: "",
    bio: "",
    email: "",
    phone: "",
    address_line1: "",
    address_line2: "",
    country_code: 1,
    city_id: "",
    state_id: 0,
    // dob: +"00-00-0000",
    // total_experience: 3,
  });

  // Function to fetch data from the API
  const fetchData = async () => {
    try {
      const param = {
        user_id: user.id,
        token: user.token,
      };
      const apiData = await profile.getPersonalInfo(param, "user/get-profile");

      setformData({
        user_id: user.id,
        role_id: user.role_id,
        token: user.token,
        profile_pic: apiData[0].profile_pic,
        name: apiData[0].name,
        email: apiData[0].email,
        phone: apiData[0].phone,
        bio: apiData[0].bio,
        address_line1: apiData[0].address_line1,
        address_line2: apiData[0].address_line2,
        state_id: apiData[0].state_id || 0,
        city_id: apiData[0].city_id,
        /*dob: apiData[0].dob,
        /*total_experience: apiData[0].total_experience,*/
      });
      localStorage.setItem("profile_Image",apiData[0].profile_pic)
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    // Call the fetchData function when the component mounts
    fetchData();
  }, []); // Empty dependency array ensures that the effect runs only once, when the component mounts

  const [cities, setCities] = useState([]);

  useEffect(() => {
    // Function to fetch data from the API
    const fetchData = async () => {
      try {
        const apiData = await api.getMethod("get-cities/" + formData.state_id);

        setCities(apiData.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    // Call the fetchData function when the component mounts
    fetchData();
  }, [formData.state_id]); // Empty dependency array ensures that the effect runs only once, when the component mounts

  // Define the validation schema
  let validationSchema = Yup.object({
    name: Yup.string().required("Full Name is Required"),
    email: Yup.string().email("Invalid Email").required("Email is Required"),
    phone: Yup.string()
      .matches(phoneRegExp, "Phone number is not valid")
      .required("Phone Number is Required"),
    city_id: Yup.string().required("City is Required"),
    state_id: Yup.string().required("State is Required"),
    /*total_experience: Yup.string().required("Please Select Experience"),*/
  });

  const [errors, setErrors] = useState({});

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      // without abortEarly, it will return back on 1st field's validation occured, won't check other fields
      await validationSchema.validate(formData, { abortEarly: false });

      // Create form data object
      const formDataToSend = new FormData();
      for (const key in formData) {
        formDataToSend.append(key, formData[key]);
      }

      let res = await profile.updatePersonalInfo(formDataToSend, "user/update-profile");
      if (res) {
        // Trigger a full re-render of the entire page layout
        // refreshPage();

        fetchData();
        fetchProfileData();
        // Reset the profile_pic field to null
        setformData({
          ...formData,
          profile_pic: null,
        });
        /*
        document.getElementById("profile_pic").value = "";
        */
      }
    } catch (error) {
      const newErrors = {};
      if (error.inner && Array.isArray(error.inner)) {
        error.inner.forEach((element) => {
          newErrors[element.path] = element.message;
        });
      } else {
        console.error("Unexpected error structure:", error);
      }
      setErrors(newErrors);
    }
  };

  // Handle change event
  const handleChange = (e) => {
    const { name, value } = e.target;

    setformData({
      ...formData,
      [name]: value,
    });

    validateFields(name, value);
  };

  const handleImageChange = (e) => {
    setformData({
      ...formData,
      profile_pic: e.target.files[0], // Update image field with the selected file
    });
  };

  const validateFields = (name, value) => {
    try {
      validationSchema.validateSyncAt(name, { [name]: value });

      // Clear the error for the current field if validation passes
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: "",
      }));
    } catch (error) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: error.message,
      }));
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit}>
        <div className="row">
          <div className="col-md-3">
            <div
              className={`mb-3 ${
                isSubmissionFileRef && !formData.profile_pic ? "highlight-empty" : ""
              }`}
            >
              <label htmlFor="profile_pic" className="form-label">
                Profile Image
              </label>

              <div className="input-group">
                <input
                  type="file"
                  className="form-control"
                  id="profile_pic"
                  name="profile_pic"
                  onChange={handleImageChange}
                  accept="image/*"
                />
                {formData.profile_pic !== "" && (
                  <div className="input-group-prepend">
                    <span className="input-group-text p-1">
                      <img src={formData.profile_pic} alt="" className="float-end " height={32} />
                    </span>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="col-md-3">
            <div
              className={`mb-3 ${isSubmissionFileRef && !formData.name ? "highlight-empty" : ""}`}
            >
              <label htmlFor="name" className="form-label">
                Full Name
              </label>
              <input
                type="text"
                className="form-control"
                id="name"
                name="name"
                onChange={handleChange}
                defaultValue={formData.name}
              />
              {errors.name && <span className="error">{errors.name}</span>}
            </div>
          </div>
          <div className="col-md-3">
            <div
              className={`mb-3 ${isSubmissionFileRef && !formData.email ? "highlight-empty" : ""}`}
            >
              <label htmlFor="email" className="form-label">
                Email Address
              </label>
              <input
                type="email"
                className="form-control"
                id="email"
                name="email"
                onChange={handleChange}
                defaultValue={formData.email}
              />
              {errors.email && <span className="error">{errors.email}</span>}
            </div>
          </div>
          <div className="col-md-3">
            <div
              className={`mb-3 ${isSubmissionFileRef && !formData.phone ? "highlight-empty" : ""}`}
            >
              <label htmlFor="phone" className="form-label">
                Phone Number
              </label>
              <div className="input-group">
                <div className="input-group-prepend">
                  <span className="input-group-text">+1</span>
                </div>
                <input
                  type="text"
                  className="form-control"
                  id="phone"
                  name="phone"
                  onChange={handleChange}
                  defaultValue={formData.phone}
                />
              </div>
              {errors.phone && <span className="error">{errors.phone}</span>}
            </div>
          </div>
          {/* end col */}
        </div>
        {/* end row */}
        <div className="row">
          <div className="col-12">
            <div
              className={`mb-3 ${isSubmissionFileRef && !formData.bio ? "highlight-empty" : ""}`}
            >
              <label htmlFor="bio" className="form-label">
                About Me
              </label>
              <textarea
                className="form-control"
                id="bio"
                rows={4}
                name="bio"
                onChange={handleChange}
                defaultValue={formData.bio}
              />
            </div>
          </div>
          {/* end col */}
        </div>
        {/* end row */}

        {/* end row */}
        <div className="row">
          <div className="col-md-6">
            <div
              className={`mb-3 ${
                isSubmissionFileRef && !formData.address_line1 ? "highlight-empty" : ""
              }`}
            >
              <label htmlFor="address_line1" className="form-label">
                Address Line 1
              </label>
              <input
                type="text"
                className="form-control"
                id="address_line1"
                name="address_line1"
                onChange={handleChange}
                defaultValue={formData.address_line1}
              />
            </div>
          </div>
          <div className="col-md-6">
            <div className="mb-3">
              <label htmlFor="address_line2" className="form-label">
                Address Line 2
              </label>
              <input
                type="text"
                className="form-control"
                id="address_line2"
                name="address_line2"
                onChange={handleChange}
                defaultValue={formData.address_line2}
              />
            </div>
          </div>
          {/* end col */}
        </div>
        <div className="row">
          <div className="col-md-3">
            <div
              className={`mb-3 ${
                isSubmissionFileRef && !formData.state_id ? "highlight-empty" : ""
              }`}
            >
              <label htmlFor="state_id" className="form-label">
                State
              </label>
              <select
                className="form-control"
                name="state_id"
                onChange={handleChange}
                value={formData.state_id !== null ? formData.state_id : ""}
              >
                <option value="" key="">
                  Please Select
                </option>
                {/* Render the states */}
                {states.map((state) => (
                  <option key={state.id} value={state.id}>
                    {state.name}
                  </option>
                ))}
              </select>

              {errors.state_id && <span className="error">{errors.state_id}</span>}
            </div>
          </div>

          <div className="col-md-3">
            <div
              className={`mb-3 ${
                isSubmissionFileRef && !formData.city_id ? "highlight-empty" : ""
              }`}
            >
              <label htmlFor="city_id" className="form-label">
                City
              </label>
              <select
                className="form-control"
                name="city_id"
                onChange={handleChange}
                value={formData.city_id !== null ? formData.city_id : ""}
              >
                <option value="" key="">
                  Please Select
                </option>
                {/* Render the states */}
                {cities.map((city) => (
                  <option key={city.id} value={city.id}>
                    {city.city_name}
                  </option>
                ))}
              </select>
              {errors.city_id && <span className="error">{errors.city_id}</span>}
            </div>
          </div>

          {/*
        <div className="col-md-3">
          <div className="mb-3">
            <label htmlFor="dob" className="form-label">
              Date of Birth
            </label>
            <input
              type="date"
              className="form-control"
              id="dob"
              name="dob"
              onChange={handleChange}
              defaultValue={formData.dob}
            />
          </div>
        </div>
        */}

          {/*
        <div className="col-md-3">
          <div className="mb-3">
            <label htmlFor="total_experience" className="form-label">
              Total Years of Experience
            </label>
            <select
              className="form-control"
              id="total_experience"
              name="total_experience"
              onChange={handleChange}
              value={formData.total_experience !== null ? formData.total_experience : ""}
            >
              <option value="" key="">
                0 Year(s)
              </option>
              {Array.from(Array(15), (e, i) => {
                return (
                  <option value={i + 1} key={i + 1}>
                    {i + 1} Year(s)
                  </option>
                );
              })}
              <option value="15+">15+ Year(s)</option>
            </select>
            {errors.total_experience && <span className="error">{errors.total_experience}</span>}
          </div>
        </div>
         */}
        </div>
        <div className="text-end">
          <button type="submit" className="btn btn-primary waves-effect waves-light mt-2">
            Save
          </button>
        </div>
      </form>
    </>
  );
}

export default PersonalInfo;
