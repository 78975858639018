import React, { useEffect, useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import Header from "../../Layout/Header";
import Footer from "../../Layout/Footer";
import Sidebar from "../../Layout/Sidebar";
import ThemeSettings from "../../Layout/ThemeSettings";
import * as api from "../../../services/ApiService";
import * as Yup from "yup";
import * as tokenUtils from "../../../utils/tokenUtils";
import * as toast from "../../../utils/toastUtils";
import Modal from "react-bootstrap/Modal";
import { Tooltip } from "react-tooltip";
import useConfirm from "../../../hooks/useConfirm";
import PageTitle from "../../Layout/PageTitle";
import { currentRole } from "../../Layout/HelmetComponent";

function AddCandidateStep4() {
  let navigate = useNavigate();
  const location = useLocation();
  const isStaff = location.pathname.includes("/staff");
  const urlSlug = isStaff ? "staff" : "client";
  // Get data from token util
  let user = tokenUtils.getTokenData();
  let userID = localStorage.getItem("candidate_id");

  const [modalOpen, setModalOpen] = useState(false);

  const toggleModal = () => {
    setModalOpen(!modalOpen);

    if (!modalOpen) {
      setformData({
        id: null,
        user_id: userID,
        token: user.token,
        degree: null,
        school_college: null,
        end_month: null,
        end_year: null,
        currently_attending: null,
        location: null,
      });

      // Reset validation errors
      setErrors({});
    }
  };

  const [formData, setformData] = useState({
    user_id: userID,
    token: user.token,
    degree: null,
    school_college: null,
    end_month: null,
    end_year: null,
    currently_attending: null,
    location: null,
  });

  const [errors, setErrors] = useState({});

  const handleEdit = (record) => {
    // Populate formData with the values of the selected record
    setformData({
      user_id: userID,
      token: user.token,
      id: record.id,
      degree: record.degree,
      school_college: record.school_college,
      end_month: record.end_month,
      end_year: record.end_year,
      currently_attending: record.currently_attending,
      location: record.location,
    });

    setModalOpen(true);
  };

  const { confirm } = useConfirm();
  const handleDelete = async (record) => {
    const confirmed = await confirm();
    if (confirmed.isConfirmed) {
      record.user_id = userID;
      record.token = user.token;

      var result = await api.postMethod(record, "user/delete-educational-info");
      if (result) {
        fetchEducationalInfoData();
      }
    }
  };

  const validationSchema = Yup.object({
    degree: Yup.string().required("Degree is Required"),
    school_college: Yup.string().required("School/Collage is Required"),
    /*
    currently_attending: Yup.boolean(),
    end_month: Yup.string().when("currently_attending", {
      is: "0",
      then: Yup.string().required("End Month is Required"),
    }),
    end_year: Yup.string().when("currently_attending", {
      is: "0",
      then: Yup.string().required("End Year is Required"),
    }),
    location: Yup.string().required("Location is Required"),
    */
  });

  const [educationalInfo, setEducationalInfo] = useState({});
  const [loading, setLoading] = useState(true);
  const [noRecordsFound, setNoRecordsFound] = useState(false);

  const fetchEducationalInfoData = async () => {
    try {
      const param = {
        user_id: userID,
        token: user.token,
      };
      const apiData = await api.postMethod(param, "user/get-educational-info");
      //const apiDataObj = Object.setPrototypeOf(apiData, Object.prototype);

      /*
      const apiDataObj = apiData.reduce((acc, currentValue, index) => {
        acc[index] = currentValue;
        return acc;
      }, {});
      */

      setEducationalInfo(apiData.data);
      setLoading(false);
      // Check if data array is empty
      if (apiData.data.length === 0) {
        setNoRecordsFound(true);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchEducationalInfoData();
  }, []); // Empty dependency array ensures that the effect runs only once, when the component mounts

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      await validationSchema.validate(formData, { abortEarly: false });
      const result = await api.postMethod(formData, "user/update-educational-info");
      if (result) {
        setModalOpen(false);
        fetchEducationalInfoData();
      }
    } catch (error) {
      const newErrors = {};
      if (error.inner && Array.isArray(error.inner)) {
        error.inner.forEach((element) => {
          newErrors[element.path] = element.message;
        });
      } else {
        console.error("Unexpected error structure:", error);
      }
      setErrors(newErrors);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    setformData({ ...formData, [name]: value });

    validateFields(name, value);
  };

  const validateFields = (name, value) => {
    try {
      validationSchema.validateSyncAt(name, { [name]: value });

      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: "",
      }));
    } catch (error) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: error.message,
      }));
    }
  };

  // Generate an array of years
  const years = [];
  const currentYear = new Date().getFullYear();
  for (let year = currentYear; year >= currentYear - 15; year--) {
    years.push(year);
  }

  const handleRedirectSubmit = async (e, navigateTo) => {
    e.preventDefault();
    navigate(navigateTo);
  };

  const breadcrumbItems = [
    { text: currentRole + " Dashboard", link: "/" + urlSlug + "/dashboard" },
    { text: "Candidates", link: "/" + urlSlug + "/candidates" },
    { text: "Add Candidate", link: null },
  ];

  return (
    <>
      <div id="wrapper">
        <Sidebar />

        <div className="content-page">
          <Header />

          <div className="content">
            {/* Start Content*/}
            <div className="container-fluid">
              {/* start page title */}
              <PageTitle title="Add Candidate" breadcrumbItems={breadcrumbItems} />
              {/*Job progress bar start*/}
              <div className="row">
                <div className="col-12">
                  <div className="stepper-wrapper">
                    <div className="stepper-item completed">
                      <div className="step-counter">1</div>
                      <div className="step-name">Personal Information</div>
                    </div>
                    <div className="stepper-item completed">
                      <div className="step-counter">2</div>
                      <div className="step-name">Job Preferences</div>
                    </div>
                    {/* 
                    <div className="stepper-item">
                      <div className="step-counter">3</div>
                      <div className="step-name">Professional Details</div>
                    </div>
                    */}
                    <div className="stepper-item completed">
                      <div className="step-counter">3</div>
                      <div className="step-name">Work History</div>
                    </div>
                    <div className="stepper-item active">
                      <div className="step-counter">4</div>
                      <div className="step-name">Educational Information</div>
                    </div>
                    {/*
                    <div className="stepper-item">
                      <div className="step-counter">5</div>
                      <div className="step-name">Skills</div>
                    </div>
                    */}
                  </div>
                </div>
              </div>
              {/*Job progress bar end*/}
              <div className="row">
                <div className="col-12">
                  <div className="card custom-box-shadow">
                    <div className="card-body">
                      <div className="table-responsive">
                        <table className="table table-centered table-nowrap mb-0">
                          <thead className="table-light">
                            <tr>
                              <th>Degree</th>
                              <th>School/Collage</th>
                              <th>Graduated</th>
                              <th>Location</th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {loading ? (
                              <tr>
                                <td colSpan="5">Loading...</td>
                              </tr>
                            ) : (
                              <>
                                {noRecordsFound ? (
                                  <tr>
                                    <td colSpan="5">No records found</td>
                                  </tr>
                                ) : (
                                  <>
                                    {Object.entries(educationalInfo).map(([key, value]) => (
                                      <tr key={value.id}>
                                        <td>{value.degree}</td>
                                        <td>{value.school_college}</td>
                                        <td>
                                          {value.currently_attending === "1"
                                            ? "Present"
                                            : value.end_month + "/" + value.end_year}
                                        </td>
                                        <td>{value.location}</td>
                                        <td>
                                          <span
                                            className="action-icon"
                                            onClick={() => handleEdit(value)}
                                          >
                                            <i className="mdi mdi-pencil" />
                                          </span>
                                          <span
                                            className="action-icon"
                                            onClick={() => handleDelete(value)}
                                          >
                                            <i className="mdi mdi-delete" />
                                          </span>
                                        </td>
                                      </tr>
                                    ))}
                                  </>
                                )}
                              </>
                            )}
                          </tbody>
                        </table>
                      </div>

                      <Link
                        to={`/` + urlSlug + `/add-candidate-step3`}
                        className="btn btn-secondary mt-2 me-1 float-start"
                        type="submit"
                      >
                        <i className="fas fa-angle-left" /> Go Back
                      </Link>
                      <button
                        type="submit"
                        className="btn btn-info float-start mt-2"
                        onClick={toggleModal}
                      >
                        <i className="fa fa-plus"></i> Add Education
                      </button>

                      {/*
                      <button
                        className="btn btn-info ms-1 float-end mt-2 ms-1"
                        type="button"
                        onClick={(e) => handleRedirectSubmit(e, "/" + urlSlug + "/candidates")}
                      >
                        Save & Exit <i className="fas fa-check" />
                      </button>

                      <Link
                        to={`/` + urlSlug + `/add-candidate-step5`}
                        className="btn btn-primary mt-2 float-end"
                      >
                        Save and Continue <i className="fas fa-angle-right" />
                      </Link>
                       */}

                      <Link
                        to={`/` + urlSlug + `/candidates`}
                        className="btn btn-primary mt-2 float-end"
                      >
                        Submit <i className="fas fa-angle-right" />
                      </Link>

                      <Modal show={modalOpen} onHide={toggleModal} centered backdrop="static">
                        <form onSubmit={handleSubmit}>
                          <Modal.Header closeButton>
                            <Modal.Title className="my-0">Add Educational Info</Modal.Title>
                          </Modal.Header>
                          <Modal.Body>
                            <div className="row">
                              <div className="col-md-12">
                                <div className="mb-2">
                                  <label htmlFor="degree" className="form-label">
                                    Degree
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="degree"
                                    name="degree"
                                    placeholder=""
                                    onChange={handleChange}
                                    defaultValue={formData.degree}
                                  />
                                  {errors.degree && <span className="error">{errors.degree}</span>}
                                </div>
                              </div>
                              <div className="col-md-12">
                                <div className="mb-2">
                                  <label htmlFor="school_college" className="form-label">
                                    School/Collage
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="school_college"
                                    name="school_college"
                                    onChange={handleChange}
                                    defaultValue={formData.school_college}
                                  />
                                  {errors.school_college && (
                                    <span className="error">{errors.school_college}</span>
                                  )}
                                </div>
                              </div>

                              <div className="col-md-6">
                                <div className="mb-2">
                                  <label htmlFor="end_month" className="form-label">
                                    End Month
                                  </label>
                                  <select
                                    className="form-control"
                                    id="end_month"
                                    name="end_month"
                                    onChange={handleChange}
                                    value={formData.end_month !== null ? formData.end_month : ""}
                                  >
                                    <option value="" key="">
                                      -
                                    </option>
                                    {Array.from(Array(12), (e, i) => {
                                      return (
                                        <option value={i + 1} key={i + 1}>
                                          {i + 1}
                                        </option>
                                      );
                                    })}
                                  </select>
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="mb-2">
                                  <label htmlFor="end_year" className="form-label">
                                    End Year
                                  </label>
                                  <label className="float-end">
                                    <input
                                      type="checkbox"
                                      name="currently_attending"
                                      defaultValue="1"
                                      data-tooltip-id="tt-1"
                                      data-tooltip-content="Currently Attending"
                                      defaultChecked={formData.currently_attending}
                                    />
                                    <Tooltip id="tt-1"></Tooltip>
                                  </label>
                                  <select
                                    className="form-control"
                                    id="end_year"
                                    name="end_year"
                                    onChange={handleChange}
                                    value={formData.end_year !== null ? formData.end_year : ""}
                                  >
                                    <option value="" key="">
                                      -
                                    </option>
                                    {years.map((year) => (
                                      <option key={year} value={year}>
                                        {year}
                                      </option>
                                    ))}
                                  </select>
                                </div>
                              </div>

                              <div className="col-md-12">
                                <div className="mb-2">
                                  <label htmlFor="location" className="form-label">
                                    Location <small>(City, State, Country)</small>
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="location"
                                    name="location"
                                    onChange={handleChange}
                                    defaultValue={formData.location}
                                  />
                                </div>
                              </div>
                            </div>
                          </Modal.Body>
                          <Modal.Footer>
                            <button
                              type="button"
                              className="btn btn-secondary"
                              onClick={toggleModal}
                            >
                              Close
                            </button>
                            <button type="submit" className="btn btn-primary">
                              Submit
                            </button>
                          </Modal.Footer>
                        </form>
                      </Modal>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </div>
      <ThemeSettings />
    </>
  );
}

export default AddCandidateStep4;
