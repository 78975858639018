import React, { useState, useEffect } from "react";
import Header from "../../Layout/Header";
import Footer from "../../Layout/Footer";
import Sidebar from "../../Layout/Sidebar";
import ThemeSettings from "../../Layout/ThemeSettings";

import { Link, useLocation } from "react-router-dom";
import * as api from "../../../services/ApiService";
import * as tokenUtils from "../../../utils/tokenUtils";
import AllSubscription from "./Tabs/AllSubscription";
import ActiveUser from "./Tabs/ActiveUser";
import MoniterSubscription from "./Tabs/MoniterSubscription";
function Subscription({ refreshPage }) {
  // Get data from token util
  let user = tokenUtils.getTokenData();
  const location = useLocation();
  const [selectedTab, setSelectedTab] = useState("all_subscription");

  useEffect(() => {
    if (location.state?.tab) {
      setSelectedTab(location.state.tab);
    }
  }, [location.state]);

  const [skills, setSkills] = useState([]);
  const [states, setStates] = useState([]);
  const [professions, setProfessions] = useState([]);
  const [employmentTypes, setEmploymentTypes] = useState([]);
  const [shifts, setShifts] = useState([]);

  //console.log("skills", skills);

  // Tab select event
  const handleTabSelect = (tab) => {
    setSelectedTab(tab);
  };

  return (
    <>
      <div id="wrapper">
        <Sidebar />

        <div className="content-page">
          <Header />

          <div className="content">
            {/* Start Content*/}
            <div className="container-fluid">
              {/* start page title */}
              <div className="row">
                <div className="col-12">
                  <div className="page-title-box">
                    <div className="page-title-right">
                      <ol className="breadcrumb m-0">
                        <li className="breadcrumb-item">
                          <Link to="/dashboard">Home</Link>
                        </li>
                        <li className="breadcrumb-item active">All Subscription</li>
                      </ol>
                    </div>
                    <h4 className="page-title">All Subscription</h4>
                  </div>
                </div>
              </div>
              {/* end page title */}
              <div className="row">
                <div className="col-lg-12 col-xl-12">
                  <div className="card">
                    <div className="card-body"></div>
                  </div>
                  <div className="card">
                    <div className="card-body">
                      <div className="row mb=3">
                        <div className="col">
                          <button
                            type="button"
                            className={
                              "btn  w-100 " +
                              (selectedTab === "all_subscription"
                                ? "btn-primary"
                                : "btn-light")
                            }
                            onClick={() => handleTabSelect("all_subscription")}
                          >
                            All Subscription
                          </button>
                        </div>
                        <div className="col">
                          <button
                            type="button"
                            className={
                              "btn  w-100 " +
                              (selectedTab === "active_user"
                                ? "btn-primary"
                                : "btn-light")
                            }
                            onClick={() => handleTabSelect("active_user")}
                          >
                           Active Users
                          </button>
                        </div>
                        <div className="col">
                          <button
                            type="button"
                            className={
                              "btn  w-100 " +
                              (selectedTab === "moniter_subscription"
                                ? "btn-primary"
                                : "btn-light")
                            }
                            onClick={() => handleTabSelect("moniter_subscription")}
                          >
                           Active Users
                          </button>
                        </div>
                        {/* 
                        <div className="col">
                          <button
                            type="button"
                            className={
                              "btn  w-100 " +
                              (selectedTab === "skills" ? "btn-primary" : "btn-light")
                            }
                            onClick={() => handleTabSelect("skills")}
                          >
                            Skills
                          </button>
                        </div>
                        */}
                      </div>
                    </div>
                  </div>

                  <div className="card">
                    <div className="card-body">
                      {selectedTab === "all_subscription" &&
                        
                           <AllSubscription /> 
                        }
                      {selectedTab === "active_user" &&
                        <ActiveUser/>
                        }
                      {selectedTab === "moniter_subscription" &&
                        <MoniterSubscription/>
                        }
                      {selectedTab === "educational_info" &&
                        {
                          /* <EducationalInfo
                          fetchProfileData={fetchProfileData}
                          refreshPage={refreshPage}
                        /> */
                        }}
                      {selectedTab === "skills" &&
                        {
                          /* <Skills skills={skills} fetchProfileData={fetchProfileData} /> */
                        }}
                      {selectedTab === "references" &&
                        {
                          /* <Reference fetchProfileData={fetchProfileData} refreshPage={refreshPage} /> */
                        }}
                    </div>
                  </div>

                  {/* end card*/}
                </div>
                {/* end col */}
              </div>
              {/* end row */}
            </div>
            {/* container */}
          </div>

          <Footer />
        </div>
      </div>

      <ThemeSettings />
    </>
  );
}

export default Subscription;
