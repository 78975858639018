import React, { useEffect, useState } from "react";
import Header from "../Layout/Header";
import Footer from "../Layout/Footer";
import Sidebar from "../Layout/Sidebar";
import ThemeSettings from "../Layout/ThemeSettings";
import { Link } from "react-router-dom";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
import * as api from "../../services/ApiService";
import * as tokenUtils from "../../utils/tokenUtils";
import Spinner from "../Loader/Spinner";
import { Modal } from "react-bootstrap";

function EventCalendar() {
  // Get data from token util
  let user = tokenUtils.getTokenData();
  const [isLoading, setIsLoading] = useState(false);
  const localizer = momentLocalizer(moment);

  const [myEventsList, setMyEventsList] = useState([]);

  // Function to convert date and time strings to JavaScript Date objects
  function parseEvent(response) {
    const [startYear, startMonth, startDay] = response.start_date.split("-").map(Number);
    const [startHour, startMinute, startSecond] = response.start_time.split(":").map(Number);
    const [endYear, endMonth, endDay] = response.end_date.split("-").map(Number);
    const [endHour, endMinute, endSecond] = response.end_time.split(":").map(Number);

    return {
      title: response.title,
      start: new Date(startYear, startMonth - 1, startDay, startHour, startMinute, startSecond),
      end: new Date(endYear, endMonth - 1, endDay, endHour, endMinute, endSecond),
    };
  }

  const fetchSchedules = async () => {
    setIsLoading(true);
    try {
      const param = {
        user_id: user.id,
      };
      const apiData = await api.postMethod(param, "user/get-calendar-data");
      let newData = [];
      let i = 0;
      while (i < apiData.data.length) {
        let ele = apiData.data[i];
        ele = {
          ...ele,
          start: new Date(`${ele.start_date}T${ele.start_time}`),
          end: new Date(`${ele.end_date}T${ele.end_time}`),
        };
        newData.push(ele);
        i++;
      }

      setMyEventsList(newData);
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchSchedules();
  }, []);

  const eventColors = {
    call: "#00B8FF",
    meeting: "#1ABC9C",
    task: "#ff5712",
    email: "#F7B94B",
    interview: "#8E44AD",
  };

  const eventPropGetter = (event) => {
    const backgroundColor = eventColors[event.type] || "#FFFFFF";
    return { style: { backgroundColor } };
  };

  const [selectedEvent, setSelectedEvent] = useState(null);
  const [detailModal, setDetailsModal] = useState(false);
  const toggleDetailsModal = () => setDetailsModal(!detailModal);

  return (
    <>
      <div id="wrapper">
        <Sidebar />

        <div className="content-page">
          <Header />

          <div className="content">
            {/* Start Content*/}
            <div className="container-fluid">
              {/* start page title */}
              <div className="row">
                <div className="col-12">
                  <div className="page-title-box">
                    <div className="page-title-right">
                      <ol className="breadcrumb m-0">
                        <li className="breadcrumb-item">
                          <Link to="/dashboard">Home</Link>
                        </li>
                        <li className="breadcrumb-item active">Event Calendar</li>
                      </ol>
                    </div>
                    <h4 className="page-title">Event Calendar</h4>
                  </div>
                </div>
              </div>
              {/* end page title */}
              <div className="row">
                <div className="col-12">
                  <div className="card custom-box-shadow">
                    <div className="card-header border-bottom">
                      <div className="row">
                        <div className="col-12">
                          <p className="mb-0 fw-bold">
                            Welcome to your event calendar. Here, you can view all your scheduled
                            activities including calls, meetings, emails, tasks, and interviews etc.
                            Use the calendar below to keep track of your important dates and events.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="card-body">
                      <div className="row">
                        <div className="col-12 text-end mb-2">
                          <span
                            className="btn btn-defalt btn-sm text-white me-1"
                            style={{ backgroundColor: "#00B8FF", pointerEvents: "none" }}
                          ></span>
                          : Call
                          <span
                            className="btn btn-defalt btn-sm text-white me-1 ms-2"
                            style={{ backgroundColor: "#1ABC9C", pointerEvents: "none" }}
                          ></span>
                          : Meeting
                          <span
                            className="btn btn-defalt btn-sm text-white me-1 ms-2"
                            style={{ backgroundColor: "#ff5712", pointerEvents: "none" }}
                          ></span>
                          : Task
                          <span
                            className="btn btn-defalt btn-sm text-white me-1 ms-2"
                            style={{ backgroundColor: "#F7B94B", pointerEvents: "none" }}
                          ></span>
                          : Email
                          <span
                            className="btn btn-defalt btn-sm text-white me-1 ms-2"
                            style={{ backgroundColor: "#8E44AD", pointerEvents: "none" }}
                          ></span>
                          : Follow Up
                        </div>
                        <div className="col-lg-12">
                          <Calendar
                            localizer={localizer}
                            events={myEventsList}
                            startAccessor="start"
                            endAccessor="end"
                            style={{ height: 500 }}
                            defaultView="month"
                            eventPropGetter={eventPropGetter}
                            selectable={true}
                            onSelectEvent={(e) => {
                              setSelectedEvent(e);
                              toggleDetailsModal();
                            }}
                          />
                        </div>
                        {/* end col */}
                      </div>
                      {/* end row */}
                    </div>
                    {/* end card body*/}
                  </div>
                  {/* end card */}
                </div>
                {/* end col */}
              </div>
              {/* end row */}
            </div>
            {/* container */}
          </div>

          <Footer />
        </div>
      </div>

      <Modal
        show={detailModal}
        size="md"
        onHide={() => toggleDetailsModal()}
        centered
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title className="my-0">View Event Details</Modal.Title>
        </Modal.Header>
        <div className="modal-body">
          <div className="row">
            <div className="col-md-6 mb-2">
              <strong>Event Type</strong>
            </div>
            <div className="col-md-6 mb-2">
              {selectedEvent?.type == "interview"
                ? "Follow Up"
                : selectedEvent?.type.charAt(0).toUpperCase() + selectedEvent?.type.slice(1)}
            </div>
          </div>

          <div className="row">
            <div className="col-md-6 mb-2">
              <strong>Title</strong>
            </div>
            <div className="col-md-6 mb-2">{selectedEvent?.title}</div>
          </div>

          <div className="row">
            <div className="col-md-6 mb-2">
              <strong>Start Date</strong>
            </div>
            <div className="col-md-6 mb-2">{selectedEvent?.start_date}</div>
          </div>

          <div className="row">
            <div className="col-md-6 mb-2">
              <strong>End Date</strong>
            </div>
            <div className="col-md-6 mb-2">{selectedEvent?.end_date}</div>
          </div>

          <div className="row">
            <div className="col-md-6 mb-2">
              <strong>Start Time</strong>
            </div>
            <div className="col-md-6 mb-2">{selectedEvent?.start_time}</div>
          </div>

          <div className="row">
            <div className="col-md-6 mb-2">
              <strong>End Time</strong>
            </div>
            <div className="col-md-6 mb-2">{selectedEvent?.end_time}</div>
          </div>
        </div>
      </Modal>

      {isLoading === true && <Spinner />}
      <ThemeSettings />
    </>
  );
}

export default EventCalendar;
