import { React, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import * as auth from "../../../services/AuthService";
import PasswordToggle from "../../../hooks/PasswordToggle";
import Logo from "./Logo";

function Register() {
  let navigate = useNavigate();

  // Form button state
  const [formBtn, setformBtn] = useState({
    label: "Register",
    disabled: false,
  });

  // Use useState hook to initialize FormData variables with empty values
  const [formData, setformData] = useState({
    name: "",
    email: "",
    password: "",
    acceptedTerms: false,
  });

  // Password toggle state
  const [showPassword, togglePasswordVisibility] = PasswordToggle(formData, setformData);

  // Define Error state
  const [errors, setErrors] = useState({});

  // Define the validation schema
  let validationSchema = Yup.object({
    name: Yup.string()
      .matches(/^[A-Za-z\s]+$/, "Invalid name")
      .min(2)
      .max(50)
      .required("Name is required"),
    email: Yup.string().email("Invalid email").required("Email is required"),
    password: Yup.string()
      .min(8, "Password must be at least 8 characters")
      .required("Password is required"),
    acceptedTerms: Yup.boolean().oneOf([true], "You must accept privacy policy and terms"),
  });

  // Form submit function
  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      // without abortEarly, it will return back on 1st field's validation occured, won't check other fields
      await validationSchema.validate(formData, { abortEarly: false });

      // Disable the btn
      setformBtn({
        label: "Please wait...",
        disabled: true,
      });

      // Call function with formData and API endpoint
      let res = await auth.register(formData, "user/register");

      // Reset the btn
      setformBtn({
        label: "Register",
        disabled: false,
      });

      if (res) {
        setTimeout(() => {
          navigate("/email-verification", { replace: true });
        }, 500);
      }
    } catch (error) {
      const newErrors = {};

      if (error.inner && Array.isArray(error.inner)) {
        error.inner.forEach((err) => {
          newErrors[err.path] = err.message;
        });
      } else {
        console.error("Unexpected error structure:", error);
      }

      setErrors(newErrors);
    }
  };

  // OnChange event on each form field, because form fields are disabled
  const handleChange = (e) => {
    //name attritube & value from the field
    const { name, value } = e.target;

    // Update the formData state
    setformData({
      ...formData,
      [name]: value,
    });

    // Validate the changed field and update errors state
    validateField(name, value);
  };

  // OnCheck event on checkbox
  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;

    // Update the formData state
    setformData({
      ...formData,
      [name]: checked,
    });
  };

  // Function to validate form validate
  const validateField = (name, value) => {
    try {
      validationSchema.validateSyncAt(name, { [name]: value });
      // Clear the error for the current field if validation passes
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: "",
      }));
    } catch (error) {
      // Set the error message for the current field if validation fails
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: error.message,
      }));
    }
  };
  document.body.classList.add("auth-fluid-pages");
  return (
    <div className="auth-fluid">
      <div className="container">
        <div className="row justify-content-center w-100 align-items-center h-100">
          <div className="bg-white col-md-8 col-lg-6 col-xl-4 py-3 rounded shadow-lg">
            {/*Auth fluid left content */}
            <div className="auth-fluid-form-box position-relative h-100 p-0">
              <div className="align-items-center d-flex h-100">
                <div className="p-3">
                  {/* Logo */}
                  <Logo />

                  <h4 className="mt-0">Job Seekers Sign Up to Start Your Journey</h4>
                  <p className="mb-4">Fill the required fields</p>

                  <form onSubmit={handleSubmit}>
                    <div className="mb-2">
                      <label htmlFor="name" className="form-label">
                        Full Name <span className="required">*</span>
                      </label>
                      <input
                        className="form-control"
                        type="text"
                        id="name"
                        name="name"
                        placeholder="Enter your full name"
                        value={formData.name}
                        onChange={handleChange}
                      />
                      {/* Validation message */}
                      {errors.name && <span className="error">{errors.name}</span>}
                    </div>

                    <div className="mb-2">
                      <label htmlFor="emailaddress" className="form-label">
                        Email <span className="required">*</span>
                      </label>
                      <input
                        className="form-control"
                        type="text"
                        name="email"
                        id="emailaddress"
                        placeholder="Enter your email"
                        value={formData.email}
                        onChange={handleChange}
                      />
                      {/* Validation message */}
                      {errors.email && <span className="error">{errors.email}</span>}
                    </div>

                    <div className="mb-2">
                      <label htmlFor="password" className="form-label">
                        Password <span className="required">*</span>
                      </label>
                      <div className="input-group input-group-merge">
                        <input
                          type={showPassword ? "text" : "password"}
                          id="password"
                          name="password"
                          className="form-control"
                          placeholder="Enter your password"
                          value={formData.password}
                          onChange={handleChange}
                        />
                        <div
                          className="input-group-append"
                          className={showPassword ? "show-password" : ""}
                          data-password="false"
                        >
                          <div className="input-group-text">
                            <span className="password-eye" onClick={togglePasswordVisibility} />
                          </div>
                        </div>
                      </div>
                      {/* Validation message */}
                      {errors.password && <span className="error">{errors.password}</span>}
                    </div>
                    <div className="mb-3">
                      <div className="form-check">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          id="checkbox-signin"
                          name="acceptedTerms"
                          checked={formData.acceptedTerms}
                          onChange={handleCheckboxChange}
                        />
                        <label className="form-check-label" htmlFor="checkbox-signin">
                          I agree to the{" "}
                          <Link
                            to={process.env.REACT_APP_FRONT_END_URL + "privacy-policy"}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            Privacy Policy
                          </Link>{" "}
                          &{" "}
                          <Link
                            to={process.env.REACT_APP_FRONT_END_URL + "term-conditions"}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            Terms and Conditions
                          </Link>
                        </label>
                      </div>
                      {/* Validation message */}
                      {errors.acceptedTerms && (
                        <span className="error">{errors.acceptedTerms}</span>
                      )}
                    </div>
                    <div className="text-center d-grid">
                      <button
                        className="btn btn-info btn-block"
                        type="submit"
                        disabled={formBtn.disabled}
                      >
                        {formBtn.label}
                      </button>
                    </div>
                  </form>
                  {/* end form*/}
                  {/* Footer*/}
                  <footer className="footer footer-alt position-relative ">
                    <p className="mb-0">
                      Already have an account?&nbsp;
                      <Link to="/" className="ml-1">
                        <b>Login</b>
                      </Link>
                    </p>
                  </footer>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Register;
