import React from "react";
import Layout from "../../Layout/Index";
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  BarElement,
  LineElement,
  Title,
  PointElement,
  Filler,
} from "chart.js";
import { Pie, Bar, Line } from "react-chartjs-2";

// Register the components
ChartJS.register(
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  BarElement,
  LineElement,
  PointElement,
  Filler,
  Title
);
const ReportsAnalytics = () => {
  const candidateData = {
    labels: ["Applied", "On Hold", "Interviewing", "Rejected"],
    datasets: [
      {
        data: [30, 40, 20, 10],
        backgroundColor: ["#f7b84b", "#34c38f", "#50a5f1", "#f46a6a"],
      },
    ],
  };

  const applicantData = {
    labels: ["Applied", "On Hold", "Interviewing", "Rejected"],
    datasets: [
      {
        data: [25, 35, 25, 15],
        backgroundColor: ["#f7b84b", "#34c38f", "#50a5f1", "#f46a6a"],
      },
    ],
  };

  const jobData = {
    labels: ["Active Jobs", "In-Active Jobs", "On Hold Jobs", "Expired Jobs"],
    datasets: [
      {
        data: [20, 10, 30, 40],
        backgroundColor: ["#f7b84b", "#34c38f", "#50a5f1", "#f46a6a"],
      },
    ],
  };

  const jobApplicationData = {
    labels: ["Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct"],
    datasets: [
      {
        label: "Applied",
        data: [80, 90, 100, 110, 120, 130, 140, 150, 160],
        backgroundColor: "#f7b84b",
      },
      {
        label: "Selected",
        data: [70, 80, 90, 100, 110, 120, 130, 140, 150],
        backgroundColor: "#34c38f",
      },
      {
        label: "Rejected",
        data: [10, 20, 30, 40, 50, 60, 70, 80, 90],
        backgroundColor: "#f46a6a",
      },
    ],
  };

  const transactionData = {
    labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
    datasets: [
      {
        label: "Transactions ($)",
        data: [1200, 1500, 800, 1700, 1300, 900, 1400, 1100, 1600, 1500, 1700, 1800],
        backgroundColor: "#f7b84b",
        borderColor: "rgba(75, 192, 192, 1)",
        borderWidth: 1,
        fill: true,
        tension: 0.4,
      },
    ],
  };

  return (
    <Layout>
      <div className="row">
        <div className="col-12">
          <div className="page-title-box">
            <h4 className="page-title">Reports &amp; Analytics</h4>
          </div>
        </div>
      </div>
      {/* end page title */}
      <div className="row">
        <div className="col-xl-4">
          <div className="card">
            <div className="card-body custom-box-shadow">
              <h4 className="header-title mb-3">Candidate Overview</h4>
              <Pie data={candidateData} />
            </div>
          </div>
        </div>
        {/* end col */}
        <div className="col-xl-4">
          <div className="card">
            <div className="card-body custom-box-shadow">
              <h4 className="header-title mb-3">Applicant Overview</h4>
              <Pie data={applicantData} />
            </div>
          </div>
        </div>
        {/* end col */}
        <div className="col-xl-4">
          <div className="card">
            <div className="card-body custom-box-shadow">
              <h4 className="header-title mb-3">Job Overview</h4>
              <Pie data={jobData} />
            </div>
          </div>
        </div>
        <div className="col-xl-6">
          <div className="card">
            <div className="card-body custom-box-shadow">
              <h4 className="header-title mb-3">Job Application Overview</h4>
              <Bar data={jobApplicationData} />
            </div>
          </div>
        </div>
        <div className="col-xl-6">
          <div className="card">
            <div className="card-body custom-box-shadow">
              <h4 className="header-title mb-3">Transaction Overview</h4>
              <Line data={transactionData} />
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default ReportsAnalytics;
