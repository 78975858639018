import * as toast from "../utils/toastUtils";
import axios from "axios";
const token = localStorage.getItem('token');

/*  Update Documents  */
export async function updateDocument(data, endpoint) {
  let url = process.env.REACT_APP_API_URL + endpoint;

  // Send form data to the server
  const response = await axios.post(url, data, {
    headers: {
      "Content-Type": "multipart/form-data",
      'Authorization': `Bearer ${token}`,
    },
  });

  let res = response.data;
  if (res.status) {
    toast.success(res.message);
    return true;
  } else {
    toast.error(res.message);
    return false;
  }
}

/*  Delete Document  */
export async function deleteDocument(data, endpoint) {
  let url = process.env.REACT_APP_API_URL + endpoint;

  const response = await fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      'Authorization': `Bearer ${token}`,
    },
    body: JSON.stringify(data),
  });

  if (!response.ok) {
    //throw new Error("Failed to fetch data");
    toast.error("Something went wrong");
    return false;
  }

  const res = await response.json();

  if (res.status) {
    toast.success(res.message);
    return true;
  } else {
    toast.error(res.message);
    return false;
  }
}

/* Share Document */
export async function shareDocument(data, endpoint) {
  let url = process.env.REACT_APP_API_URL + endpoint;

  const response = await fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      'Authorization': `Bearer ${token}`,
    },
    body: JSON.stringify(data),
  });

  if (!response.ok) {
    //throw new Error("Failed to fetch data");
    toast.error("Something went wrong");
    return false;
  }

  const res = await response.json();

  if (res.status) {
    toast.success(res.message);
    return true;
  } else {
    toast.error(res.message);
    return false;
  }
}
