import React, { useState, useEffect } from "react";
import Header from "../../Layout/Header";
import Footer from "../../Layout/Footer";
import Sidebar from "../../Layout/Sidebar";
import ThemeSettings from "../../Layout/ThemeSettings";
import { Link } from "react-router-dom";
import * as api from "../../../services/ApiService";
import { Tooltip } from "react-tooltip";
import * as tokenUtils from "../../../utils/tokenUtils";
import * as Yup from "yup";
import Spinner from "../../Loader/Spinner";
import { toast } from "react-toastify";
import useConfirm from "../../../hooks/useConfirm";

function ClientComplianceCategories() {
  // Get data from token util
  let user = tokenUtils.getTokenData();

  const [isLoading, setIsLoading] = useState(false);
  const [documentTypes, setDocumentTypes] = useState({});
  const [loading, setLoading] = useState(true);
  const [noRecordsFound, setNoRecordsFound] = useState(false);

  const fetchDocumentTypes = async () => {
    try {
      const param = {
        user_id: user.id,
        token: user.token,
      };
      const apiData = await api.postMethod(param, "client/get-compliance-file-categories");

      setDocumentTypes(apiData.data);
      setLoading(false);
      // Check if data array is empty
      if (apiData.data.length === 0) {
        setNoRecordsFound(true);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchDocumentTypes();
  }, []); // Empty dependency array ensures that the effect runs only once, when the component mounts

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = {
      day: "2-digit",
      month: "short",
      year: "numeric",
    };
    const formattedDate = date.toLocaleDateString("en-US", options);
    return `${formattedDate}`;
  };

  const formatTime = (dateString) => {
    const date = new Date(dateString);
    const formattedTime = date.toLocaleTimeString("en-US", {
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    });
    return `${formattedTime}`;
  };

  const [formData, setformData] = useState({
    id: null,
    user_id: user.id,
    cat_name: null,
  });

  const [uploadModalOpen, setUploadModalOpen] = useState(false);

  const toggleUploadModal = () => {
    setUploadModalOpen(!uploadModalOpen);

    if (!uploadModalOpen) {
      setformData({
        id: null,
        user_id: user.id,
        cat_name: null,
      });

      // Reset validation errors
      setErrors({});
    }
  };

  const handleEdit = (record) => {
    // Populate formData with the values of the selected record
    setformData({
      user_id: user.id,
      id: record.id,
      cat_name: record.cat_name,
    });

    setUploadModalOpen(true);
  };

  const {confirm} = useConfirm()
  const handleDelete = async (record) => {
    const confirmed = await confirm()
    if (confirmed.isConfirmed) {
      record.user_id = user.id;
      setIsLoading(true);
      const result = await api.postMethod(record, "client/delete-compliance-file-category");
      setIsLoading(false);
      if (result.status) {
        toast.success(result.message);
        fetchDocumentTypes();
      }
    }
  };

  //Define the validation schema
  const validationSchema = Yup.object({
    cat_name: Yup.string().required("Title is Required"),
  });

  const [errors, setErrors] = useState({});
  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      await validationSchema.validate(formData, { abortEarly: false });

      setIsLoading(true);
      const result = await api.postMethod(formData, "client/update-compliance-file-category");
      setIsLoading(false);
      if (result.status) {
        toast.success(result.message);

        //document.getElementById("file_name").value = "";
        setUploadModalOpen(false);
        fetchDocumentTypes();
      } else {
        toast.error(result.message);
      }
    } catch (error) {
      setIsLoading(false);
      const newErrors = {};
      if (error.inner && Array.isArray(error.inner)) {
        error.inner.forEach((element) => {
          newErrors[element.path] = element.message;
        });
      } else {
        console.error("Unexpected error structure:", error);
      }
      setErrors(newErrors);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setformData({ ...formData, [name]: value });

    validateFields(name, value);
  };

  const validateFields = (name, value) => {
    try {
      validationSchema.validateSyncAt(name, { [name]: value });

      // Clear the error for the current field if validation passes
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: "",
      }));
    } catch (error) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: error.message,
      }));
    }
  };

  return (
    <>
      <div id="wrapper">
        <Sidebar />

        <div className="content-page">
          <Header />
          <div className="content">
            {/* Start Content*/}
            <div className="container-fluid">
              {/* start page title */}
              <div className="row">
                <div className="col-12">
                  <div className="page-title-box">
                    <div className="page-title-right">
                      <ol className="breadcrumb m-0">
                        <li className="breadcrumb-item">
                          <Link to="/client/dashboard">Home</Link>
                        </li>
                        <li className="breadcrumb-item">
                          <Link to="/client/compliance-files">Compliance Files</Link>
                        </li>
                        <li className="breadcrumb-item active">Compliance File Categories</li>
                      </ol>
                    </div>
                    <h4 className="page-title">Compliance File Categries</h4>
                  </div>
                </div>
              </div>

              <div
                className={uploadModalOpen ? "collapse show" : "collapse"}
                id="collapseWidthExample"
              >
                <div className="card custom-box-shadow">
                  <form onSubmit={handleSubmit}>
                    <div className="card-header border-bottom">
                      <h5 className="card-title mb-0">Add New Categry</h5>
                    </div>
                    <div className="card-body">
                      <div className="row">
                        <div className="form-group mb-0 col-md-4">
                          <label htmlFor="cat_name">Title *</label>
                          <input
                            type="text"
                            className="form-control"
                            id="cat_name"
                            name="cat_name"
                            value={formData.cat_name ? formData.cat_name : ""}
                            onChange={handleChange}
                          />
                          {errors.cat_name && <span className="error">{errors.cat_name}</span>}
                        </div>
                      </div>
                    </div>
                    <div className="card-footer border-top">
                      <input type="submit" value="Submit" className="btn btn-primary" />

                      <button
                        type="button"
                        className="btn btn-info waves-effect waves-light float-end"
                        onClick={toggleUploadModal}
                      >
                        Close
                      </button>
                    </div>
                  </form>
                </div>
              </div>

              <div className="row">
                <div className="col-12">
                  <div className="card custom-box-shadow">
                    <div className="card-body">
                      <div className="row justify-content-between mb-2">
                        <div className="col-auto">
                          <form className="d-flex flex-wrap align-items-center">
                            <div className="me-3">
                              <label htmlFor="inputPassword2" className="sr-only">
                                Search
                              </label>
                              <input
                                type="search"
                                className="form-control"
                                id="inputPassword2"
                                placeholder="Search by keyword..."
                              />
                            </div>
                          </form>
                        </div>
                        <div className="col-auto">
                          <div className="text-lg-end my-1 my-lg-0">
                            <button
                              type="button"
                              className="btn btn-primary waves-effect waves-light mb-2 me-1"
                              onClick={toggleUploadModal}
                            >
                              <i className="fa fa-plus mr-1"></i> Add New Type
                            </button>
                          </div>
                        </div>
                        {/* end col*/}
                      </div>
                      <div className="table-responsive">
                        <table className="table table-centered table-nowrap mb-0">
                          <thead className="table-light">
                            <tr>
                              <th>Title</th>
                              <th>Creation Date</th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {loading ? (
                              <tr>
                                <td colSpan="3">Loading...</td>
                              </tr>
                            ) : (
                              <>
                                {noRecordsFound ? (
                                  <tr>
                                    <td colSpan="3">No records found</td>
                                  </tr>
                                ) : (
                                  <>
                                    {Object.entries(documentTypes).map(([key, value]) => (
                                      <tr key={value.id}>
                                        <td>{value.cat_name}</td>
                                        <td>
                                          {formatDate(value.created_at)}{" "}
                                          <small className="text-muted">
                                            {formatTime(value.created_at)}
                                          </small>
                                        </td>
                                        <td>
                                          <button
                                            type="button"
                                            className="btn btn-default action-icon"
                                            data-tooltip-id="mt-3"
                                            data-tooltip-content="Edit Document"
                                            onClick={() => handleEdit(value)}
                                          >
                                            <i className="mdi mdi-pencil" />
                                          </button>
                                          <Tooltip id="mt-3"></Tooltip>
                                          <button
                                            type="button"
                                            className="btn btn-default action-icon"
                                            data-tooltip-id="mt-2"
                                            data-tooltip-content="Remove Document"
                                            onClick={() => handleDelete(value)}
                                          >
                                            <i className="mdi mdi-delete" />
                                          </button>
                                          <Tooltip id="mt-2"></Tooltip>
                                        </td>
                                      </tr>
                                    ))}
                                  </>
                                )}
                              </>
                            )}
                          </tbody>
                        </table>
                      </div>
                      {/* 
                      <ul className="pagination pagination-rounded justify-content-end my-2">
                        <li className="page-item">
                          <span className="page-link" aria-label="Previous">
                            <span aria-hidden="true">«</span>
                            <span className="sr-only">Previous</span>
                          </span>
                        </li>
                        <li className="page-item active">
                          <span className="page-link">1</span>
                        </li>
                        <li className="page-item">
                          <span className="page-link">2</span>
                        </li>
                        <li className="page-item">
                          <span className="page-link">3</span>
                        </li>
                        <li className="page-item">
                          <span className="page-link">4</span>
                        </li>
                        <li className="page-item">
                          <span className="page-link">5</span>
                        </li>
                        <li className="page-item">
                          <span className="page-link" aria-label="Next">
                            <span aria-hidden="true">»</span>
                            <span className="sr-only">Next</span>
                          </span>
                        </li>
                      </ul>
                      */}
                    </div>
                    {/* end card-body*/}
                  </div>
                  {/* end card*/}
                </div>
                {/* end col */}
              </div>
              {/* end row */}
            </div>
            {/* container */}
          </div>

          <Footer />
        </div>
      </div>

      <ThemeSettings />
      {isLoading === true && <Spinner />}
    </>
  );
}

export default ClientComplianceCategories;
