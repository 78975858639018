import React from "react";
import { Link } from "react-router-dom";

const PageTitle = ({ title, breadcrumbItems }) => {
  return (
    <div className="row">
      <div className="col-12">
        <div className="page-title-box">
          <div className="page-title-right">
            <ol className="breadcrumb m-0">
              {breadcrumbItems.map((item, index) => (
                <li
                  key={index}
                  className={`breadcrumb-item ${
                    index === breadcrumbItems.length - 1 ? "active" : ""
                  }`}
                >
                  {item.link ? <Link to={item.link}>{item.text}</Link> : item.text}
                </li>
              ))}
            </ol>
          </div>
          <h4 className="page-title">{title}</h4>
        </div>
      </div>
    </div>
  );
};

export default PageTitle;
