import { React, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import * as auth from "../../../services/AuthService";
import Logo from "./Logo";

function ForgotPassword() {
  let navigate = useNavigate();

  // Form button state
  const [formBtn, setformBtn] = useState({
    label: "Submit",
    disabled: false,
  });

  // Use useState hook to initialize FormData variables with empty values
  const [formData, setformData] = useState({
    email: "",
  });

  // Define Error state
  const [errors, setErrors] = useState({});

  // Define the validation schema
  let validationSchema = Yup.object({
    email: Yup.string().email("Invalid email").required("Email is required"),
  });

  // Form submit function
  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      // without abortEarly, it will return back on 1st field's validation occured, won't check other fields
      await validationSchema.validate(formData, { abortEarly: false });

      // Disable the btn
      setformBtn({
        label: "Please wait...",
        disabled: true,
      });

      // Call function with formData and API endpoint
      let res = await auth.forgotPassword(formData, "client/forgot-password");

      // Reset the btn
      setformBtn({
        label: "Submit",
        disabled: false,
      });

      if (res) {
        setTimeout(() => {
          navigate("/reset-password");
        }, 500);
      }
    } catch (error) {
      const newErrors = {};

      if (error.inner && Array.isArray(error.inner)) {
        error.inner.forEach((err) => {
          newErrors[err.path] = err.message;
        });
      } else {
        console.error("Unexpected error structure:", error);
      }

      setErrors(newErrors);
    }
  };

  // OnChange event on each form field, because form fields are disabled
  const handleChange = (e) => {
    //name attritube & value from the field
    const { name, value } = e.target;

    // Update the formData state
    setformData({
      ...formData,
      [name]: value,
    });

    // Validate the changed field and update errors state
    validateField(name, value);
  };

  // Function to validate form validate
  const validateField = (name, value) => {
    try {
      validationSchema.validateSyncAt(name, { [name]: value });
      // Clear the error for the current field if validation passes
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: "",
      }));
    } catch (error) {
      // Set the error message for the current field if validation fails
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: error.message,
      }));
    }
  };
  document.body.classList.add("auth-fluid-pages");
  return (
    <div className="auth-fluid">
      <div className="container">
        <div className="row justify-content-center w-100 align-items-center h-100">
          <div className="bg-white col-md-8 col-lg-6 col-xl-4 py-3 rounded shadow-lg">
            <div className="auth-fluid-form-box position-relative h-100 p-0">
              <div className="align-items-center d-flex h-100">
                <div className="p-3">
                  {/* Logo */}
                  <Logo />

                  {/* title*/}
                  <h4 className="mt-0">Forgot Password?</h4>
                  <p className="mb-4">
                    Enter your email address and we'll send you an email with instructions to reset
                    your password.
                  </p>
                  {/* form */}
                  <form onSubmit={handleSubmit}>
                    <div className="mb-3">
                      <label htmlFor="emailaddress" className="form-label">
                        Enter your Email to Reset Password
                      </label>
                      <input
                        className="form-control"
                        type="text"
                        name="email"
                        id="emailaddress"
                        placeholder="Enter your email"
                        value={formData.email}
                        onChange={handleChange}
                      />
                      {/* Validation message */}
                      {errors.email && <span className="error">{errors.email}</span>}
                    </div>
                    <div className="form-group mb-0 text-center d-grid">
                      <button
                        className="btn btn-info btn-block"
                        type="submit"
                        disabled={formBtn.disabled}
                      >
                        {formBtn.label}
                      </button>
                    </div>
                  </form>
                  {/* end form*/}
                  {/* Footer*/}
                  <footer className="footer footer-alt position-relative">
                    <p className="mb-0">
                      Back to &nbsp;
                      <Link to="/client/login" className="ml-1">
                        <b>Login</b>
                      </Link>
                    </p>
                  </footer>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ForgotPassword;
