import React, { useState, useEffect } from "react";
import * as api from "../../../../../services/ApiService";
import * as tokenUtils from "../../../../../utils/tokenUtils";

function Connections({ profileInfo, formatDate }) {
  // Get data from token util
  let user = tokenUtils.getTokenData();

  return (
    <>
      <p className="text-center">No connections available</p>
    </>
  );
}

export default Connections;
