import React, { useState, useEffect } from "react";
import * as api from "../../../../../services/ApiService";
import * as tokenUtils from "../../../../../utils/tokenUtils";
import { Link } from "react-router-dom";
import { formatDate } from "../../../../../utils/dateFormat";
import { Tooltip } from "react-tooltip";
import Modal from "react-bootstrap/Modal";

function ComplianceFiles({ profileInfo }) {
  // Get data from token util
  let user = tokenUtils.getTokenData();

  const [complianceFiles, setComplianceFiles] = useState({});
  const [loading, setLoading] = useState(true);
  const [noRecordsFound, setNoRecordsFound] = useState(false);

  const fetchComplianceFiles = async (tab) => {
    try {
      const param = {
        user_id: user.id,
        token: user.token,
        userID: profileInfo.id,
      };
      const apiData = await api.postMethod(param, "admin/get-user-compliance-files");

      setComplianceFiles(apiData.data);
      setLoading(false);
      // Check if data array is empty
      if (apiData.data.length === 0) {
        setNoRecordsFound(true);
      } else setNoRecordsFound(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchComplianceFiles();
  }, []); // Empty dependency array ensures that the effect runs only once, when the component mounts

  return (
    <>
      <div className="table-responsive">
        <table className="table table-centered table-nowrap mb-0">
          <thead className="table-light">
            <tr>
              <th>Title</th>
              <th>Type</th>
              <th>Posted By</th>
              <th>Expiration Date</th>
              <th>Status</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {loading ? (
              <tr>
                <td colSpan="6">Loading...</td>
              </tr>
            ) : (
              <>
                {noRecordsFound ? (
                  <tr>
                    <td colSpan="6">No records found</td>
                  </tr>
                ) : (
                  <>
                    {Object.entries(complianceFiles).map(([key, value]) => (
                      <tr key={value.id}>
                        <td>{value.title}</td>
                        <td>{value.doc_type_name}</td>
                        <td>
                          {(value.creator_role_id == 2 && (
                            <Link
                              to={`/admin/facility-profile/` + value.creator_unique_id}
                              className=" "
                            >
                              {value.creator_name}
                            </Link>
                          )) ||
                            (value.creator_role_id == 3 && (
                              <Link
                                to={`/admin/agency-profile/` + value.creator_unique_id}
                                className=" "
                              >
                                {value.creator_name}
                              </Link>
                            )) || <span className="">{value.creator_name}</span>}
                        </td>
                        <td>{formatDate(value.expiration_date, "MMM DD, YYYY")}</td>
                        <td>
                          {value.status == 1 && (
                            <label className="badge badge-soft-success">Active</label>
                          )}
                          {value.status == 0 && (
                            <label className="badge badge-soft-warning">In-Active</label>
                          )}
                        </td>
                        <td>
                          {value.dir_path && (
                            <>
                              <Link
                                to={value.dir_path}
                                download
                                target="_blank"
                                rel="noreferrer"
                                className="btn btn-default action-icon"
                                data-tooltip-id="mt-4"
                                data-tooltip-content="Download Document"
                              >
                                <i className="mdi mdi-download" />
                              </Link>
                              <Tooltip id="mt-4"></Tooltip>
                            </>
                          )}
                        </td>
                      </tr>
                    ))}
                  </>
                )}
              </>
            )}
          </tbody>
        </table>
      </div>
    </>
  );
}

export default ComplianceFiles;
