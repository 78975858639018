import React, { useState, useEffect } from "react";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import Header from "../../Layout/Header";
import Footer from "../../Layout/Footer";
import Sidebar from "../../Layout/Sidebar";
import ThemeSettings from "../../Layout/ThemeSettings";
import { Tooltip } from "react-tooltip";
import Modal from "react-bootstrap/Modal";
import * as tokenUtils from "../../../utils/tokenUtils";
import Spinner from "../../Loader/Spinner";
import { toast } from "react-toastify";
import Dropdown from "react-bootstrap/Dropdown";
import PageTitle from "../../Layout/PageTitle";
import { currentRole } from "../../Layout/HelmetComponent";
import { formatDate } from "../../../utils/dateFormat";
import useConfirm from "../../../hooks/useConfirm";

import * as Yup from "yup";

function JobRequest() {
  // Get data from token util
  let user = tokenUtils.getTokenData();
  const location = useLocation();
  const isBoostedJobs = location.pathname.includes("boosted-jobs");
  const isStaff = location.pathname.includes("/staff");
  const urlSlug = isStaff ? "staff" : "client";

  const [isLoading, setIsLoading] = useState(false);


  const [showJobFilterDropdown, setJobFilterDropdown] = useState(false);
  const handleToggleJobFilterDropDown = () => {
    setJobFilterDropdown(!showJobFilterDropdown);
  };

  // State for filter values
  const [filters, setFilters] = useState({
    job_title: "",
    state_id: "",
    date_posted: "",
    starred_job: false,
    order_by: "default",
  });

  // Handle form reset
  const handleReset = () => {
    const defaultFilters = {
      job_title: "",
      state_id: "",
      date_posted: "",
      starred_job: false,
      order_by: "default",
    };
    setFilters(defaultFilters);
    setJobFilterDropdown(false);
  };

  useEffect(() => {
    if (showJobFilterDropdown === false) {
    }
  }, [showJobFilterDropdown]);

  // Handle form input change
  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;

    if (type === "checkbox") {
      setFilters({
        ...filters,
        [name]: checked,
      });
    } else {
      setFilters({
        ...filters,
        [name]: value,
      });
    }
  };


; // Empty dependency array ensures that the effect runs only once, when the component mounts

  const [boostModal, setBoostModal] = useState(false);


  // State for filter values
  const [formData, setformData] = useState({
    user_id: user.id,
    job_id: "",
    amount: "",
    startDate: "",
    endDate: "",
  });


  const { confirm } = useConfirm();


  const breadcrumbItems = [
    { text: currentRole + " Dashboard", link: `/` + urlSlug + `/dashboard` },
    { text: isBoostedJobs ? "Boosted Jobs" : "Job Request", link: null },
  ];


  return (
    <>
      <div id="wrapper">
        <Sidebar />

        <div className="content-page">
          <Header />

          <div className="content">
            {/* Start Content*/}
            <div className="container-fluid">
              <PageTitle    
                title={isBoostedJobs ? "Boosted Jobs" : "Job Requests & Matches"}
                breadcrumbItems={breadcrumbItems}
              />

              <div className="row">
                <div className="col-12">
                  <div className="card custom-box-shadow">
                    <div className="card-body">
                      <div className="row justify-content-between">
                        <div className="col-auto">
                          <div className="dropdown" style={{ width: 300 }}>
                            <button
                              type="button"
                              className="btn btn-outline-light dropdown-toggle w-100 text-start"
                              onClick={handleToggleJobFilterDropDown}
                            >
                              Filter and Search Jobs
                              <i className="mdi mdi-chevron-down float-end" />
                            </button>
                            <div className={`dropdown-menu ${showJobFilterDropdown ? "show" : ""}`}>
                              <form className="px-2 py-2" 
                            //   onSubmit={handleSubmit}
                              >
                                <div className="mb-2">
                                  <label htmlFor="job_title" className="form-label">
                                    Job Title
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="job_title"
                                    name="job_title"
                                    // value={filters.job_title}
                                    // onChange={handleInputChange}
                                  />
                                </div>
                                <div className="mb-2">
                                  <label htmlFor="state_id" className="form-label">
                                    Location
                                  </label>
                                  <select
                                    className="form-control"
                                    id="state_id"
                                    name="state_id"
                                    // value={filters.state_id}
                                    // onChange={handleInputChange}
                                  >
                                    <option value="" key="">
                                      Please Select
                                    </option>
                                    {/* Render the states */}
                                    {/* {states.map((state) => (
                                      <option key={state.id} value={state.id}>
                                        {state.name}
                                      </option>
                                    ))} */}
                                  </select>
                                </div>
                                <div className="mb-2">
                                  <label htmlFor="date_posted" className="form-label">
                                    Date Posted
                                  </label>
                                  <input
                                    type="date"
                                    className="form-control"
                                    id="date_posted"
                                    name="date_posted"
                                    // value={filters.date_posted}
                                    // onChange={handleInputChange}
                                  />
                                </div>
                                <div className="mb-2">
                                  <label htmlFor="order_by" className="form-label">
                                    Order By
                                  </label>
                                  <select
                                    className="form-control"
                                    id="order_by"
                                    name="order_by"
                                    // value={filters.order_by}
                                    // onChange={handleInputChange}
                                  >
                                    <option value="default" key="default">
                                      Default
                                    </option>
                                    <option value="highest" key="highest">
                                      Highest Pay First
                                    </option>
                                    <option value="lowest" key="lowest">
                                      Lowest Pay First
                                    </option>
                                  </select>
                                </div>
                                <div className="mb-2">
                                  <div className="form-check">
                                    <input
                                      type="checkbox"
                                      className="form-check-input"
                                      id="starred_job"
                                      name="starred_job"
                                    //   checked={filters.starred_job}
                                    //   onChange={handleInputChange}
                                    />
                                    <label className="form-check-label" htmlFor="starred_job">
                                      <i className="fa fa-star" /> Starred
                                    </label>
                                  </div>
                                </div>
                                <div className="text-end">
                                  <button
                                    type="button"
                                    className="btn btn-light float-start"
                                    onClick={handleReset}
                                  >
                                    Reset Filter
                                  </button>
                                  <button type="submit" className="btn btn-primary">
                                    Apply Filter
                                  </button>
                                </div>
                              </form>
                            </div>
                          </div>
                        </div>

                        {/* end col*/}
                      </div>
                    </div>
                    {/* end card-body*/}
                  </div>
                  {/* end card*/}
                </div>
                {/* end col */}
              </div>



              {/* end row */}
            </div>
            <div>

            <>
                        <h4 style={{fontWeight:"700",padding:"1vw"}}>Job Matches</h4>

                          {/* {Object.entries(jobs).map(([key, value]) => ( */}
                            <div
                              className="card mb-3 custom-box-shadow  position-relative"
                              // key={value.id}
                            >
                              <div className="card-body">
                                <div className="row align-items-center">
                                  <div className="col-sm-12">
                                    <h5 className="mt-0 mb-2 font-16">
                                      <Link to={`/user/job/detail`} className="">
                                        {/* {value.title}{" "} */}
                                        Title
                                      </Link>
                                      <span className=" text-muted font-14 mb-1">
                                        {/* ({value.company_name}) */} company_name
                                      </span>
                                      {/* 
                                      <span className="mb-0 text-muted">
                                        <small>(#{value.unique_id})</small>
                                      </span>
                                      */}
                                    </h5>
                                  </div>
                                  <div className="col-sm-4">
                                    <div className="d-flex align-items-start">
                                      <Link to={`/user/job/` + 1} className="">
                                        {/* {value.profile_pic_path && ( */}
                                          <img
                                            // src={value.profile_pic_path}
                                            className="d-flex align-self-center me-3 rounded-circle"
                                            alt="user-img"
                                            height={64}
                                          />
                                      </Link>

                                      <div className="w-100">
                                        <p className="mb-1">
                                          <b>Profession : </b>{" "}
                                          {/* {value.state_code !== null && value.city_name !== null
                                            ? value.city_name + ", " + value.state_code
                                            : value.state_code !== null
                                            ? value.state_code
                                            : value.city_name !== null
                                            ? value.city_name
                                            : "N/A"} */}
                                        </p>
                                        <p className="mb-0">
                                          <b>Specialty : </b>{" "}
                                          {/* {value.specialty !== null ? value.specialty : "-"} */}
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-sm-4">
                                    <p className="mb-1 mt-0 mt-sm-0">
                                      <b>Shift : </b> 
                                      {/* {value.shift_title || "-"} */}
                                    </p>
                                    <p className="mb-0">
                                      <b>Locations : </b>
                                      {/* {value.show_pay_rate == 0 ? (
                                        <>
                                          <span
                                            onClick={() => toggleModal(value.id)}
                                            className="cursor-pointer"
                                            style={{ cursor: "pointer" }}
                                          >
                                            Contact Us
                                          </span>
                                        </>
                                      ) : value.salary_start_range ? (
                                        <>
                                          ${formatAmount(value.salary_start_range)}{" "}
                                          {value.salary_type}
                                        </>
                                      ) : (
                                        "N/A"
                                      )} */}
                                    </p>
                                  </div>
                                  <div className="col-sm-3">
                                    <p className="mb-1 mt-0 mt-sm-0">
                                      <b>Salary : </b> 
                                      {/* {value.total_opening || "N/A"} */}
                                    </p>
                                    <p className="mb-0">
                                      <b>Start Date : </b>
                                       {/* {formatDate(value.created_at)} */}
                                    </p>
                                  </div>
                                  <div className="col-md-1">
            <Dropdown className="">
                                      <Dropdown.Toggle
                                        variant="default"
                                        // id={`dropdown-basic-` + value.job_id}
                                        // key={`dropdown-basic-` + value.job_id}
                                        className="p-0"
                                      >
                                        <i className="mdi mdi-dots-horizontal m-0 text-muted h3" />
                                      </Dropdown.Toggle>

                                      <Dropdown.Menu>
                                       
                                          <Dropdown.Item >
                                            Mark as Active
                                          </Dropdown.Item>
                                        
                                        
                                          <Dropdown.Item >
                                            Mark as In-Active
                                          </Dropdown.Item>
                                       

                                        <Dropdown.Item
                                        >
                                          Edit
                                        </Dropdown.Item>
                                        <Dropdown.Item>
                                          Delete
                                        </Dropdown.Item>
                                      </Dropdown.Menu>
                                    </Dropdown>
          </div>
                                </div>
                              </div>
                              {/* {value.is_boosted == 1 && (
                                <div className="ribbon ribbon-triangle ribbon-top-end border-primary">
                                  <div class="ribbon-icon mt-n3 me-0">
                                    <i class="fas fa-star fs-2 text-white font-18"></i>
                                  </div>
                                </div>
                              )} */}
                            </div>
                            
                          {/* ))} */}
                        </>
            </div>
            {/* container */}
            <div >

        </div>
          </div>

          <Footer />
        </div>
      </div>

      <ThemeSettings />
      {isLoading === true && <Spinner />}
    </>
  );
}

export default JobRequest;
